import React, { ReactElement, FC } from 'react';
import type { IStep, IStepperProps } from './types';
import Bubble from '../bubble';
import { LABEL_POSITION, Elements } from '../constants';
import styled from 'styled-components';
import { MAIN_THEME_DATA } from '@configs';

const Stepper: FC<IStepperProps> = (props) => {
  const {
    steps,
    currentStepIndex = 0,
    onStepClick,
    renderBubble,
    styles = {},
    labelPosition = LABEL_POSITION.RIGHT,
    isLoading,
    isMobile,
  } = props;
  return (
    <StyledStepperContainer>
      {steps?.map(
        (step: IStep, stepIndex: number): ReactElement => (
          <div key={stepIndex} className="eachStep" id="stepper-steps">
            <div className="bubbleLineWrapper">
              <Bubble
                isMobile={isMobile}
                isLoading={isLoading}
                step={step}
                index={stepIndex}
                currentStepIndex={currentStepIndex}
                handleStepClick={(): void => onStepClick && onStepClick(step, stepIndex)}
                showCursor={!!onStepClick}
                renderAdornment={renderBubble}
                labelPosition={labelPosition}
              />
              <>
                {isMobile && steps.length === 1 ? (
                  <div className={`lineSeparator one-step`} />
                ) : (
                  <>
                    {stepIndex < steps?.length - 1 && (
                      <div
                        className={`lineSeparator
                ${stepIndex > currentStepIndex - 1 ? '' : `inactiveStepLineSeparator`}`}
                      />
                    )}
                  </>
                )}
              </>
            </div>
          </div>
        ),
      )}
    </StyledStepperContainer>
  );
};
const StyledStepperContainer = styled.div`
  width: 100%;
  height: 100%;
  padding-bottom: 2rem;
  .one-step {
    height: 2.5rem !important;
  }
  .eachStep {
    position: relative;
    .bubbleLineWrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: fit-content;
      .lineSeparator {
        height: 4.6rem;
        width: 2px;
        background-color: ${MAIN_THEME_DATA.mainColor};
      }
      .inactiveStepLineSeparator {
        width: 2px;
      }
    }
  }
  .cursorPointer {
    cursor: pointer;
  }
`;
export default Stepper;
