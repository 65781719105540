import { Category, NavBarItem, ProductFilter, SubCategory, WarehouseItem } from '@interfaces';
import { selectApp, selectAuth, useAppSelector } from '@redux';
import { useMediaQuery } from '@utils';
import { useLocation } from 'react-router-dom';
import { AuthHeader } from '../auth/AuthHeader';
import { MobileHeader } from './MobileHeader';
import { WebHeader } from './WebHeader';
import { AppModal, IConCancel, RoundedFillTickIcon, ShareInput } from '@components';
import { styled } from 'styled-components';
import { MAIN_THEME_DATA } from '@configs';
interface IProps {
  isAuth?: boolean;
  navItems: NavBarItem[];
  products: ProductFilter[];
  onNavItemClick: (path: string) => void;
  onNavigateToCategory: (item: Category) => void;
  onClickChildCategory: (item: SubCategory) => void;
  handleOpenChange: (value: boolean) => void;
  handleProductOpenChange: (value: boolean) => void;
  handleSubmitSearch: () => void;
  open: boolean;
  productOpen: boolean;
  navigateToProfile: () => void;
  onSearch: (value: string) => void;
  onNavigateToProductDetail: (item: ProductFilter) => void;
  keyword: string;
  onOpenWarehouseModal: (value: boolean) => void;
  warehouses: WarehouseItem[];
  currentWarehouse: WarehouseItem;
  warehouseModal: boolean;
  onSelectWarehouse: (item: WarehouseItem) => void;
  onSearchWarehouse: (value: string) => void;
  avatar: string;
  navigateToCart: () => void;
}
export const AppHeader = (props: IProps) => {
  const {
    isAuth,
    warehouseModal,
    warehouses,
    currentWarehouse,
    onSelectWarehouse,
    onSearchWarehouse,
    onOpenWarehouseModal,
    navigateToCart,
  } = props;
  const { themeData } = useAppSelector(selectApp);
  const { accessToken } = useAppSelector(selectAuth);
  const location = useLocation();
  const isMobile = useMediaQuery('(max-width: 768px)');
  return (
    <StyledHeaderWrapper>
      {isMobile ? <MobileHeader {...props} /> : <WebHeader {...props} />}
      <AppModal modalClassName="modal" open={warehouseModal}>
        <div>
          <div className="inner-modal">
            <div className="header">
              <ShareInput
                onChange={(e: any) => {
                  onSearchWarehouse(e.target.value);
                }}
                placeholder="Nhập tên kho"
              />
              <IConCancel
                onClick={() => {
                  onOpenWarehouseModal(false);
                }}
              />
            </div>
            <div className="main">
              <span>Vui lòng chọn kho để xem sản phẩm, giá, khuyến mãi khác nhau</span>
              <div className="warehouses">
                {warehouses?.map((item) => {
                  return (
                    <div
                      onClick={() => {
                        onSelectWarehouse(item);
                      }}
                      key={item.id}
                      className="warehouse-item"
                    >
                      <span className={`warehouse-txt`}>{item.name}</span>
                      {item?.id === currentWarehouse?.id && (
                        <RoundedFillTickIcon width={15} height={15} />
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </AppModal>
    </StyledHeaderWrapper>
  );
};
const StyledHeaderWrapper = styled.div`
  .modal {
    .content {
      padding: 0;
      max-width: 37rem;
      max-height: 35rem;
      overflow-y: auto;
    }
  }
  .inner-modal {
    .header {
      background-color: ${MAIN_THEME_DATA.mainColor};
      display: flex;
      padding: 1rem;
      align-items: center;
      border-radius: 0.6rem 0.6rem 0 0;
      .inner-input {
        height: 4rem;
        font-size: 1.4rem;
      }
    }
    .main {
      padding: 1rem;
      .warehouses {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 1rem;
        .warehouse-item {
          width: calc((100% - 2rem) / 2);
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-bottom: 1px solid #f1f1f1;
          padding: 0.5rem 0.75rem;
          margin-bottom: 0.5rem;
          cursor: pointer;
          &:hover {
            background-color: ${MAIN_THEME_DATA.bgColor};
          }
          .warehouse-txt {
            font-size: 1.2rem;
            font-family: Roboto-Medium;
          }
        }
      }
    }
  }
`;
