import { Divider } from 'antd';
import React, { forwardRef } from 'react';

import {
  enumThemeMode,
  INTRO_COL,
  MAIN_THEME_DATA,
  MAT_SM_SCREEN_WIDTH,
  NAV_BAR,
  POLICY_COL,
} from '@configs';
import { BreadcrumbsModule, MenuDropdownModule } from '@modules';
import { HeaderMenuIcon, MenuFoldOutlined, MenuUnfoldOutlined, UserHeaderIcon } from '../Icon';
import { SharedDropdown, SharedImage } from '../shared';
import { useMediaQuery } from '@utils';
import { selectApp, useAppSelector } from '@redux';
import { MobileFooter } from './MobileFooter';
import { WebFooter } from './WebFooter';
import { FooterContactItem } from '@interfaces';

interface IProps {
  contacts: FooterContactItem[];
}

export const Footer = (props: IProps) => {
  const { contacts } = props;
  const { themeData } = useAppSelector(selectApp);
  const isMobile = useMediaQuery('(max-width: 768px)');
  return (
    <>
      {isMobile ? (
        <MobileFooter navBarItems={NAV_BAR} />
      ) : (
        <WebFooter contacts={contacts} intros={INTRO_COL as any} policies={POLICY_COL as any} />
      )}
    </>
  );
};
