import { IMAGES } from '@assets';
import { GiftIcon, XIcon } from '@components';
import { MAIN_THEME_DATA, PATH_CART } from '@configs';
import {
  Classification,
  ProductDetailResponse,
  ProductFilter,
  ProductsHotSelling,
  SubClassification,
} from '@interfaces';
import { toVND } from '@utils';
import parse from 'html-react-parser';
import ImageGallery from 'react-image-gallery';
import { useNavigate } from 'react-router-dom';
import { AppModal } from 'src/components/AppModal';
import { styled } from 'styled-components';
import { HomeList } from '../../home/components/HomeList';
import { LeftArrow, RightArrow } from '../../home/components/web';
import { SharedButton, SharedImage } from '../../shared';
import { AddProductModal } from '../components/AddProductModal';
import { Description } from '../components/Description';
import { LoadingView } from '../components/LoadingView';
import { Policy } from '../components/Policy';
import { QuantityControl } from '../components/QuantityControl';
import { Rating } from '../components/Rating';
import { Size } from '../components/Size';
const MIN_QUANTITY = 20;
const MAX_QUANTITY = 100;
interface ProductSectionProps {
  error: string;
  product: ProductDetailResponse;
  onSelectClassification: (classification: Classification) => void;
  onSelectSubClassification: (classification: SubClassification) => void;
  selectedClassification: Classification;
  selectedSubClassification: SubClassification;
  productPrice: string;
  quantity: number;
  increaseProductQuantity: () => void;
  decreaseProductQuantity: () => void;
  onAddToCart: () => void;
  onBuyNow: () => void;
  isLoading: boolean;
  sameProducts: ProductFilter[];
  onItemClick: (item: ProductsHotSelling) => void;
  onChangeQuantity: (quantity: number) => void;
  getAttribute: () => string;
  modalData: { opened: boolean; product: ProductDetailResponse };
  onModalInteract: (value: boolean) => void;
  isNotFound: boolean;
  onInteractAddProductModal: (value: boolean) => void;
  modalOpened: boolean;
  galleryRef: any;
  getProductDeduction: () => number;
}
export const AppProductSection = (props: ProductSectionProps) => {
  const {
    product,
    selectedClassification,
    onSelectClassification,
    onSelectSubClassification,
    selectedSubClassification,
    productPrice,
    increaseProductQuantity,
    decreaseProductQuantity,
    quantity,
    onAddToCart,
    error,
    onBuyNow,
    isLoading,
    sameProducts,
    onItemClick,
    onChangeQuantity,
    modalData,
    getAttribute,
    onModalInteract,
    isNotFound,
    onInteractAddProductModal,
    modalOpened,
    galleryRef,
    getProductDeduction,
  } = props;
  const navigate = useNavigate();
  const hasSubClassification = product.classifications.every(
    (item) => item.sub_classifications.length > 0,
  );
  const images =
    product.classifications.length > 0
      ? [
          ...product.images.map((item) => {
            return {
              image: {
                url: item.url,
              },
            };
          }),
          ...product.classifications,
        ].map((item) => {
          return {
            original: item.image?.url ?? '',
            thumbnail: item.image?.url ?? '',
          };
        })
      : product.images.map((item) => {
          return {
            original: item.url,
            thumbnail: item.url,
          };
        });
  const getQuantity = (quantity: number) => {
    if (quantity <= 20) return MIN_QUANTITY;
    if (quantity >= 100) return MAX_QUANTITY;
    return quantity;
  };
  return (
    <StyledProductSection error={error}>
      {isLoading ? (
        <LoadingView />
      ) : (
        <>
          {isNotFound ? (
            <div className="flex items-center flex-col">
              <SharedImage containerClassName="not-found-img" src={IMAGES.searchNotFound} />
              <span className="not-found-text">
                Không tìm thấy sản phẩm này. Vui lòng xem sản phẩm khác hoặc chọn kho khác
              </span>
            </div>
          ) : (
            <div className="detail-wrapper">
              <h3>{product.name}</h3>
              <div className="content-block">
                <ImageGallery
                  ref={galleryRef}
                  renderLeftNav={(onClick, disabled) => {
                    return <LeftArrow onClick={onClick} className="nav-btn left-nav" />;
                  }}
                  renderRightNav={(onClick, disabled) => {
                    return <RightArrow onClick={onClick} className="nav-btn right-nav" />;
                  }}
                  renderFullscreenButton={() => {
                    return null;
                  }}
                  renderPlayPauseButton={() => {
                    return null;
                  }}
                  items={images}
                />
                <div className="product-info-block">
                  <div>
                    <div>
                      <span className="price-text">{productPrice}</span>
                      {getProductDeduction() > 0 && (
                        <span className="deduct-percent">Chiết khấu: {getProductDeduction()}%</span>
                      )}
                      <div className="status-block">
                        <div className="rating-block">
                          <span className="text">Tình trạng:</span>
                          <span className="text content">Còn hàng</span>
                        </div>
                        {!product.classifications.length && (
                          <>
                            <div className="divider" />
                            <div>
                              <span className="text">Số lượng:</span>
                              <span className="text content red">
                                {getQuantity(product.quantity)}
                              </span>
                            </div>
                          </>
                        )}
                        <div className="divider" />
                        <div className="rating-block">
                          <span className="text">Đơn vị:</span>
                          <span className="text content">{product.unit}</span>
                        </div>
                        <div className="divider" />
                        <div className="rating-block">
                          <span className="text">Đánh giá:</span>
                          <Rating numStar={5} />
                        </div>
                      </div>
                      {product?.promotions?.length > 0 && (
                        <div className="promotion">
                          <div className="header">
                            <span>Khuyến mãi đặc biệt</span>
                          </div>
                          <div className="content">
                            {product.promotions.map((item) => {
                              return (
                                <div id={item.id}>
                                  <div className="promotion-item">
                                    <GiftIcon />
                                    <span className="pro-text">{item.name}</span>
                                  </div>
                                  <div className="ml-4">{parse(item?.description || '<div/>')}</div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="attribute-block">
                      {product?.classifications?.length > 0 && (
                        <div className="size-block">
                          <span className="size-text text">
                            {product?.classifications?.[0]?.attribute_name}
                          </span>
                          <div className="size-content">
                            {product.classifications.map((item) => {
                              return (
                                <Size
                                  key={item.id}
                                  isSelected={item.id === selectedClassification.id}
                                  onClick={() => {
                                    onSelectClassification(item);
                                  }}
                                  hasQuantity={!item.sub_classifications.length}
                                  title={item.value}
                                  quantity={getQuantity(item?.product_item?.quantity)}
                                  percent={item?.product_item?.deduct_percent}
                                  titleClassName={'text text-10'}
                                  quantityClassName={'text content text-10'}
                                />
                              );
                            })}
                          </div>
                          {selectedClassification?.sub_classifications?.length > 0 && (
                            <>
                              <span className="size-text text">
                                {selectedClassification?.sub_classifications?.[0]?.attribute_name}
                              </span>
                              <div className="size-content">
                                {selectedClassification?.sub_classifications?.map((item) => {
                                  return (
                                    <Size
                                      key={item.id}
                                      isSelected={selectedSubClassification.id === item.id}
                                      onClick={() => {}}
                                      hasQuantity
                                      title={`${item?.value}`}
                                      quantity={getQuantity(item?.product_item?.quantity)}
                                      titleClassName={'text text-10'}
                                      quantityClassName={'text content text-10'}
                                      percent={item?.product_item?.deduct_percent}
                                    />
                                  );
                                })}
                              </div>
                            </>
                          )}
                        </div>
                      )}
                      {!product.classifications.length && (
                        <div className="quantity-block">
                          <QuantityControl
                            size="small"
                            onDecrease={decreaseProductQuantity}
                            onIncrease={increaseProductQuantity}
                            quantity={quantity}
                            onChangeQuantity={onChangeQuantity}
                          />
                          {error && <span className="error-text">{error}</span>}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="btn-block">
                    <SharedButton
                      onClick={() => {
                        if (product.classifications.length) onInteractAddProductModal(true);
                        else onAddToCart();
                      }}
                      textClassName="text-btn"
                      className="mr"
                      btnStyle="line"
                      textColor={MAIN_THEME_DATA.mainColor}
                      text="Thêm vào giỏ hàng"
                    />
                    <SharedButton
                      onClick={() => {
                        if (product.classifications.length) onInteractAddProductModal(true);
                        else onBuyNow();
                      }}
                      text="Mua ngay"
                    />
                  </div>
                </div>
              </div>
              <div className="description-block">
                {product?.description && (
                  <Description className="mb" title={'Mô tả sản phẩm'}>
                    {parse(product?.description || '<div/>')}
                  </Description>
                )}
                <Description title={'Chính sách cửa hàng'}>
                  <Policy />
                </Description>
              </div>
            </div>
          )}
        </>
      )}
      <AppModal open={modalData.opened}>
        <div className="inner-modal">
          <div className="product-heading">
            <span className="product-title size-17 medium-font weight-500">
              Đã thêm <span className="quantity">{quantity}</span> sản phẩm vào giỏ hàng
            </span>
            <XIcon
              onClick={() => {
                onModalInteract(false);
              }}
            />
          </div>
          <div className="product-content">
            <SharedImage containerClassName="img-ctn" src={modalData.product?.images?.[0]?.url} />
            <div className="text-block">
              <span className="text weight-500 medium-font mb-4r size-17">
                {modalData.product.name}
              </span>
              <span className="text  mb-8r">
                Phân loại: <span className="size weight-500 red-text">{getAttribute()}</span>
              </span>
              <div className="price-block">
                <span className="text size-17 weight-500 medium-font red-text mb-4r">
                  {toVND(modalData.product.selling_price)}
                </span>
                <span className="text original-price">
                  {toVND(modalData.product.selling_price)}
                </span>
              </div>
            </div>
          </div>
          <div className="actions">
            <SharedButton
              onClick={() => {
                navigate(PATH_CART);
              }}
              text="Xem giỏ hàng"
            />
          </div>
        </div>
      </AppModal>
      <AddProductModal
        onClose={() => {
          onInteractAddProductModal(false);
        }}
        modalOpened={modalOpened}
        productItems={(hasSubClassification
          ? selectedClassification.sub_classifications
          : product.classifications
        ).map((item) => {
          return {
            product_id: product.id,
            product_item_id: item.product_item.id,
            quantity: 0,
            price: item.product_item.selling_price,
          };
        })}
        product={product}
        selectedClassification={selectedClassification}
        classifications={product.classifications}
      />
      {!isNotFound && (
        <HomeList
          hasPrice
          isLoading={isLoading}
          footerClassNameItem="product-footer-item"
          title="Sản phẩm tương tự"
          data={sameProducts}
          onItemClick={onItemClick}
        />
      )}
    </StyledProductSection>
  );
};
const StyledProductSection = styled.div<{ error: string }>`
  padding-bottom: 8.8rem;
  p {
    margin-bottom: 0;
  }
  .not-found-img {
    width: 20rem;
    aspect-ratio: 759/735;
  }
  .not-found-text {
    text-align: center;
    font-size: 1.7rem;
    font-family: Roboto-Medium;
    margin-top: 1.6rem;
  }
  .inner-modal {
    margin: 1.6rem;
    .product-heading {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom-width: 1px;
      border-bottom-color: #eaeaea;
      padding: 0 1.6rem 1.2rem 1.6rem;
    }
    .mb-4r {
      margin-bottom: 0.4rem;
    }
    .mb-8r {
      margin-bottom: 0.8rem;
    }
    .medium-font {
      font-family: Roboto-Medium;
    }
    .weight-500 {
      font-weight: 500;
    }
    .size-17 {
      font-size: 1.7rem;
      line-height: 19.6px;
    }
    .red-text {
      color: ${MAIN_THEME_DATA.mainColor};
    }
    .actions {
      padding: 0 1.6rem;
      .text-btn {
        font-size: 1.7rem;
      }
    }
    .product-content {
      margin-bottom: 2.4rem;
      padding: 0 1.6rem;
      span {
        display: inline-block;
      }
      margin-top: 1.6rem;
      display: flex;
      .img-ctn {
        width: 9.6rem;
        aspect-ratio: 1;
        border-radius: 10px;
        img {
          border-radius: 10px;
        }
      }
      .text-block {
        display: flex;
        flex-direction: column;
        margin-left: 1.6rem;
        .price-block {
          display: flex;
        }
        .text {
          font-size: 1.4rem;
          line-height: 140%;
        }
        .original-price {
          text-decoration: line-through;
          margin-left: 0.4rem;
        }
      }
    }
    .product-title {
      font-family: Roboto-Regular;
      .quantity {
        color: ${MAIN_THEME_DATA.mainColor};
        font-size: 1.7rem;
        font-family: Roboto-Medium;
      }
    }
  }
  .app-modal {
    .content {
      margin: 0 1.6rem;
      padding: 0;
      border-radius: 1.6rem;
      width: 40rem;
    }
  }
  ul {
    list-style-type: disc;
    list-style-position: inside;
  }
  ol {
    list-style-type: decimal;
    list-style-position: inside;
  }
  ul ul,
  ol ul {
    list-style-type: circle;
    list-style-position: inside;
  }
  ol ol,
  ul ol {
    list-style-type: lower-latin;
    list-style-position: inside;
  }
  /* padding: 0 12rem; */
  .text-btn {
    font-size: 1.4rem;
  }
  .quantity-block {
    .title {
      // Số lượng
      color: ${MAIN_THEME_DATA.mainText};
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 19.6px;
      font-family: Roboto-Medium;
    }
    margin-top: 1.6rem;
    .error-text {
      color: ${MAIN_THEME_DATA.mainColor};
      margin-top: 1rem;
      display: inline-block;
    }
  }
  .detail-wrapper {
    padding: 0 1.6rem;
  }
  .image-gallery {
    width: 100%;
    aspect-ratio: 1;
    /* width: calc((536 / 1200) * 100%); */
    /* aspect-ratio: 0.807; */
  }
  .image-gallery-slide-wrapper {
  }
  .image-gallery-swipe {
  }
  .image-gallery-image {
    width: 100%;
    aspect-ratio: 1;
    /* object-fit: fill; */
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
  }
  .image-gallery-content {
    background-color: white;
    border-radius: 12px;
  }
  .image-gallery-thumbnails {
    padding: 1.6rem 2.4rem;
  }
  .image-gallery-thumbnail {
    width: 6.4rem;
    aspect-ratio: 1;
    border-radius: 0.8rem;
    margin-right: 0.8rem;
  }
  .image-gallery-thumbnail-inner {
    width: 100%;
    height: 100%;
  }
  .image-gallery-thumbnail-image {
    width: 100%;
    aspect-ratio: 1;
    /* object-fit: fill; */
    border-radius: 9px;
  }
  .image-gallery-thumbnail.active {
    border-color: ${MAIN_THEME_DATA.mainColor};
  }
  .nav-btn {
    color: #fff;
    background-color: ${MAIN_THEME_DATA.bgColor};
    padding: 6px;
    outline: none;
    position: absolute;
    z-index: 4;
    top: 50%;
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    transform: translateY(-50%);
  }
  .left-nav {
    left: 1.6rem;
  }
  .right-nav {
    right: 1.6rem;
  }
  .content-block {
    margin-bottom: 2.4rem;
  }
  h3 {
    // Bóng chuyền Gerll Star BS-700 Super X - Chính hãng
    color: ${MAIN_THEME_DATA.mainText};
    font-size: 2rem;
    font-family: Roboto-Medium;
    font-weight: 500;
    line-height: 33.6px;
    margin-bottom: 2rem;
  }
  .m-left-4 {
    margin-left: 4rem;
  }
  .product-info-block {
    /* margin-left: 4rem; */
    .promotion {
      margin-top: 1rem;
      .header {
        background-color: ${MAIN_THEME_DATA.mainColor};
        border-top-right-radius: 1.6rem;
        border-top-left-radius: 1.6rem;
        padding: 0.8rem 0 0.8rem 0.8rem;
        span {
          color: #fff;
          font-size: 1.2rem;
        }
      }
      .content {
        border: 1px solid ${MAIN_THEME_DATA.mainColor};
        border-bottom-left-radius: 1.6rem;
        border-bottom-right-radius: 1.6rem;
        padding: 0.8rem 1.2rem;
        .promotion-item {
          display: flex;
          .pro-text {
            margin-left: 0.8rem;
          }
        }
      }
    }
    .deduct-percent {
      font-size: 1.2rem;
      color: ${MAIN_THEME_DATA.mainColor};
      font-style: italic;
      margin-left: 0.5rem;
    }
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    .price-text {
      color: ${MAIN_THEME_DATA.mainColor};
      font-size: 2.4rem;
      font-family: Roboto-Medium;
      font-weight: 500;
      line-height: 44.8px;
      word-wrap: break-word;
      margin-right: 1rem;
    }
    .org-price-text {
      color: #888888;
      font-size: 1.7rem;
      font-family: Roboto-Medium;
      font-weight: 400;
      text-decoration: line-through;
      line-height: 33.6px;
      word-wrap: break-word;
    }
    .text {
      color: #888888;
      font-size: 1.2rem;
      font-family: Roboto-Medium;
      font-weight: 400;
      line-height: 19.6px;
    }
    .text-10 {
      font-size: 1rem;
    }
    .content {
      color: ${MAIN_THEME_DATA.mainColor};
    }
    .status-block {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      .rating-block {
        display: flex;
        align-items: center;
      }
      .divider {
        height: 1.6rem;
        width: 1px;
        background-color: #888888;
        margin: 0 0.4rem;
      }
    }
    .attribute-block {
      margin-top: 1.2rem;
      background-color: ${(p) => (p.error ? '#FFE8E5' : `${MAIN_THEME_DATA.bgColor}`)};
    }
    .size-block {
      .size-text {
        color: ${MAIN_THEME_DATA.mainText};
      }
      .size-content {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 1.2rem;
      }
    }
    .btn-block {
      display: flex;
      width: 100%;
      margin-top: 2.4rem;
      .btn {
        padding: 0.8rem 0;
      }
      .mr {
        margin-right: 1.6rem;
      }
    }
    .description-block {
    }
  }
  .react-horizontal-scrolling-menu--item {
    width: 16rem;
    margin-right: 1.5rem;
    flex-shrink: 0;
  }
  .image-gallery-thumbnail:focus {
    border-color: ${MAIN_THEME_DATA.mainColor};
  }
  .image-gallery-thumbnail:hover {
    border-color: ${MAIN_THEME_DATA.mainColor};
  }
  .product-footer-item {
    text-align: left;
    font-family: Roboto-Regular;
    font-size: 12px;
    @media (max-width: 768px) {
      font-size: 1rem;
    }
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 16.8px */
  }
`;
