import { yupResolver } from '@hookform/resolvers/yup';
import { useLayoutEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { authAPI } from '@api';
import { ResetPasswordForm } from '@components';
import { PATH_LOGIN } from '@configs';
import {
  selectAuth,
  setForgotUsername,
  setLoading,
  setVerifyOTPHash,
  useAppDispatch,
  useAppSelector,
} from '@redux';
//@ts-ignore
import { NotificationManager } from 'react-notifications';
const schema = yup.object().shape({
  password: yup
    .string()
    .required('Vui lòng nhập mật khẩu')
    .matches(
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$%^&+=!]).{8,}$/,
      'Mật khẩu phải chứa ít nhất 8 ký tự, bao gồm ít nhất một chữ hoa, một chữ thường, một số và một ký tự đặc biệt.',
    ),
  confirm_password: yup
    .string()
    .required('Vui lòng xác nhận mật khẩu')
    .oneOf([yup.ref('password'), null], 'Mật khẩu không khớp'),
});

export const ResetPasswordModule = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { verifyOTPHash, forgotUsername } = useAppSelector(selectAuth);

  useLayoutEffect(() => {
    if (!verifyOTPHash) {
      navigate(PATH_LOGIN);
    }
  }, []);

  const {
    register,
    handleSubmit,
    reset,
    setError,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const handleRedirectToLogin = () => {
    navigate(PATH_LOGIN);
  };

  const handleResetPassword = handleSubmit(async (value) => {
    try {
      dispatch(setLoading(true));
      const res = await authAPI.resetPassword({
        new_password: value.password,
        confirm_password: value.confirm_password,
        username: forgotUsername ?? '',
      });
      dispatch(setVerifyOTPHash(''));
      dispatch(setForgotUsername(''));
      NotificationManager.success('Thông báo', 'Thành công');
      handleRedirectToLogin();
    } catch (error: any) {
      NotificationManager.error('Thông báo', error.data.detail);
    } finally {
      dispatch(setLoading(false));
    }
  });

  return (
    <ResetPasswordForm
      register={register}
      redirectToLogin={handleRedirectToLogin}
      errors={errors}
      handleResetPassword={handleResetPassword}
      goBack={() => {
        navigate(-1);
      }}
    />
  );
};
