import { Layout } from 'antd';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';

import { ILayout } from '@interfaces';
import { AuthHeaderModule, FooterModule, HeaderModule, InnerAppModule } from '@modules';
import { HEADER_HEIGHT, PATH_404, PATH_LOGIN, PATH_MAIN, PATH_SIGN_UP } from '@configs';
import { selectApp, selectAuth, useAppSelector } from '@redux';
import { useEffect, useLayoutEffect } from 'react';
import { LogApp } from '@utils';
const AUTH_LAYOUT_HAS_FOOTER = [PATH_SIGN_UP, PATH_LOGIN];
export const WebAuthLayout = (props: ILayout) => {
  const { children } = props;
  const navigate = useNavigate();
  const { themeMode, merchantId } = useAppSelector(selectApp);
  const location = useLocation();
  useLayoutEffect(() => {}, []);
  LogApp(location.pathname, 'location.pathname');
  return (
    <StyledWebAuthLayout className="auth-layout">
      <AuthHeaderModule />
      <Layout className="content-layout">
        <StyledPageInner>
          <InnerAppModule {...props}>{children}</InnerAppModule>
        </StyledPageInner>
      </Layout>
      {AUTH_LAYOUT_HAS_FOOTER.includes(location.pathname) && <FooterModule />}
    </StyledWebAuthLayout>
  );
};

const StyledWebAuthLayout = styled((props: any) => <Layout {...props} />)`
  min-height: 100vh;
`;

const StyledPageInner = styled.div<{
  sidebarCollapsed?: boolean;
  backgroundColor?: string;
  backgroundColor2?: string;
}>`
  background-color: ${(p: any) => p?.theme.colors?.bgPage};
  transition: all 0.3s;
  padding: ${HEADER_HEIGHT} 0 0 0;
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1;
`;
