import { Routes } from 'react-router';
import {
  BrowserRouter,
  Route,
  ScrollRestoration,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { Spin } from 'antd';
import { useEffect } from 'react';

import { IRoute, RootResponse } from '@interfaces';
import { authRoutes, routes } from 'src/routes';
import { AppLayout, DefaultLayout } from '@layouts';
import {
  logout,
  selectApp,
  selectAppLoading,
  selectAuth,
  selectThemeMode,
  setAccessToken,
  setVersion,
  showUnAuthModal,
  useAppDispatch,
  useAppSelector,
} from 'src/redux';
import { MAIN_THEME_DATA, PATH_LOGIN, enumThemeMode } from '@configs';
import { themes, useTheme } from '@theme';
import {
  AppModal,
  AppRoute,
  AppToast,
  AuthRoute,
  LoadingIndicator,
  SharedButton,
} from '@components';
import { useDispatch } from 'react-redux';
import { LogApp, useScrollToTop } from '@utils';
import { styled } from 'styled-components';
import { HomePage } from '@pages';
//@ts-ignore
import { NotificationContainer } from 'react-notifications';
import { authAPI } from '@api';

interface IProps {
  setTheme: (theme: any) => void;
}
export const ModuleMain = (props: IProps) => {
  const { setTheme } = props;
  const { setCurrentTheme } = useTheme();
  const themeMode = useAppSelector(selectThemeMode);
  const appLoading = useAppSelector(selectAppLoading);
  const { version } = useAppSelector(selectAuth);
  const dispatch = useAppDispatch();
  const checkVersion = async () => {
    try {
      const response: RootResponse<string> = await authAPI.checkVersion();
      if (!version || version !== response.data) {
        dispatch(logout());
        localStorage.clear();
        sessionStorage.clear();
      }
      dispatch(setVersion(response.data));
    } catch (error) {}
  };
  // DEV: Update theme when dev
  // if (process.env.NODE_ENV === 'development') {
  useEffect(() => {
    checkVersion();
  }, []);
  // }
  return (
    <Spin
      indicator={<LoadingIndicator />}
      className="app-loading"
      spinning={appLoading}
      size="large"
    >
      <BrowserRouter>
        <Routes>
          {/* Main Route */}
          <Route path="/" element={<AppRoute />}>
            {routes.map((route: IRoute, index: number) => {
              const Page = route.page;
              const Layout = route.layout || DefaultLayout;
              if (route.auth) return;
              return (
                <Route
                  key={index}
                  path={route.path}
                  element={
                    <Layout {...route}>
                      <Page />
                    </Layout>
                  }
                />
              );
            })}
          </Route>

          {/* Auth Route */}
          <Route path="/" element={<AuthRoute />}>
            {authRoutes.map((route: IRoute, index: number) => {
              const Page = route.page;
              const Layout = route.layout || DefaultLayout;
              return (
                <Route
                  key={index}
                  path={route.path}
                  element={
                    <Layout {...route}>
                      <Page />
                    </Layout>
                  }
                />
              );
            })}
          </Route>
        </Routes>
        <AppToast />
        <NotificationContainer />
      </BrowserRouter>
    </Spin>
  );
};
