import { AppLayout, AuthLayout, LayoutError } from '@layouts';
import { IRoute } from '@interfaces';
import {
  PageError404,
  LoginPage,
  SignUpPage,
  ForgotPasswordPage,
  VerifyOTPPage,
  ResetPasswordPage,
  SocialSignUpPage,
  ProfilePage,
  HomePage,
  CategoryPage,
  CartPage,
  OrderPage,
  ProductPage,
  MobileCategoryPage,
  OrderDetailPage,
  OrderSuccessPage,
  ProductSearchResultPage,
  SetupPasswordPage,
  OrderProgressPage,
  EditProfilePage,
  ChangePasswordPage,
  UpdateProfilePage,
} from '@pages';
import {
  PATH_404,
  PATH_HOME,
  PATH_LOGIN,
  PATH_FORGOT_PASSWORD,
  PATH_SIGN_UP,
  PATH_RESET_PASSWORD,
  PATH_VERIFY_OTP,
  PATH_PROFILE,
  PATH_EDIT_PROFILE,
  PATH_SOCIAL_SIGN_UP,
  PATH_CATEGORY,
  PATH_CART,
  PATH_ORDER,
  PATH_PRODUCT_DETAIL,
  PATH_CATEGORY_DETAIL,
  PATH_ORDER_DETAIL,
  PATH_ORDER_SUCCESS,
  PATH_SEARCH,
  PATH_SETUP_PASSWORD,
  PATH_ORDER_PROGRESS_DETAIL,
  PATH_CHANGE_PASSWORD,
  PATH_UPDATE_PROFILE,
} from './navigation';

export const routes: Array<IRoute> = [
  { path: PATH_HOME, page: HomePage, layout: AppLayout },
  { path: PATH_CATEGORY_DETAIL, page: CategoryPage, layout: AppLayout },
  { path: PATH_CATEGORY, page: MobileCategoryPage, layout: AppLayout },
  { path: PATH_CART, page: CartPage, layout: AppLayout },
  { path: PATH_ORDER, page: OrderPage, layout: AppLayout },
  { path: PATH_SEARCH, page: ProductSearchResultPage, layout: AppLayout },
  { path: PATH_ORDER_DETAIL, page: OrderDetailPage, layout: AppLayout },
  { path: PATH_ORDER_PROGRESS_DETAIL, page: OrderProgressPage, layout: AppLayout },
  { path: PATH_PROFILE, page: ProfilePage, layout: AppLayout },
  { path: PATH_PRODUCT_DETAIL, page: ProductPage, layout: AppLayout },
  { path: PATH_ORDER_SUCCESS, page: OrderSuccessPage, layout: AppLayout },
  { path: PATH_PROFILE, page: ProfilePage, layout: AppLayout },
  { path: PATH_CHANGE_PASSWORD, page: ChangePasswordPage, layout: AppLayout },
  { path: PATH_EDIT_PROFILE, page: EditProfilePage, layout: AppLayout },
  { path: PATH_404, page: PageError404, layout: LayoutError },
  { page: PageError404, layout: LayoutError },
];

export const normalRoutes = [PATH_HOME];

export const authRoutes: Array<IRoute> = [
  //auth
  { path: PATH_LOGIN, page: LoginPage, layout: AuthLayout, auth: true },
  { path: PATH_SIGN_UP, page: SignUpPage, layout: AuthLayout, auth: true },
  { path: PATH_SOCIAL_SIGN_UP, page: SocialSignUpPage, layout: AuthLayout, auth: true },
  { path: PATH_FORGOT_PASSWORD, page: ForgotPasswordPage, layout: AuthLayout, auth: true },
  { path: PATH_VERIFY_OTP, page: VerifyOTPPage, layout: AuthLayout, auth: true },
  { path: PATH_RESET_PASSWORD, page: ResetPasswordPage, layout: AuthLayout, auth: true },
  { path: PATH_SETUP_PASSWORD, page: SetupPasswordPage, layout: AuthLayout, auth: true },
  { path: PATH_UPDATE_PROFILE, page: UpdateProfilePage, layout: AuthLayout, auth: true },
  { path: PATH_404, page: PageError404, layout: LayoutError },
  { page: PageError404, layout: LayoutError },
];
