import { HomeDataPayload, IGetListParams, ProductFilterPayload } from '@interfaces';
import { ApiClient, DefaultApiParams } from './axiosClient';
import { MAX_ITEM_PER_PAGE } from '@configs';

export const homeAPI = {
  getTransactionHistory: (params: IGetListParams) => {
    const url = '/members/web/transaction-history';
    return ApiClient.get(url, params);
  },
  getProductByFilter: (payload: ProductFilterPayload) => {
    const url = `/products/filter/client?page=${payload.page}&size=${
      payload.size ?? MAX_ITEM_PER_PAGE
    }`;
    const newPayload: ProductFilterPayload = { ...payload, keyword: payload.keyword?.trim() };
    return ApiClient.post(url, newPayload);
  },
  getHomeData: (payload: HomeDataPayload) => {
    const url = `/home/client`;
    return ApiClient.post(url, payload);
  },
  getWarehouses: (params?: DefaultApiParams) => {
    const url = `/warehouses`;
    return ApiClient.get(url, { params });
  },
  getBanners: (params?: DefaultApiParams) => {
    const url = `/images/banners-client?status=true`;
    return ApiClient.get(url, { params });
  },
  searchProduct: (payload: ProductFilterPayload) => {
    const url = `/products/search-item?page=${payload.page}&size=${
      payload.size ?? MAX_ITEM_PER_PAGE
    }`;
    const newPayload: ProductFilterPayload = { ...payload, keyword: payload.keyword?.trim() };
    return ApiClient.get(url, { params: newPayload });
  },
  getHomeProduct: (payload: HomeDataPayload) => {
    const url = `/home/product-client`;
    return ApiClient.post(url, payload);
  },
};
