import { SharedButton, Trash } from '@components';
import { generateRandomId } from '@utils';
import Skeleton from 'react-loading-skeleton';
import { styled } from 'styled-components';
export const LoadingCartItem = ({
  type = 'order',
  hasBottomLine,
  isMobile,
}: {
  type: 'order' | 'cart';
  hasBottomLine?: boolean;
  isMobile?: boolean;
}) => {
  const isFromOrder = type === 'order';
  const imageSize = isMobile ? '5.6rem' : !isFromOrder ? '13.6rem' : '8rem';
  const nameHeight = isMobile ? '4rem' : !isFromOrder ? '4.8rem' : '2.4rem';
  const nameWidth = isMobile ? '16.5rem' : '32.8rem';
  const quantityWidth = !isFromOrder ? '12.8rem' : '1.5rem';
  const quantityHeight = !isFromOrder ? '3.2rem' : '2rem';
  const priceWidth = isMobile ? '7rem' : '8.8rem';
  return (
    <StyledLoadingCartItem isFromOrder={isFromOrder} hasBottomLine={hasBottomLine}>
      <Skeleton containerClassName="image-loading" width={imageSize} height={imageSize} />
      <div className="content-loading-block">
        <div className="head-loading-block">
          <Skeleton width={nameWidth} height={nameHeight} />
          <div className="price-block">
            <Skeleton width={priceWidth} height="2.4rem" />
            {!isFromOrder && <Skeleton width="7.1rem" height="2rem" />}
          </div>
        </div>
        <Skeleton width="10.2rem" height="2rem" />
        <div className="foot-block">
          <Skeleton width={quantityWidth} height={quantityHeight} />
          {!isFromOrder && <Trash color="#D20C00" />}
        </div>
      </div>
    </StyledLoadingCartItem>
  );
};
export const LoadingCart = ({
  count,
  type,
  isMobile,
}: {
  count: number;
  type: 'order' | 'cart';
  isMobile?: boolean;
}) => {
  const LOADING_ITEMS = Array.from({ length: count }, () => ({
    id: generateRandomId(),
  }));
  return (
    <StyledLoadingCart>
      {LOADING_ITEMS.map((item, index) => {
        return (
          <LoadingCartItem
            isMobile={isMobile}
            hasBottomLine={index !== count - 1}
            type={type}
            key={item.id}
          />
        );
      })}
    </StyledLoadingCart>
  );
};
export const LoadingBill = () => {
  return (
    <StyledLoadingBill>
      <Skeleton containerClassName="line" height="2.4rem" />
      <Skeleton containerClassName="line" height="2.4rem" />
      <Skeleton containerClassName="line" height="2.4rem" />
      <Skeleton containerClassName="line" height="2.4rem" />
      <Skeleton containerClassName="line" height="2.4rem" />
      <SharedButton text="Tiếp tục đặt hàng" />
    </StyledLoadingBill>
  );
};
const StyledLoadingCart = styled.div`
  box-shadow: 0px 1px 16px 0px rgba(56, 56, 56, 0.15);
  border-radius: 10px;
  background-color: white;
`;
const StyledLoadingCartItem = styled.div<{ hasBottomLine?: boolean; isFromOrder: boolean }>`
  display: flex;
  padding: 1.6rem;
  border-bottom-width: ${(p) => (p?.hasBottomLine ? '1px' : '0px')};
  padding: ${(p) => (!p?.isFromOrder ? '1.6rem' : '0 0 1.6rem 0')};
  border-bottom-color: rgb(52, 52, 52, 0.15);
  .image-loading {
    border-radius: 10px;
    margin-right: 1.6rem;
  }
  .content-loading-block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    .foot-block {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }
    .head-loading-block {
      display: flex;
      justify-content: space-between;
      .price-block {
        display: flex;
        flex-direction: column;
      }
    }
  }
`;
const StyledLoadingBill = styled.div<{ isTotalPrice?: boolean }>`
  .react-loading-skeleton {
    margin-bottom: 1.6rem;
  }
`;
