import { IMAGES } from '@assets';
import { MAIN_THEME_DATA, enumNavKey } from '@configs';
import { FooterContactItem } from '@interfaces';
import { Image } from 'antd';
// import { FooterContactItem } from '@interfaces';
import {
  CartIcon,
  CategoryIcon,
  FacebookIcon,
  HomeIcon,
  OrderIcon,
  PhoneIcon,
  SendIcon,
  TwitterIcon,
  UserIcon,
  YoutubeIcon,
} from 'src/components/Icon';
import { ShareInput } from 'src/components/shared';
import styled from 'styled-components';
interface SendEmailColProps {
  onSendMail: () => void;
}
export const SendEmailCol = ({ onSendMail }: SendEmailColProps) => {
  return (
    <StyledSendEmailCol>
      <Image preview={false} src={IMAGES.color_logo} />
    </StyledSendEmailCol>
  );
};
const StyledSendEmailCol = styled.div`
  .social-block {
    display: flex;
    flex-direction: row;
    margin-top: 4rem;
    .social-item {
      margin-right: 1.6rem;
    }
  }
  .send-icon {
    background-color: #d20c00;
    height: 4.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4.6rem;
  }
  .suf-icon {
    right: 0rem !important;
    bottom: 0rem !important;
  }
`;
