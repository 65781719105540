import { styled } from 'styled-components';
import {
  LoadingList,
  Pagination,
  ShareSelectInput,
  SharedButton,
  SharedCheckbox,
  SharedDropdown,
} from '../../shared';
import { List, Radio, Select, Slider, Tabs, TabsProps } from 'antd';
import { MAIN_THEME_DATA } from '@configs';
import { HomeCardItem } from '../../home/components/HomeCardItem';
import { Category, NewArrivalProduct, ProductFilter, ProductFilterData } from '@interfaces';
import { LogApp, toVND } from '@utils';
import { selectHome } from 'src/redux/Slices/home';
import { useAppSelector } from '@redux';
const MIN_PRICE = 0;
const MAX_PRICE = 100000000;
const STEP = 100000;
const PRICE_SELECTION = [
  { id: 1, title: 'Dưới 100.000 VND', min: 0, max: 100000 },
  { id: 2, title: '100.000 VND - 300.000 VND', min: 100000, max: 300000 },
  { id: 3, title: '300.000 VND - 500.000 VND', min: 300000, max: 500000 },
  { id: 4, title: '500.000 VND - 1.000.000 VND', min: 500000, max: 1000000 },
  { id: 5, title: 'Trên 1.000.000 VND', min: 1000000, max: MAX_PRICE },
];
interface CategorySectionProps {
  products: ProductFilterData;
  defaultActiveKey?: string;
  navigateToProductDetail: (id: string) => void;
  onChangeSubCategory: (key: string) => void;
  category: Category;
  tabItems: Array<{ key: string; label: string }>;
  isLoading: boolean;
  onPageChange: (page: number) => void;
  payload: any;
  onFilterBrand: (value: string) => void;
  onFilterSupplier: (value: string) => void;
  onFilterPrice: (value: number[]) => void;
  getProductBySubCategory: () => void;
  filters: {
    brand: string | null;
    supplier: string | null;
    min_price: number;
    max_price: number;
  };
}
export const CategorySection = (props: CategorySectionProps) => {
  const {
    navigateToProductDetail,
    category,
    tabItems,
    defaultActiveKey,
    products,
    onChangeSubCategory,
    isLoading,
    onPageChange,
    payload,
    onFilterBrand,
    onFilterPrice,
    getProductBySubCategory,
    filters,
  } = props;
  const { currentChildCategory } = useAppSelector(selectHome);
  return (
    <>
      <StyledBreadCrumb>
        <div className="bread-crumb-wrapper">
          <div className="bread-crumb-item">
            <span>{category.name} /&nbsp;</span>
            <span className={`red`}>{currentChildCategory?.name}</span>
          </div>
        </div>
      </StyledBreadCrumb>
      <StyledCategorySection>
        <div className="head-block">
          <div className="filter-block">
            <span className="select-title">Bộ lọc:</span>
            <ShareSelectInput
              onChange={(v) => {
                onFilterBrand(v);
              }}
              data={products.brands}
              placeholder="Nhãn hàng"
              className="select mr"
            />
            <ShareSelectInput
              dropdownStyle={{ minWidth: '40rem', padding: 16 }}
              dropdownRender={() => {
                return (
                  <div>
                    <Radio.Group
                      onChange={(e) => {
                        onFilterPrice([e.target.value.min, e.target.value.max]);
                      }}
                    >
                      {PRICE_SELECTION.map((item) => {
                        return (
                          <SharedCheckbox
                            type="radio"
                            value={item}
                            style={{ marginBottom: '1rem' }}
                            key={item.id}
                            text={item.title}
                          />
                        );
                      })}
                    </Radio.Group>
                    <div className="flex justify-between">
                      <SharedButton
                        textStyle={{ fontSize: 14 }}
                        style={{ paddingTop: 6, paddingBottom: 6, marginRight: 16 }}
                        onClick={() => {}}
                        btnStyle="line"
                        textColor={MAIN_THEME_DATA.mainColor}
                        text="Đóng"
                      />
                      <SharedButton
                        textStyle={{ fontSize: 14 }}
                        style={{ paddingTop: 7, paddingBottom: 7 }}
                        onClick={() => {
                          getProductBySubCategory();
                        }}
                        text="Xem kết quả"
                      />
                    </div>
                  </div>
                );
              }}
              placeholder="Khoảng giá"
              className="select"
              data={[]}
            />
          </div>
          {/* <div className="sort-block">
          <span className="select-title select-sort-title">Sắp xếp: </span>
          <ShareSelectInput placeholder="Xếp theo" className="select" data={[]} />
        </div> */}
        </div>
        <div className="main-block">
          <h2>{category.name}</h2>
          <Tabs
            tabBarStyle={{
              borderBottomWidth: 1,
              borderBottomColor: '#D9D9D9',
            }}
            onChange={(key) => {
              onChangeSubCategory(key);
            }}
            activeKey={defaultActiveKey}
            items={tabItems}
          />
          {isLoading ? (
            <LoadingList rows={2} count={10} />
          ) : (
            <List
              style={{ marginTop: '2.4rem' }}
              grid={{
                gutter: 16,
                xs: 5,
                sm: 5,
                md: 5,
                lg: 5,
                xl: 5,
                xxl: 5,
              }}
              dataSource={products.data}
              renderItem={(item: ProductFilter) => (
                <List.Item>
                  <HomeCardItem
                    onItemClick={() => {
                      navigateToProductDetail(item.code);
                    }}
                    hasPrice
                    footerClassName="product-footer-item"
                    title={item.name}
                    image={item.images?.[0]?.url}
                    content={item?.selling_price?.toLocaleString('it-IT', {
                      style: 'currency',
                      currency: 'VND',
                    })}
                    footer={''}
                    tags={[]}
                  />
                </List.Item>
              )}
            />
          )}
          <Pagination
            className="pagination-bar"
            currentPage={payload.page}
            totalCount={products.limit}
            pageSize={products.count}
            onPageChange={(page: number) => {
              onPageChange(page);
            }}
          />
        </div>
      </StyledCategorySection>
    </>
  );
};
const StyledCategorySection = styled.div`
  padding: 0 12rem;
  margin-top: 4rem;
  .pagination-bar {
    margin-top: 1rem;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .ant-tabs-top > .ant-tabs-nav {
    margin: 0;
  }
  .head-block {
    display: flex;
    .filter-block {
      display: flex;
      align-items: center;
      width: 32%;
      margin-right: 2.4rem;
      .select {
        width: calc((100% - 14% - 1.6rem - 0.8rem) / 2);
      }
      .mr {
        margin-right: 1.6rem;
      }
    }
    .sort-block {
      display: flex;
      align-items: center;
      width: 19%;
      .select {
        width: 70%;
      }
      .select-sort-title {
        width: 30%;
      }
    }
    .select-title {
      width: 14%;
      color: #383838;
      font-size: 1.7rem;
      font-family: Roboto-Regular;
      font-weight: 400;
      line-height: 23.8px;
      margin-right: 0.8rem;
    }
  }
  .main-block {
    margin-top: 4rem;
    h2 {
      //styleName: Size 24/Medium;
      font-family: Roboto-Medium;
      font-size: 2.4rem;
      font-weight: 500;
      line-height: 34px;
    }
    .ant-tabs-tab-btn {
      color: ${MAIN_THEME_DATA.mainText};
      font-size: 1.7rem;
      font-family: Roboto-Regular;
      font-weight: 400;
      line-height: 23.8px;
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: ${MAIN_THEME_DATA.mainColor};
      font-size: 1.7rem;
      font-family: Roboto-Medium;
      font-weight: 500;
      line-height: 23.8px;
    }
    .ant-tabs-ink-bar {
      background: ${MAIN_THEME_DATA.mainColor};
      height: 3px;
    }
    .product-footer-item {
      text-align: left;
      font-family: Roboto-Regular;
      font-size: 1.2rem;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 16.8px */
      color: ${MAIN_THEME_DATA.mainText};
    }
  }
`;
const StyledBreadCrumb = styled.div`
  padding: 1rem 0;
  background-color: #ffd0cd;
  padding-left: 12rem;
  .bread-crumb-wrapper {
    display: flex;
    .red {
      color: ${MAIN_THEME_DATA.mainColor} !important;
    }
    .slash {
      margin: 0 0.8rem;
    }
    .bread-crumb-item {
      display: flex;
      span {
        color: ${MAIN_THEME_DATA.mainText};
        font-size: 1.4rem;
        font-family: Roboto-Regular;
        font-weight: 400;
        line-height: 19.6px;
        display: inline-block;
      }
    }
  }
`;
