import { UpdatePasswordPayload, UpdateUserPayload } from '@interfaces';
import { ApiClient } from './axiosClient';

export const userAPI = {
  getUserInfo: (id: string) => {
    const url = `/customers/${id}`;
    return ApiClient.get(url);
  },
  updateUserInfo: (id: string, payload: UpdateUserPayload) => {
    const url = `/customers/${id}`;
    return ApiClient.put(url, payload);
  },
  updatePassword: (id: string, payload: UpdatePasswordPayload) => {
    const url = `/accounts/${id}/change-password`;
    return ApiClient.post(url, payload);
  },
};
