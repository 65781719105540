import { MAIN_THEME_DATA } from '@configs';
import {
  AddressItem,
  AuthAutocompleteData,
  SellerItem,
  UserInfo,
  WarehouseItem,
} from '@interfaces';
import { OPTION_VALUE } from '@pages';
import { FieldErrorsImpl, UseFormRegister, UseFormWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ChangePasswordForm } from '../components/ChangePasswordForm';
import { EditProfileForm } from '../components/EditProfileForm';
interface IProfileForm {
  selectedId: number;
  register: UseFormRegister<any>;
  handleChangePassword: any;
  errors: Partial<FieldErrorsImpl<any>>;
  userInfo: UserInfo;
  avatar: string;
}
export const WebProfileForm = (props: IProfileForm) => {
  const { errors, register, userInfo, handleChangePassword, selectedId, avatar } = props;
  const navigate = useNavigate();
  return (
    <StyledProfileForm>
      {selectedId === OPTION_VALUE.ACCOUNT ? (
        <EditProfileForm avatar={avatar} userInfo={userInfo} />
      ) : (
        <ChangePasswordForm
          handleChangePassword={handleChangePassword}
          register={register}
          errors={errors}
        />
      )}
    </StyledProfileForm>
  );
};
const StyledProfileForm = styled.div`
  background-color: white;
  padding: 2.4rem;
  border-radius: 1.2rem;
  width: 100%;
  .actions {
    width: fit-content;
    margin-top: 2.4rem;
  }
  .user-input {
    margin-bottom: 1.6rem;
  }
  .user-form {
    margin-top: 2.4rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .ant-select-selection-search {
      font-size: 1.7rem;
    }
    .ant-picker,
    .ant-select-selector {
      height: 4.6rem;
    }
    .shared-input {
      .inner-input {
        height: 4.8rem;
      }
    }
  }
  .user-info {
    background-color: ${MAIN_THEME_DATA.mainColor};
    display: flex;
    justify-content: space-between;
    padding: 2.4rem;
    border-radius: 1.2rem;
    .base-info {
      display: flex;
      flex-direction: column;
      span {
        display: inline-block;
        margin-bottom: 0.4rem;
        color: white;
        font-size: 1.7rem;
      }
      .name {
        font-size: 2.4rem;
        font-family: Roboto-Medium;
        font-weight: 500;
      }
    }
    .user-avatar {
      width: 12.4rem;
      aspect-ratio: 1;
      border-radius: 100px;
      img {
        border-radius: 100px;
      }
    }
  }
  .change-password-form {
    width: 57%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
`;
