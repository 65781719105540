import { MAIN_THEME_DATA, testImg } from '@configs';
import { ProductDetailResponse } from '@interfaces';
import { toVND } from '@utils';
import { IConCancel, XIcon } from 'src/components/Icon';
import { SharedButton, SharedImage } from 'src/components/shared';
import { styled } from 'styled-components';
interface CartNotificationProps {
  product: ProductDetailResponse;
  quantity: number;
  attribute: string;
  onClose: () => void;
  navigate: () => void;
}
export const CartNotification = (props: CartNotificationProps) => {
  const { product, quantity, attribute, navigate } = props;
  return (
    <StyledCartNotification>
      <div className="heading">
        <span className="product-title size-17 medium-font weight-500">
          Đã thêm <span className="quantity">{quantity}</span> sản phẩm vào giỏ hàng
        </span>
        <XIcon />
      </div>
      <div className="content">
        <SharedImage containerClassName="img-ctn" src={product?.images?.[0]?.url} />
        <div className="text-block">
          <span className="text weight-500 medium-font mb-4r size-17">{product.name}</span>
          <span className="text  mb-8r">
            Phân loại: <span className="size weight-500 red-text">{attribute}</span>
          </span>
          <span className="text size-17 weight-500 medium-font red-text mb-4r">
            {toVND(product.selling_price)}
          </span>
          <span className="text original-price">{toVND(product.selling_price)}</span>
        </div>
      </div>
      <SharedButton onClick={navigate} text="Xem giỏ hàng" />
    </StyledCartNotification>
  );
};
const StyledCartNotification = styled.div`
  width: 100%;
  padding: 1.6rem 2.4rem;
  .heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom-width: 1px;
    border-bottom-color: #eaeaea;
    padding-bottom: 1.2rem;
  }
  .mb-4r {
    margin-bottom: 0.4rem;
  }
  .mb-8r {
    margin-bottom: 0.8rem;
  }
  .medium-font {
    font-family: Roboto-Medium;
  }
  .weight-500 {
    font-weight: 500;
  }
  .size-17 {
    font-size: 1.7rem;
    line-height: 19.6px;
  }
  .red-text {
    color: ${MAIN_THEME_DATA.mainColor};
  }
  .content {
    margin-bottom: 2.4rem;
    span {
      display: inline-block;
    }
    margin-top: 1.6rem;
    display: flex;
    .img-ctn {
      width: 9.6rem;
      aspect-ratio: 1;
      border-radius: 10px;
      img {
        border-radius: 10px;
      }
    }
    .text-block {
      display: flex;
      flex-direction: column;
      margin-left: 1.6rem;
      .text {
        font-size: 1.4rem;
        line-height: 140%;
      }
      .original-price {
        text-decoration: line-through;
      }
    }
  }
  .product-title {
    .quantity {
      color: ${MAIN_THEME_DATA.mainColor};
    }
  }
`;
