import { BaseSyntheticEvent, memo } from 'react';
import { FieldErrorsImpl, UseFormRegister, UseFormSetValue, UseFormWatch } from 'react-hook-form';

import { IConArrowLeft, SharedButton, ShareInput } from '@components';
import { MAIN_THEME_DATA } from '@configs';
import {
  AddressItem,
  AuthAutocompleteData,
  AutocompleteItem,
  RegisterData,
  SellerItem,
  WarehouseItem,
} from '@interfaces';
import { UploadImageModule } from '@modules';
import { selectApp, useAppSelector } from '@redux';
import { useTheme } from '@theme';
import { LogApp } from '@utils';
import styled from 'styled-components';
import { SharedAutocomplete } from '../../shared/autocomplete';
import { StyledMobileAuth } from '../Login';

interface IProps {
  errors: Partial<FieldErrorsImpl<any>>;
  agreePolicy?: boolean;
  isSocialSignUp?: boolean;
  watch: UseFormWatch<any>;
  register: UseFormRegister<any>;
  redirectToLogin?: () => void;
  handleSignUp: (e?: BaseSyntheticEvent<any, any, any> | undefined) => Promise<void>;
  setValue: UseFormSetValue<any>;
  changeAgreePolicy?: () => void;
  onSelectProvince: (value: AddressItem) => void;
  onSelectDistrict: (value: AddressItem) => void;
  onSelectWard: (value: AddressItem) => void;
  onSelectWarehouse: (value: WarehouseItem) => void;
  onSelectSeller: (value: SellerItem) => void;
  autocompleteData: AuthAutocompleteData;
  registerInfo?: RegisterData;
  changeSelectedImage: (value: any) => void;
  imageError: string;
}

export const AppSignUpForm = memo((props: IProps) => {
  const {
    errors,
    agreePolicy,
    isSocialSignUp,
    watch,
    register,
    handleSignUp,
    redirectToLogin,
    changeAgreePolicy,
    setValue,
    onSelectDistrict,
    onSelectProvince,
    onSelectWard,
    autocompleteData,
    registerInfo,
    onSelectWarehouse,
    changeSelectedImage,
    imageError,
    onSelectSeller,
  } = props;

  const { theme } = useTheme();

  LogApp('imageError', imageError);

  const { themeData } = useAppSelector(selectApp);

  return (
    <StyledMobileAuth className="register-page">
      <StyledSignUpForm>
        <div className="avatar-wrapper">
          <UploadImageModule
            defaultUrl={registerInfo?.image_url}
            onUploadEnd={changeSelectedImage}
            typeUpload="image"
          />
          {imageError && <span className="error-text">{imageError}</span>}
          <span className="avatar-title">Logo, Ảnh cửa hàng</span>
        </div>
        {/* @ts-ignore */}
        <form onSubmit={handleSignUp} className="signUp-form">
          <ShareInput
            required
            label="Họ và tên"
            placeholder="Nhập họ và tên"
            name="full_name"
            className="input"
            type="text"
            onChange={() => {}}
            errors={errors['full_name']?.message}
            register={register}
          />
          <ShareInput
            required
            label="Số điện thoại"
            placeholder="Nhập số điện thoại (Đã đăng ký Zalo)"
            name="phone_number"
            className="input hidden-arrow-num-input"
            onChange={() => {}}
            errors={errors['phone_number']?.message}
            register={register}
          />
          <SharedAutocomplete
            onSelect={(value) => {
              onSelectProvince(value.item);
            }}
            containerClassName="input mx"
            data={autocompleteData.provinces}
            label={'Tỉnh/thành phố'}
            placeholder="Nhập tỉnh/thành phố"
            defaultValue={registerInfo?.province?.name}
            key={`${registerInfo?.province?.code}:province`}
            required
            filterOption={(inputValue: string, option: AutocompleteItem<AddressItem>) =>
              option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
            }
            errors={errors['province_code']?.message}
          />
          <SharedAutocomplete
            onSelect={(value) => {
              onSelectDistrict(value.item);
            }}
            containerClassName="input mx"
            data={autocompleteData.districts}
            label={'Quận/huyện'}
            placeholder="Nhập quận/huyện"
            defaultValue={registerInfo?.district?.name}
            key={`${registerInfo?.district?.code}:district`}
            required
            filterOption={(inputValue: string, option: AutocompleteItem<AddressItem>) =>
              option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
            }
            errors={errors['district_code']?.message}
          />
          <SharedAutocomplete
            onSelect={(value) => {
              onSelectWard(value.item);
            }}
            containerClassName="input"
            data={autocompleteData.wards}
            label={'Phường/xã'}
            placeholder="Nhập phường/xã"
            defaultValue={registerInfo?.ward?.name}
            key={`${registerInfo?.ward?.code}:ward`}
            required
            errors={errors['ward_code']?.message}
            filterOption={(inputValue: string, option: AutocompleteItem<AddressItem>) =>
              option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
            }
          />
          <SharedAutocomplete
            onSelect={(value) => {
              onSelectWarehouse(value.item);
            }}
            containerClassName="input"
            data={autocompleteData.warehouses}
            label={'Kho'}
            placeholder="Chọn kho"
            defaultValue={registerInfo?.warehouse?.name}
            key={`${registerInfo?.warehouse?.id}:warehouse`}
            required
            errors={errors['warehouse_id']?.message}
            filterOption={(inputValue: string, option: AutocompleteItem<WarehouseItem>) =>
              option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
            }
          />
          <SharedAutocomplete
            onSelect={(value) => {
              onSelectSeller(value.item);
            }}
            containerClassName="input"
            data={autocompleteData.sellers}
            label={'Seller'}
            placeholder="Chọn seller"
            defaultValue={registerInfo?.seller?.name}
            key={`${registerInfo?.seller?.id}:seller`}
            required
            errors={errors['seller_id']?.message}
            filterOption={(inputValue: string, option: AutocompleteItem<SellerItem>) =>
              option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
            }
          />
          <ShareInput
            required
            label="Địa chỉ"
            placeholder="Nhập địa chỉ"
            name="address_detail"
            className="input"
            type="text"
            onChange={() => {}}
            errors={errors['address_detail']?.message}
            register={register}
          />
          <div className="actions">
            <SharedButton
              typeHtml="submit"
              text="Tiếp tục"
              className="submit__btn"
              backgroundColor={MAIN_THEME_DATA.mainColor}
              btnStyle="pad"
            />
          </div>
        </form>
      </StyledSignUpForm>
    </StyledMobileAuth>
  );
});
const StyledSignUpForm = styled.div`
  .inner-input {
    height: 4.8rem;
  }
  .input {
    margin-bottom: 1.6rem;
  }
  .avatar-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .avatar-title {
      font-size: 1.4rem;
      color: #000;
      margin-top: 1.6rem;
      display: inline-block;
    }
  }
`;
