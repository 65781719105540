import styled from 'styled-components';

import { AppModal, SharedButton } from '@components';
import { MAIN_THEME_DATA, PATH_LOGIN } from '@configs';
import { ILayout } from '@interfaces';
import { selectApp, showUnAuthModal, useAppDispatch, useAppSelector } from '@redux';
import { useMediaQuery, useScrollToTop } from '@utils';
import { useNavigate } from 'react-router-dom';
import { MobileLayout } from './MobileLayout';
import { WebLayout } from './WebLayout';
export const AppLayout = (props: ILayout) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery('(max-width: 768px)');
  const { isUnAuthModalShow } = useAppSelector(selectApp);
  const onCloseModal = () => {
    dispatch(showUnAuthModal(false));
  };
  const onNavigateToLogin = () => {
    navigate(PATH_LOGIN);
  };
  useScrollToTop();
  return <>{isMobile ? <MobileLayout {...props} /> : <WebLayout {...props} />}</>;
};
const StyledModalInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50rem;
  .title {
    font-family: Roboto-Medium;
    font-size: 2.4rem;
    font-weight: 500;
    line-height: 140%;
    margin-bottom: 0.5rem;
  }
  .message {
    font-size: 1.7rem;
    font-weight: 500;
    line-height: 140%;
  }
  .btn-block {
    display: flex;
    width: 100%;
    margin-top: 0.8rem;
    .mr {
      margin-right: 2.4rem;
    }
  }
`;
