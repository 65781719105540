import { MAIN_THEME_DATA } from '@configs';
import styled from 'styled-components';

export const StyledSignUpForm = styled.section`
  width: 55%;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 2.4rem 3.2rem 3.2rem 3.2rem;
  border-radius: 1.2rem;
  margin-bottom: 4.4rem;
  .reason {
    font-size: 1.7rem;
    font-style: italic;
    .red {
      color: ${MAIN_THEME_DATA.mainColor};
    }
  }
  .error-text {
    color: ${MAIN_THEME_DATA.mainColor};
    margin-top: 1rem;
    display: inline-block;
  }
  .ant-select-selection-search {
    font-size: 1.7rem;
  }
  .avatar-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .avatar-title {
      font-size: 1.7rem;
      color: #000;
      margin-top: 1.6rem;
      display: inline-block;
    }
  }

  @media (min-width: 1280px) {
    min-width: 41rem;
  }
  .heading {
    position: relative;
    display: flex;
    justify-content: center;
    .icon {
      position: absolute;
      left: 0;
      top: 2px;
      cursor: pointer;
    }
  }

  .actions {
    @media (min-width: 1280px) {
      display: flex;
      align-items: center;
      .btn {
        width: 100%;
      }
    }
  }

  .signUp-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    .shared-input {
      margin-bottom: 2rem;
      .inner-input {
        height: 4.8rem;
      }
    }
    .form-input {
      margin-bottom: 2rem;
      .ant-picker,
      .ant-select-selector {
        height: 4.6rem;
      }
    }
    .submit__btn {
      @media (min-width: 1280px) {
        margin-right: 1.2rem;
        margin-bottom: 0;
      }
      margin-bottom: 1.2rem;
    }
    .forgot-password {
      display: flex;
      justify-content: flex-end;
      text-align: right;
      .forgot-text {
        font-size: 1em;
        font-weight: 400;
        color: ${(p) => p.theme.colors.authText};
        margin-bottom: 3.6rem;
      }
    }

    .inner-input {
      width: 100%;
      height: 4.6rem;
    }

    .agree-policy__cb {
      margin-bottom: 1.2rem;
    }
  }
`;
