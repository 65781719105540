import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import 'react-horizontal-scrolling-menu/dist/styles.css';
import { HomeCardItem } from '../../HomeCardItem';
import { NewArrivalProduct, ProductsHotSelling, Promotion } from '@interfaces';
import React, { MouseEventHandler, PropsWithChildren } from 'react';
import { IConArrowLeft, IConArrowRight } from 'src/components/Icon';
import { MAIN_THEME_DATA, PATH_PRODUCT } from '@configs';
import { styled } from 'styled-components';
import { StyledList } from './list.styled';
import { generateRandomId, toVND } from '@utils';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { LoadingList, StyledSkeletonItem } from '@components';
import { useNavigate } from 'react-router-dom';

interface ProductListProps {
  title: string;
  data: ProductsHotSelling[];
  footerClassNameItem?: string;
  hasPrice?: boolean;
  isLoading?: boolean;
  onItemClick: (item: ProductsHotSelling) => void;
}
export function LeftArrow({
  className,
  onClick,
}: {
  className?: string;
  onClick?: MouseEventHandler<HTMLElement>;
}) {
  const { isFirstItemVisible, scrollPrev } = React.useContext(VisibilityContext);
  return (
    <div
      className={`arrow arrow-left ${className}`}
      onClick={(e) => {
        if (onClick) onClick(e);
        else scrollPrev();
      }}
    >
      {/* <IConArrowRight color={MAIN_THEME_DATA.mainColor} /> */}
      <IConArrowLeft color={MAIN_THEME_DATA.mainColor} />
    </div>
  );
}
export function RightArrow({
  className,
  onClick,
}: {
  className?: string;
  onClick?: MouseEventHandler<HTMLElement>;
}) {
  const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext);
  return (
    <div
      className={`arrow arrow-right ${className}`}
      onClick={(e) => {
        if (onClick) onClick(e);
        else scrollNext();
      }}
    >
      <IConArrowRight color={MAIN_THEME_DATA.mainColor} />
    </div>
  );
}

export const WebHomeList = ({
  data,
  title,
  footerClassNameItem,
  hasPrice,
  isLoading,
  onItemClick,
}: ProductListProps) => {
  return (
    <StyledListWrapper>
      <h2>{title}</h2>
      {isLoading ? (
        <LoadingList wrapperWidth="calc(100% - 24rem);" rows={1} count={5} />
      ) : (
        <StyledList>
          <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
            {data.map((item) => (
              <HomeCardItem
                hasPrice={hasPrice}
                onItemClick={() => {
                  onItemClick(item);
                }}
                title={item.name}
                image={item.images?.[0]?.url}
                content={toVND(item?.selling_price)}
                footer={''}
                footerClassName={footerClassNameItem}
                tags={[]}
              />
            ))}
          </ScrollMenu>
        </StyledList>
      )}
    </StyledListWrapper>
  );
};

const StyledListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  h2 {
    font-family: Roboto-Medium;
    font-size: 2.4rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 33.6px */
    width: calc(100% - 24rem);
    margin-top: 4rem;
    margin-bottom: 2.4rem;
  }
`;
