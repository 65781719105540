import {
  enumNavKey,
  enumRuleType,
  enumCashbackType,
  enumStatus,
  enumGender,
  enumPageSize,
} from './enum';
import {
  PATH_CART,
  PATH_CATEGORY,
  PATH_CHANGE_PASSWORD,
  PATH_EDIT_PROFILE,
  PATH_FORGOT_PASSWORD,
  PATH_HOME,
  PATH_LOGIN,
  PATH_ORDER,
  PATH_ORDER_PROGRESS,
  PATH_ORDER_SUCCESS,
  PATH_PROFILE,
  PATH_RESET_PASSWORD,
  PATH_SETUP_PASSWORD,
  PATH_SIGN_UP,
  PATH_VERIFY_OTP,
} from './../routes/navigation';

export const DEFAULT_LANGUAGE = process.env.REACT_APP_LANGUAGE || 'en';
const ALL_THEMES = 'themes';
const CURRENT_THEME = 'theme';

const SIDEBAR_WIDTH = '260px';
const SIDEBAR_COLLAPSED_WIDTH = '105px';
const SIDEBAR_ICON_SIZE = '24px';
const HEADER_HEIGHT = '12.4rem';
const HEADER_PADDING_TOP = '0px';
const MOBILE_HEADER_HEIGHT = '12rem';
const MOBILE_AUTH_HEADER_HEIGHT = '7.2rem';
const MOBILE_FOOTER_HEIGHT = '5.6rem';
const SIDEBAR_DATA = [
  {
    label: 'Dashboard',
    path: PATH_HOME,
    pathKey: enumNavKey.HOME,
  },
  // {
  //   label: 'Setting',
  //   path: PATH_SETTING,
  //   pathKey: enumNavKey.SETTING,
  //   subItems: [
  //     {
  //       label: 'COLOR',
  //       path: PATH_COLOR_SETTINGS,
  //       pathKey: enumNavKey.COLOR_SETTING,
  //       subOptions: [],
  //     },
  //   ],
  // },
];
const MAX_ITEM_PER_PAGE = 10;
const NAV_BAR = [
  { title: 'Trang chủ', path: PATH_HOME, pathKey: enumNavKey.HOME },
  { title: 'Danh mục', path: PATH_CATEGORY, pathKey: enumNavKey.CATEGORY },
  { title: 'Giỏ hàng', path: PATH_CART, pathKey: enumNavKey.CART },
  { title: 'Đơn hàng', path: PATH_ORDER, pathKey: enumNavKey.ORDER },
  { title: 'Tài khoản', path: PATH_PROFILE, pathKey: enumNavKey.PROFILE },
];
const WEB_NAV_BAR = [
  { title: 'Trang chủ', path: PATH_HOME, pathKey: enumNavKey.HOME },
  { title: 'Danh mục', path: PATH_CATEGORY, pathKey: enumNavKey.CATEGORY },
  { title: 'Giỏ hàng', path: PATH_CART, pathKey: enumNavKey.CART },
  { title: 'Đơn hàng', path: PATH_ORDER, pathKey: enumNavKey.ORDER },
];
const INTRO_COL = [
  { id: 1, title: 'Dong Duong Sport', link: 'https://dongduongsport.com/gioi-thieu/' },
  { id: 2, title: 'Nhượng quyền', link: 'https://dongduongsport.com/nhuong-quyen/' },
  { id: 3, title: 'Sản phẩm phân phối', link: 'https://dongduongsport.com/san-pham-phan-phoi/' },
  { id: 4, title: 'Tạp Chí 360', link: 'https://dongduongsport.com/tap-chi-360/' },
  { id: 5, title: 'Liên hệ', link: 'https://dongduongsport.com/lien-he/' },
  {
    id: 6,
    title: 'Tuyển Dụng',
    link: 'https://dongduongsport.com/thong-tin-tuyen-dung/tuyen-dung-nhan-su/',
  },
];
const POLICY_COL = [
  { id: 1, title: 'Điều khoản chung', link: 'https://dongduongsport.com/dieu-khoan-chung/' },
  { id: 2, title: 'Chính sách bảo mật', link: 'https://dongduongsport.com/chinh-sach-bao-mat/' },
  { id: 3, title: 'Chính sách bảo hành' },
  { id: 4, title: 'Chính sách đổi trả', link: 'https://dongduongsport.com/chinh-sach-doi-tra/' },
  {
    id: 5,
    title: 'Chính sách vận chuyển',
    link: 'https://dongduongsport.com/chinh-sach-van-chuyen/',
  },
  { id: 6, title: 'Hướng dẫn đặt hàng', link: 'https://dongduongsport.com/huong-dan-dat-hang/' },
  { id: 7, title: 'Hình thức thanh toán', link: 'https://dongduongsport.com/huong-dan-dat-hang/#' },
];

const AUTH_THEME_COLOR = '#B10000';

const AUTH_THEME_DEFAULT_COLOR = '#980000';

const MAIN_THEME_DATA = {
  mainColor: '#D20C00',
  mainText: '#383838',
  bgColor: '#FFF4F3',
};

const MAT_SM_SCREEN_WIDTH = '1279px';
const MAT_SM_SCREEN_WIDTH_MIN = '1280px';

const RESET = 'RESET';
const ERROR_CODES = {
  UNAUTHORIZED: 401,
};
const CASHBACK_RULE_TYPE = [
  {
    value: enumRuleType.standard,
    label: 'Standard Cashback Rule',
  },
  {
    value: enumRuleType.superior,
    label: 'Superior Cashback Rule',
  },
];

const CASHBACK_TYPE = [
  {
    value: enumCashbackType.cost,
    label: '$',
  },
  {
    value: enumCashbackType.percent,
    label: '%',
  },
];

const STATUS_TYPE = [
  {
    value: enumStatus.ACTIVE,
    label: 'Active',
  },
  {
    value: enumStatus.INACTIVE,
    label: 'Inactive',
  },
  {
    value: enumStatus.NONE,
    label: 'None',
  },
];

const GENDER_TYPE = [
  {
    value: enumGender.MALE,
    label: 'Male',
  },
  {
    value: enumGender.FEMALE,
    label: 'Female',
  },
];
export const TOP_FILTER = [
  {
    value: 10,
    label: 'Top 10',
  },
  {
    value: 20,
    label: 'Top 20',
  },
  {
    value: 50,
    label: 'Top 50',
  },
];

const PAGE_SIZE_OPTIONS = [
  {
    value: enumPageSize.LIMIT_10,
    label: '10',
  },
  {
    value: enumPageSize.LIMIT_20,
    label: '20',
  },
  {
    value: enumPageSize.LIMIT_50,
    label: '50',
  },
];

export const ruleDateFormat = 'DD-MM-YYYY';
export const ruleTimeFormat = 'HH:mm:ss';
export const dateOfBirthFormat = 'DD/MM/YYYY';
export const dateTimeFormat = 'DD-MM-YYYY HH:mm:ss';
export const dateFormat = 'DD-MM-YYYY';

export const RESEND_OTP_COUNTDOWN_TIME = 300; //seconds
export const MEMBER_LINK = 'https://minty-stage.adamo.tech/';
export const INITIAL_PAGE = 1;
export const DEFAULT_ANNOUNCEMENT_TYPE = 'redirect';
export const DEFAULT_ANNOUNCEMENT_STATUS = true;
export const MAXIMUM_IMAGE_SIZE = 30000000; //bytes: 30 MB
export const SINGLE_HOST_ORIGIN = 'single_host_origin';
export const DEFAULT_AVATAR =
  'https://scontent.fhan3-3.fna.fbcdn.net/v/t1.30497-1/143086968_2856368904622192_1959732218791162458_n.png?_nc_cat=1&ccb=1-7&_nc_sid=7206a8&_nc_ohc=8yScUx_QqcUAX921lcN&_nc_ht=scontent.fhan3-3.fna&oh=00_AfBm4kDR_Zv9ZyqJNvh85EsZA4dQjLrbkePqz-OCNnMDpw&oe=63E5EA78';
export const testImg = 'https://zocker.vn/pic/Product/zocker-inspire-pro-cam-1_1358_HasThumb.webp';
const PROMOTION_TYPE = {
  OBJECT: '0',
  MONEY: '1',
};
const ORDER_STATUS = [
  {
    key: 'null',
    label: 'Tất cả',
  },
  {
    key: '0',
    label: 'Chờ xác nhận',
  },
  {
    key: '1',
    label: 'Chờ phê duyệt',
  },
  {
    key: '2',
    label: 'Đang xuất kho',
  },
  {
    key: '3',
    label: 'Đang giao hàng',
  },
  {
    key: '4',
    label: 'Đã hoàn thành',
  },
  {
    key: '-1',
    label: 'Đã huỷ',
  },
  {
    key: '6',
    label: 'Còn nợ',
  },
];
const ORDER_STATUS_NUMBER = {
  ALL: null,
  WAITING_FOR_ACCEPT: 0,
  WAITING_FOR_APPROVE: 1,
  DELIVERING: 3,
  RECEIVED: 4,
  APPROVED: 2,
  CANCEL: -1,
  DEBT: 6,
};
const ORDER_STATUS_TEXT = {
  [ORDER_STATUS_NUMBER.WAITING_FOR_ACCEPT]: 'Chờ xác nhận',
  [ORDER_STATUS_NUMBER.WAITING_FOR_APPROVE]: 'Chờ phê duyệt',
  [ORDER_STATUS_NUMBER.APPROVED]: 'Đang xuất kho',
  [ORDER_STATUS_NUMBER.DELIVERING]: 'Đang giao hàng',
  [ORDER_STATUS_NUMBER.RECEIVED]: 'Đã hoàn thành',
  [ORDER_STATUS_NUMBER.CANCEL]: 'Đã huỷ',
  [ORDER_STATUS_NUMBER.DEBT]: 'Còn nợ',
};
const AUTH_HEADER_TITLE = {
  [PATH_LOGIN]: 'Đăng nhập',
  [PATH_FORGOT_PASSWORD]: 'Đặt lại mật khẩu',
  [PATH_VERIFY_OTP]: 'Nhập mã xác nhận',
  [PATH_SETUP_PASSWORD]: 'Thiết lập mật khẩu',
  [PATH_RESET_PASSWORD]: 'Thiết lập mật khẩu',
  [PATH_SIGN_UP]: 'Đăng ký tài khoản',
};
const APP_HEADER_TITLE = {
  [PATH_CART]: 'Giỏ hàng',
  [PATH_ORDER_SUCCESS]: 'Đặt hàng thành công',
  [PATH_CATEGORY]: 'Danh mục hàng',
  [PATH_ORDER]: 'Đơn hàng',
  [PATH_ORDER_PROGRESS]: 'Theo dõi đơn hàng',
  [PATH_CHANGE_PASSWORD]: 'Bảo mật',
  [PATH_EDIT_PROFILE]: 'Thông tin cá nhân',
};
const PATHS_NO_HEADER = [PATH_PROFILE];

export {
  ALL_THEMES,
  MAIN_THEME_DATA,
  CURRENT_THEME,
  HEADER_HEIGHT,
  SIDEBAR_WIDTH,
  SIDEBAR_DATA,
  SIDEBAR_COLLAPSED_WIDTH,
  SIDEBAR_ICON_SIZE,
  AUTH_THEME_COLOR,
  HEADER_PADDING_TOP,
  MAT_SM_SCREEN_WIDTH,
  MAT_SM_SCREEN_WIDTH_MIN,
  RESET,
  CASHBACK_TYPE,
  CASHBACK_RULE_TYPE,
  STATUS_TYPE,
  GENDER_TYPE,
  PAGE_SIZE_OPTIONS,
  MOBILE_HEADER_HEIGHT,
  MOBILE_FOOTER_HEIGHT,
  NAV_BAR,
  WEB_NAV_BAR,
  INTRO_COL,
  POLICY_COL,
  ORDER_STATUS,
  ORDER_STATUS_TEXT,
  ORDER_STATUS_NUMBER,
  ERROR_CODES,
  MAX_ITEM_PER_PAGE,
  MOBILE_AUTH_HEADER_HEIGHT,
  AUTH_HEADER_TITLE,
  APP_HEADER_TITLE,
  PATHS_NO_HEADER,
  PROMOTION_TYPE,
};
