import { Avatar } from 'antd';
import React, { BaseSyntheticEvent } from 'react';
import { UserOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { Camera } from '../../Icon';
import { SharedButton, SharedImage, ShareInput, ShareUploadImage } from '../../shared';
import { selectApp, selectUser, useAppSelector } from '@redux';
import { AUTH_THEME_COLOR, MAIN_THEME_DATA, PATH_EDIT_PROFILE, testImg } from '@configs';
import { useNavigate } from 'react-router-dom';
import { LogApp } from '@utils';
import moment from 'moment';
import { UploadImageModule } from '@modules';
import { SharedAutocomplete } from '../../shared/autocomplete';
import { FieldErrorsImpl, UseFormRegister, UseFormWatch } from 'react-hook-form';
import {
  AddressItem,
  AuthAutocompleteData,
  AutocompleteItem,
  SellerItem,
  UserInfo,
  WarehouseItem,
} from '@interfaces';
interface IEditProfileForm {
  register?: UseFormRegister<any>;
  handleChangePassword?: any;
  errors?: Partial<FieldErrorsImpl<any>>;
}
export const ChangePasswordForm = (props: IEditProfileForm) => {
  const { handleChangePassword, errors, register } = props;
  const navigate = useNavigate();
  return (
    <form onSubmit={handleChangePassword} className="change-password-form">
      <ShareInput
        containerClassName="user-input"
        required
        placeholder="Mật khẩu hiện tại"
        name="password"
        type="password"
        errors={errors?.['password']?.message}
        register={register}
        haveShowPassIcon
      />
      <ShareInput
        containerClassName="user-input"
        required
        placeholder="Mật khẩu mới"
        name="new_password"
        type="password"
        errors={errors?.['new_password']?.message}
        register={register}
        haveShowPassIcon
      />
      <ShareInput
        containerClassName="user-input"
        required
        placeholder="Xác nhận mật khẩu"
        name="confirm_password"
        type="password"
        errors={errors?.['confirm_password']?.message}
        register={register}
        haveShowPassIcon
      />
      <div className="actions">
        <SharedButton
          typeHtml="submit"
          text="Cập nhật thông tin"
          className="submit__btn"
          backgroundColor={MAIN_THEME_DATA.mainColor}
          btnStyle="pad"
        />
      </div>
    </form>
  );
};
