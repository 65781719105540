import { BaseSyntheticEvent, memo } from 'react';

import { SharedButton, ShareInput, TelIcon } from '@components';
import { AUTH_THEME_COLOR } from '@configs';
import { opacityHex, themes, useTheme } from '@theme';
import styled from 'styled-components';
import { IMAGES } from '@assets';
import { IntroSectionModule } from '@modules';
import { Spin } from 'antd';
import { PageError404 } from '@pages';

interface IProps {
  redirectToSignUp?: () => void;
  loading?: boolean;
  notFound?: boolean;
}

export const TransitionSection = memo((props: IProps) => {
  const { loading, notFound, redirectToSignUp } = props;

  const { theme } = useTheme();

  return !notFound ? (
    <StyledTransitionSection className="transition-page">
      {loading && (
        <div className="loading-sec">
          <div className="content">
            <div className="loading-icon" style={{ transform: 'rotate(360deg)' }} />
            <div className="loading-text">
              <p className="text">Finding store...</p>
            </div>
          </div>
        </div>
      )}
    </StyledTransitionSection>
  ) : (
    <PageError404 haveBackButton={false} />
  );
});

export const StyledTransitionSection = styled.div<{ background?: string }>`
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: auto;
  background: ${(p) => p?.theme?.colors?.bgPage};
  .loading-sec {
    width: 100%;
    height: 100%;
    background: ${(p) => p?.theme?.colors?.bgDark + opacityHex[90]};
    display: flex;
    align-items: center;
    justify-content: center;
    .content {
      width: fit-content;
      height: fit-content;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .loading-icon {
      width: 50px;
      height: 50px;
      position: relative;
      /* position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); */
      border: 5px solid #e9218c;
      border-radius: 50%;
      border-left-color: transparent;
      animation-name: spin;
      animation-duration: 1.2s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      animation-play-state: running;
      &::after {
        content: '';
        position: absolute;
        width: 65px;
        height: 65px;
        border: 5px solid #2490fd;
        border-radius: 50%;
        border-right-color: transparent;
        left: -13px;
        top: -12px;
        animation-name: spin;
        animation-duration: 1.2s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        animation-play-state: running;
      }
      &::before {
        content: '';
        position: absolute;
        width: 80px;
        height: 80px;
        border: 5px solid #fcb100;
        border-radius: 50%;
        border-left-color: transparent;
        left: -20px;
        top: -20px;
        animation-name: spin;
        animation-duration: 1.2s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        animation-play-state: running;
      }
      &::hover,
      &::after,
      &::before {
        animation-play-state: paused;
      }
    }

    .loading-text {
      margin-top: 3.6rem;
      text-align: center;
      .text {
        font-size: 1.5rem;
        font-weight: 600;
        color: ${(p) => p?.theme?.colors?.textLight};
      }
    }

    @keyframes spin {
      from {
        transform: rotate(0deg);
      }

      to {
        transform: rotate(360deg);
      }
    }
  }
`;
