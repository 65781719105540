import { MAIN_THEME_DATA } from '@configs';
import { styled } from 'styled-components';

interface DescriptionProps {
  title: string;
  children: React.ReactNode;
  className?: string;
}
export const Description = (props: DescriptionProps) => {
  const { children, title, className } = props;
  return (
    <StyledDescription className={className}>
      <div className="title-block">
        <span className="title">{title}</span>
      </div>
      <div className="des-block">{children}</div>
    </StyledDescription>
  );
};
const StyledDescription = styled.div`
  background-color: white;
  border-radius: 1.2rem;
  margin-bottom: 2.4rem;
  .attr-item {
    .attr-title {
      margin-right: 0.5rem;
    }
    span {
      display: inline-block;
    }
  }
  .title-block {
    background-color: #ffd0cd;
    border-top-right-radius: 1.2rem;
    border-top-left-radius: 1.2rem;
    padding: 1.2rem 0;
    padding-left: 2.4rem;
    .title {
      color: ${MAIN_THEME_DATA.mainText};
      font-size: 1.7rem;
      font-family: Roboto-Medium;
      font-weight: 500;
      line-height: 23.8px;
    }
  }
  .des-block {
    padding: 1.2rem 0;
    padding-left: 2.4rem;
    .list {
      list-style: circle !important;
      padding-left: 2.4rem;
      li {
        color: ${MAIN_THEME_DATA.mainText};
        font-size: 1.7rem;
        font-family: Roboto-Medium;
        font-weight: 400;
        line-height: 27.2px;
      }
    }
  }
`;
