import { Statistic } from 'antd';
import React, { BaseSyntheticEvent, CSSProperties, memo } from 'react';
import { FieldErrorsImpl, FieldValues, UseFormRegister } from 'react-hook-form';
import styled from 'styled-components';

import { IConArrowLeft, SharedButton, StyledAuth } from '@components';
import { MAIN_THEME_DATA, RESEND_OTP_COUNTDOWN_TIME } from '@configs';
import { IntroSectionModule } from '@modules';
import { selectApp, useAppSelector } from '@redux';
import { useTheme } from '@theme';
import moment from 'moment';
import OtpInput from 'react-otp-input';
import { StyledMobileAuth } from '../Login';

interface IProps {
  errors: Partial<FieldErrorsImpl<any>>;
  countdown?: boolean;
  register: UseFormRegister<FieldValues>;
  redirectToForgot?: () => void;
  handleVerifyOTP: (e?: BaseSyntheticEvent<any, any, any> | undefined) => Promise<void>;
  handleResendEmail?: () => void;
  onEndResendOTPCountdown: () => void;
  forgotUsername?: string;
  onChangeOtp: (code: string) => void;
  otp: string;
}
const inputCtnStyle: CSSProperties = {
  justifyContent: 'space-between',
  paddingLeft: '2.7rem',
  paddingRight: '2.7rem',
};
const otpInputStyle: CSSProperties = {
  // width: 'calc((100% - 8rem )/6)',
  width: '4.8rem',
  height: '5.6rem',
  textAlign: 'center',
  margin: ' 0 auto',
  border: '1px solid  #888',
  borderRadius: '0.8rem',
  fontSize: '2.4rem',
  fontFamily: 'Roboto-Medium',
};
export const AppVerifyOTPForm = memo((props: IProps) => {
  const {
    handleVerifyOTP,
    redirectToForgot,
    handleResendEmail,
    onEndResendOTPCountdown,
    forgotUsername,
    otp,
    onChangeOtp,
  } = props;

  const { theme } = useTheme();
  const { themeData } = useAppSelector(selectApp);

  const { Countdown } = Statistic;

  return (
    <StyledMobileAuth>
      <StyledVerifyOTPForm
        className="auth-section verify-email__section"
        $themeColor={themeData?.color}
      >
        <div className="otp-notification">
          <p className="dsc-text">Mã xác thực sẽ được gửi đến Zalo đến</p>
          <p className="phone">{forgotUsername}</p>
        </div>
        <div className="verify-form">
          <OtpInput
            shouldAutoFocus
            containerStyle={inputCtnStyle}
            value={otp}
            onChange={(otp) => {
              onChangeOtp(otp);
            }}
            numInputs={4}
            renderInput={(props) => <input {...props} style={otpInputStyle} />}
          />
          <div className="resend-contain">
            <div className="time-wrapper">
              <p className="text"> Chưa nhận được mã xác nhận?</p>
              <div className="time-block">
                <span className="resend-after text">Gửi lại sau</span>
                {React.createElement(Countdown, {
                  className: 'countdown-resendOTP',
                  onFinish: () => onEndResendOTPCountdown(),
                  // value: Date.now() + 1000 * 60,
                  value: moment().add(RESEND_OTP_COUNTDOWN_TIME, 's').valueOf(),
                  format: 'mm:ss',
                })}
              </div>
            </div>
            <p className="resend-text ant-statistic-content" onClick={handleResendEmail}>
              Gửi lại
            </p>
          </div>
          <div className="actions">
            <SharedButton
              typeHtml="button"
              text="Tiếp tục"
              className="submit__btn login-btn"
              backgroundColor={MAIN_THEME_DATA.mainColor}
              btnStyle="pad"
              onClick={handleVerifyOTP}
            />
          </div>
        </div>
      </StyledVerifyOTPForm>
    </StyledMobileAuth>
  );
});

const StyledVerifyOTPForm = styled.section<{
  $themeColor?: string;
}>`
  p {
    margin-bottom: 0;
  }
  display: flex;
  flex-direction: column;
  .resend-contain {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 3.6rem;
    .text {
      font-size: 1.7rem;
    }
    .resend-after {
      margin-right: 0.5rem;
    }
    .time-wrapper {
      display: flex;
      .time-block {
        margin-left: 0.5rem;
        display: flex;
        align-items: flex-end;
      }
      .resend-text {
        cursor: pointer;
      }
    }
  }
  .ant-statistic-content {
    font-family: Roboto-Medium;
    font-size: 1.7rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
  }
  .otp-notification {
    margin-bottom: 3.6rem;
    p {
      font-size: 1.7rem;
      font-style: normal;
      line-height: 140%;
      text-align: center;
    }
    .phone {
      font-weight: 500;
      font-family: Roboto-Medium;
      margin-top: 1rem;
    }
  }
  input:focus {
    outline: 2px solid ${MAIN_THEME_DATA.mainColor};
  }
  .actions {
    margin-top: 3.2rem;
  }
  .heading {
    position: relative;
    display: flex;
    justify-content: center;
    .icon {
      position: absolute;
      left: 0;
      top: 2px;
      cursor: pointer;
    }
  }
`;
