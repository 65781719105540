import { PATH_HOME } from '@configs';
import { CategoryModule, MobileCategoryModule } from '@modules';
import { useMediaQuery } from '@utils';
import { useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
export const MobileCategoryPage = (props: any) => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const navigate = useNavigate();
  useLayoutEffect(() => {
    if (!isMobile) navigate(PATH_HOME);
  }, []);
  return <MobileCategoryModule />;
};
