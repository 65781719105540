import {
  enumThemeMode,
  MAIN_THEME_DATA,
  PATH_CART,
  PATH_CATEGORY,
  PATH_HOME,
  PATH_ORDER,
  PATH_PRODUCT,
  PATH_PROFILE,
} from '@configs';
import { NavBarItem } from '@interfaces';
import { selectApp, useAppSelector } from '@redux';
import { getPath } from '@utils';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { selectHome } from 'src/redux/Slices/home';
import { NavIcon } from './components/NavIcon';
import { StyledMobileFooter } from './footer.styled';
const NAV_BAR_PATHS = [PATH_PRODUCT, PATH_HOME, PATH_CATEGORY, PATH_PROFILE, PATH_CART, PATH_ORDER];
interface IProps {
  themeMode?: enumThemeMode;
  navBarItems: NavBarItem[];
}

export const MobileFooter = ({ navBarItems, themeMode }: IProps) => {
  const { themeData, currentPath } = useAppSelector(selectApp);
  const { category } = useAppSelector(selectHome);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigateTo = (path: string) => {
    navigate(path);
  };
  if (!NAV_BAR_PATHS.includes(getPath(location.pathname))) return <></>;
  return (
    <StyledMobileFooter
      className="footer"
      $themeMode={themeMode}
      $appTheme={MAIN_THEME_DATA.mainColor}
      $themeData={themeData}
    >
      {navBarItems.map((item) => {
        return (
          <NavIcon
            isSelected={currentPath === item.path}
            onClick={() => {
              navigateTo(item.path);
            }}
            pathKey={item.pathKey}
            title={item.title}
          />
        );
      })}
    </StyledMobileFooter>
  );
};
