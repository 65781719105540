import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import 'react-horizontal-scrolling-menu/dist/styles.css';
import { HomeCardItem } from '../../HomeCardItem';
import { NewArrivalProduct, ProductsHotSelling, Promotion } from '@interfaces';
import React from 'react';
import { IConArrowLeft, IConArrowRight } from 'src/components/Icon';
import { MAIN_THEME_DATA } from '@configs';
import { styled } from 'styled-components';
import { StyledMobileList } from './list.styled';
import useDrag from './useDrag';
import { toVND, useMediaQuery } from '@utils';
import { LoadingList } from 'src/components/shared';
interface ProductListProps {
  title: string;
  data: ProductsHotSelling[];
  hasPrice?: boolean;
  isLoading?: boolean;
  footerClassNameItem?: string;
  onItemClick: (item: ProductsHotSelling) => void;
}
type scrollVisibilityApiType = React.ContextType<typeof VisibilityContext>;
export const MobileHomeList = ({
  data,
  title,
  footerClassNameItem,
  hasPrice,
  isLoading,
  onItemClick,
}: ProductListProps) => {
  const { dragStart, dragStop, dragMove, dragging } = useDrag();
  const isMobile = useMediaQuery('(max-width: 768px)');
  const handleDrag =
    ({ scrollContainer }: scrollVisibilityApiType) =>
    (ev: React.MouseEvent) =>
      dragMove(ev, (posDiff) => {
        if (scrollContainer.current) {
          scrollContainer.current.scrollLeft += posDiff;
        }
      });
  return (
    <StyledListWrapper>
      <div className="heading">
        <h2>{title}</h2>
        <span className="view-all">Xem tất cả</span>
      </div>
      {isLoading ? (
        <LoadingList wrapperWidth="calc(100% - 3.2rem);" rows={1} count={2} />
      ) : (
        <StyledMobileList onMouseLeave={dragStop}>
          <ScrollMenu
            onMouseDown={() => dragStart}
            onMouseUp={() => dragStop}
            onMouseMove={handleDrag}
          >
            {data.map((item) => (
              <HomeCardItem
                hasPrice={hasPrice}
                onItemClick={() => {
                  onItemClick(item);
                }}
                isMobile
                title={item.name}
                image={item.images?.[0]?.url}
                content={toVND(item?.selling_price)}
                // footer={item?.footer || 'Xem chi tiết'}
                footer={'Xem chi tiết'}
                footerClassName={footerClassNameItem}
                tags={[]}
              />
            ))}
          </ScrollMenu>
        </StyledMobileList>
      )}
    </StyledListWrapper>
  );
};
const StyledListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  .heading {
    width: calc(100% - 3.6rem);
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .view-all {
      color: ${MAIN_THEME_DATA.mainColor};
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
    }
  }
  h2 {
    font-family: Roboto-Medium;
    font-size: 1.7rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 33.6px */
    margin-top: 1.6rem;
    margin-bottom: 0.2rem;
  }
`;
