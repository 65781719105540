import { SharedButton } from '@components';
import { MAIN_THEME_DATA } from '@configs';
import { generateRandomId } from '@utils';
import Skeleton from 'react-loading-skeleton';
import { LoadingCart, LoadingCartItem } from 'src/components/cart/components/LoadingCart';
import { styled } from 'styled-components';
export const LoadingOrderItem = ({ isMobile }: { isMobile?: boolean }) => {
  return (
    <StyledLoadingOrderItem>
      <div className="heading">
        <Skeleton width="15.4rem" height="2rem" />
        <Skeleton width="11.2rem" height="2rem" />
      </div>
      <LoadingCartItem isMobile={isMobile} hasBottomLine type="order" />
      <div className="footer">
        <Skeleton width="20.3rem" height="3.4rem" />
        <div className="btn-block m-left-4">
          {/* <SharedButton className="mr" text="Mua lại" />
          <SharedButton
            onClick={() => {}}
            textClassName="text-btn"
            btnStyle="line"
            textColor={MAIN_THEME_DATA.mainColor}
            text="Đánh giá"
          /> */}
        </div>
      </div>
    </StyledLoadingOrderItem>
  );
};
export const LoadingOrder = ({ count, isMobile }: { count: number; isMobile?: boolean }) => {
  const LOADING_ITEMS = Array.from({ length: count }, () => ({
    id: generateRandomId(),
  }));
  return (
    <StyledLoadingOrder>
      {LOADING_ITEMS.map((item) => {
        return <LoadingOrderItem isMobile={isMobile} key={item.id} />;
      })}
    </StyledLoadingOrder>
  );
};
const StyledLoadingOrder = styled.div``;
const StyledLoadingOrderItem = styled.div`
  background-color: white;
  .heading {
    margin-bottom: 1.2rem;
    display: flex;
    justify-content: space-between;
    padding-bottom: 0.8rem;
    border-bottom: 1px solid #d9d9d9;
  }
  border-radius: 16px;
  padding: 1.2rem 1.6rem;
  box-shadow: 0px 1px 16px 0px rgba(56, 56, 56, 0.15);
  width: 100%;
  margin-bottom: 2.4rem;
  .footer {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    margin-top: 0.8rem;
    .total {
      font-size: 1.7rem;
      .money {
        font-size: 2.4rem;
        font-weight: 500;
        color: ${MAIN_THEME_DATA.mainColor};
      }
    }
    .btn-block {
      display: flex;
      width: 30%;
      margin-top: 0.8rem;
      .mr {
        margin-right: 2.4rem;
      }
    }
  }
`;
