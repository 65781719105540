import { BaseSyntheticEvent, memo } from 'react';
import { FieldErrorsImpl, FieldValues, UseFormRegister } from 'react-hook-form';

import {
  AppModal,
  IConArrowLeft,
  OrderSuccess,
  SharedButton,
  SharedCheckbox,
  ShareInput,
  StyledAuth,
} from '@components';
import { AUTH_THEME_COLOR, MAIN_THEME_DATA } from '@configs';
import { themes, useTheme } from '@theme';
import { StyledSignUpForm } from './signUpStyle';
import styled from 'styled-components';
import { IntroSectionModule } from '@modules';
import { Statistic } from 'antd';
import moment from 'moment';
import React from 'react';
import { useMediaQuery } from '@utils';
import { AppResetPasswordForm } from './app/AppResetPassword';
import { WebResetPasswordForm } from './web/WebResetPassword';

interface IProps {
  errors: Partial<FieldErrorsImpl<any>>;
  register: UseFormRegister<any>;
  redirectToLogin?: () => void;
  goBack?: () => void;
  handleResetPassword: (e?: BaseSyntheticEvent<any, any, any> | undefined) => Promise<void>;
  opened?: boolean;
}

export const ResetPasswordForm = memo((props: IProps) => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  if (isMobile) return <AppResetPasswordForm {...props} />;
  return <WebResetPasswordForm {...props} />;
});
const StyledModalInner = styled.div`
  width: 80rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .time-block {
    margin-top: 1rem;
    .text {
      font-size: 1.7rem;
      color: #888;
    }
    .ant-statistic-content-value {
      color: #888;
    }
    .ant-statistic-content {
      font-size: unset;
    }
    display: flex;
    align-items: flex-end;
    .resend-after {
      margin-right: 0.5rem;
    }
  }
  .order-success {
    margin-top: 2.4rem;
    font-family: Roboto-Medium;
    color: #000;
    font-size: 2.4rem;
    font-weight: 500;
    line-height: 140%;
  }
  span {
    font-size: 1.7rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }
  .red-text {
    color: ${MAIN_THEME_DATA.mainColor};
  }
  .btn-block {
    display: flex;
    width: 30%;
    margin-top: 2.4rem;
    .mr {
      margin-right: 2.4rem;
    }
  }
`;
export const StyledResetPasswordForm = styled.section`
  width: 57%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 0.5rem;
  padding: 2.4rem 3.2rem;
  .shared-input {
    margin-bottom: 2rem;
    .inner-input {
      height: 4.6rem;
    }
  }
  .actions {
    margin-top: 3.2rem;
  }
  .heading {
    position: relative;
    display: flex;
    justify-content: center;
    .icon {
      position: absolute;
      left: 0;
      top: 2px;
      cursor: pointer;
    }
  }
`;
