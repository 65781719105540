import { homeAPI } from '@api';
import { IGetListParams, TransactionHistoryRoot } from '@interfaces';
import { createAsyncThunk } from '@reduxjs/toolkit';
const prefix = 'home';

export const getTransactionHistory = createAsyncThunk<any, any>(
  `${prefix}/transaction-history`,
  async (params: IGetListParams, { rejectWithValue }) => {
    try {
      const res = await homeAPI.getTransactionHistory(params);
      return res as TransactionHistoryRoot;
    } catch (e) {
      rejectWithValue(e);
    }
  },
);
