import { Layout } from 'antd';
import styled, { css } from 'styled-components';

import {
  enumThemeMode,
  HEADER_HEIGHT,
  MOBILE_HEADER_HEIGHT,
  MAIN_THEME_DATA,
  MOBILE_AUTH_HEADER_HEIGHT,
} from '@configs';
import { IThemesData } from '@interfaces';

const { Header } = Layout;

export const StyledMobileHeader = styled((props: any) => <Header {...props} />)<{
  $appTheme?: string;
  $themeMode?: enumThemeMode;
  $themeData?: IThemesData;
  $isAuth?: boolean;
  haveSearchInput?: boolean;
}>`
  .inner-input {
    @media (max-width: 768px) {
      font-size: 1.4rem;
    }
  }
  box-shadow: 0 0 11px rgb(0 0 0 / 13%);
  transition: 0.2s;
  top: 0;
  left: 0;
  height: ${(p: any) => (p?.haveSearchInput ? MOBILE_HEADER_HEIGHT : MOBILE_AUTH_HEADER_HEIGHT)};
  background-color: ${MAIN_THEME_DATA.mainColor};
  display: flex;
  flex-direction: column;
  ${(p: any) =>
    !p?.$isAuth &&
    css`
      max-width: 50rem;
      display: flex;
      margin: 0 auto;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    `};
  position: fixed;
  width: 100%;
  z-index: 100;
  align-items: center;
  padding: ${(p: any) => (p?.haveSearchInput ? '0 1.6rem 0 0' : '0')};
  .back-header {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: 1.6rem;
    .icon {
      position: absolute;
      bottom: 1.6rem;
      left: 1.6rem;
      cursor: pointer;
    }
    h2 {
      font-size: 1.7rem;
      color: white;
      font-weight: 500;
      font-family: Roboto-Medium;
    }
  }
  .header-container {
    width: 100%;
    padding: 1.4rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
  }

  .header-logo {
    position: relative;
    width: auto;
    max-width: 10rem;
    height: 5rem;
  }

  .header-title {
    .title {
      font-size: 1.6rem;
      font-weight: 600;
      color: ${(p: any) => p?.theme.colors?.header?.text};
    }
  }
  .header-actions {
    display: flex;
    align-items: center;
    .img-ctn {
      width: 10rem;
      aspect-ratio: 25/7;
    }
    .warehouse-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      background: hsla(0, 0%, 100%, 0.2);
      padding: 0.3rem 0.5rem;
      border-radius: 1rem;
      margin-right: 2rem;
      .warehouse-info {
        margin-left: 0.5rem;
        span {
          font-style: normal;
          font-weight: 400;
          line-height: 140%;
          color: white;
        }
        .view-at {
          display: flex;
          justify-content: center;
          align-items: center;
          .view-at-txt {
            font-size: 1rem;
            margin-right: 0.5rem;
          }
        }
        .warehouse-txt {
          font-size: 1.2rem;
          font-family: Roboto-Medium;
        }
      }
    }
  }
  .header-divider {
    position: relative;
    height: calc(${HEADER_HEIGHT} - 1rem * 2);
    margin: 0;
    border-left: 1px solid rgb(255 255 255 / 0.08);
  }
  .header-right {
    position: relative;
    .header-content {
      width: 100%;
      padding: 0 2.4rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .app-breadcrumb-item:not(:last-child) .app-breadcrumb-link a {
        color: ${(p) => p?.theme.colors?.header?.text};
      }
      .app-breadcrumb-separator {
        color: ${(p) => p?.theme.colors?.header?.subText};
      }
      .app-breadcrumb-item:last-child .app-breadcrumb-link a {
        color: ${(p) => p?.theme.colors?.header?.subText};
      }
    }
  }
  .search-container {
    width: 100%;
    margin-left: 1.6rem;
    .inner-input {
      height: 3.2rem;
      border-radius: 2.4rem;
    }
    .r-input {
      padding: 0.6rem 1.6rem;
    }
  }
`;
export const StyledWebHeader = styled((props: any) => <Header {...props} />)<{
  $appTheme?: string;
  $themeMode?: enumThemeMode;
  $themeData?: IThemesData;
  $isAuth?: boolean;
}>`
  .search-input {
    width: 80%;
  }
  .auth-right-block {
    display: flex;
    align-items: center;
    justify-content: center;
    .cart {
      margin-right: 1rem;
    }
    .name {
      font-family: Roboto-Medium;
      font-size: 1.7rem;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
      color: white;
    }
    .user-avatar {
      width: 4.4rem;
      margin-left: 1rem;
      aspect-ratio: 1;
      border-radius: 100px;
      img {
        border-radius: 100px;
      }
    }
  }
  .ant-dropdown {
    top: 12.4rem !important;
    left: 50% !important;
    transform: translate(-50%, 0) !important;
  }
  box-shadow: 0 0 11px rgb(0 0 0 / 13%);
  transition: 0.2s;
  top: 0;
  left: 0;
  height: ${HEADER_HEIGHT};
  background-color: ${MAIN_THEME_DATA.mainColor};
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 100%;
  z-index: 100;
  align-items: flex-start;
  padding: 0;
  .bread-crumb-block {
    padding: 1rem 0;
    background-color: #ffd0cd;
  }
  .header-container {
    width: 100%;
    padding-left: 4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    position: relative;
    height: 12.4rem;
  }

  .header-logo {
    position: relative;
    width: auto;
    max-width: 10rem;
    height: 5rem;
  }

  .mid-header {
    flex: 1;
    display: flex;
    width: calc((100% - 8rem) * 0.6);
    cursor: pointer;
    .warehouse-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      background: hsla(0, 0%, 100%, 0.2);
      padding: 0.3rem 0.5rem;
      border-radius: 1rem;
      margin-right: 2rem;
      .warehouse-info {
        margin-left: 0.5rem;
        span {
          font-style: normal;
          font-weight: 400;
          line-height: 140%;
          color: white;
        }
        .view-at {
          display: flex;
          justify-content: center;
          align-items: center;
          .view-at-txt {
            font-size: 1.4rem;
            margin-right: 0.5rem;
          }
        }
        .warehouse-txt {
          font-size: 1.7rem;
          font-family: Roboto-Medium;
        }
      }
    }
    .inner-input {
      height: 4.4rem;
      border-radius: 2.4rem;
    }
    .suf-icon {
      bottom: 0.8rem;
    }
  }
  .header-actions {
    width: calc((100% - 8rem) * 0.15);
    display: flex;
  }
  .header-divider {
    position: relative;
    height: calc(${HEADER_HEIGHT} - 1rem * 2);
    margin: 0;
    border-left: 1px solid rgb(255 255 255 / 0.08);
  }
  .header-right {
    width: calc((100% - 8rem) * 0.2);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .text-wrapper {
      margin-left: 1.2rem;
      .right-text {
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        color: white;
      }
    }
  }
  .header-bottom {
    background-color: #c00b00;
    width: 100%;
    height: 4.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    .nav-block {
      width: 37%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .nav-item {
        display: flex;
        align-items: center;
        flex-direction: column;
        height: 100%;
        cursor: pointer;
      }
      .border-bottom {
        height: 2px;
        width: 100%;
        background-color: white;
        border-radius: 2px;
      }
      span {
        color: white;
        font-size: 1.7rem;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 23.8px */
      }
    }
  }
`;
