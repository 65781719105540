import { generateRandomId, useMediaQuery } from '@utils';
import Skeleton from 'react-loading-skeleton';
import { styled } from 'styled-components';

export const LoadingList = ({
  count,
  rows,
  wrapperWidth,
}: {
  count: number;
  rows: number;
  wrapperWidth?: string;
}) => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const IMAGE_HEIGHT = isMobile ? '9rem' : '18rem';
  const LOADING_ITEMS = Array.from({ length: count }, () => ({
    id: generateRandomId(),
  }));
  const getMarginRight = (index: number) => {
    if (rows === 1) return 'mr-15';
    if (rows > 1 && index === count / 2 - 1) return '';
    return 'mr-15';
  };
  return (
    <StyledSkeletonWrapper wrapperWidth={wrapperWidth} rows={rows}>
      {LOADING_ITEMS.map((item, index) => {
        return (
          <StyledSkeletonItem
            rows={rows}
            className={`${getMarginRight(index)} ${rows > 1 ? 'mt-24' : ''}`}
            key={item.id}
            count={count}
          >
            <Skeleton containerClassName="image-skeleton" height={IMAGE_HEIGHT} />
            <div className="line-skeleton-wrapper">
              <Skeleton containerClassName="line-skeleton" count={3} />
            </div>
          </StyledSkeletonItem>
        );
      })}
    </StyledSkeletonWrapper>
  );
};
const StyledSkeletonWrapper = styled.div<{ rows: number; wrapperWidth?: string }>`
  .mr-15 {
    margin-right: 1.5rem;
  }
  .mt-24 {
    margin-top: 2.4rem;
  }
  display: flex;
  flex-wrap: ${(p) => (p?.rows > 1 ? 'wrap' : 'nowrap')};
  width: ${(p) => p?.wrapperWidth && p?.wrapperWidth};
  @media (max-width: 768px) {
    margin-top: 1.6rem;
    padding-bottom: 8.8rem;
  }
`;
export const StyledSkeletonItem = styled.div<{ count: number; rows: number }>`
  width: ${(p) =>
    `calc((100% - 1.5rem * ${p.rows > 1 ? p.count / 2 - 1 : p.count - 1})/${
      p.rows > 1 ? p.count / 2 : p.count
    })`};
  min-height: 30.5rem;
  @media (max-width: 768px) {
    min-height: 16rem;
  }
  border-radius: 16px;
  background-color: #fff;
  box-shadow: 0px 1px 16px 0px rgba(56, 56, 56, 0.15);
  &:last-child {
    margin-right: 0;
  }

  .image-skeleton {
    border-radius: 16px;
    .react-loading-skeleton {
      line-height: unset;
      margin-bottom: 1.2rem;
    }
  }
  .line-skeleton-wrapper {
    padding: 0 1.2rem;
    .line-skeleton {
      .react-loading-skeleton {
        margin-bottom: 0.8rem;
      }
    }
  }
`;
