import { Image, Layout } from 'antd';
import styled, { css } from 'styled-components';

import { enumThemeMode, HEADER_HEIGHT, MOBILE_HEADER_HEIGHT, MAIN_THEME_DATA } from '@configs';
import { IThemesData } from '@interfaces';
import { IMAGES } from '@assets';

const { Header } = Layout;
export const WebAuthHeader = () => {
  return (
    <StyledWebAuthHeader>
      <Image preview={false} src={IMAGES.color_logo} />
    </StyledWebAuthHeader>
  );
};

const StyledWebAuthHeader = styled((props: any) => <Header {...props} />)`
  top: 0;
  left: 0;
  height: 14.4rem;
  background-color: ${MAIN_THEME_DATA.bgColor};
  display: flex;
  align-items: center;
  position: fixed;
  width: 100%;
  z-index: 100;
  padding-left: 12rem;
  .ant-layout-header {
  }
`;
