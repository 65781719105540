import { authAPI } from '@api';
import {
  AddressItem,
  AuthAutocompleteData,
  AutocompleteItem,
  DistrictRoot,
  ProvinceRoot,
  RootResponse,
  SellerItem,
  WardRoot,
  WarehouseItem,
} from '@interfaces';
import { setLoading, useAppDispatch } from '@redux';
import { useImmer } from 'use-immer';
import { LogApp } from '../utilities';
import { useEffect } from 'react';

export const useAddressSelector = () => {
  const dispatch = useAppDispatch();
  const [autocompleteData, setAutoCompleteData] = useImmer<AuthAutocompleteData>({
    provinces: [],
    districts: [],
    wards: [],
    warehouses: [],
    sellers: [],
  });
  const onSelectProvince = async (value: AddressItem) => {
    try {
      const res: DistrictRoot = await authAPI.getDistricts(value.code);
      const optionValues: AutocompleteItem<AddressItem>[] = res.data.districts.map((item) => {
        return {
          label: item.name,
          value: item.name,
          item,
        };
      });
      setAutoCompleteData((draft) => {
        draft.districts = optionValues;
      });
    } catch (error) {}
  };
  const onSelectDistrict = async (value: AddressItem) => {
    try {
      const res: WardRoot = await authAPI.getWards(value.code);
      const optionValues: AutocompleteItem<AddressItem>[] = res.data.wards.map((item) => {
        return {
          label: item.name,
          value: item.name,
          item,
        };
      });
      setAutoCompleteData((draft) => {
        draft.wards = optionValues;
      });
    } catch (error) {}
  };

  const onSelectWarehouse = async (value: WarehouseItem) => {
    try {
      const res: RootResponse<SellerItem[]> = await authAPI.getSellers(value.id);
      const optionValues: AutocompleteItem<SellerItem>[] = res.data.map((item) => {
        return {
          label: item.name,
          value: item.name,
          item,
        };
      });
      setAutoCompleteData((draft) => {
        draft.sellers = optionValues;
      });
    } catch (error) {}
  };

  const getAutocompleteData = async () => {
    try {
      dispatch(setLoading(true));
      const res: ProvinceRoot = await authAPI.getProvinces();
      const warehouse: RootResponse<WarehouseItem[]> = await authAPI.getWarehouses();
      const optionValues: AutocompleteItem<AddressItem>[] = res.data.provinces.map((item) => {
        return {
          label: item.name,
          value: item.name,
          item,
        };
      });
      const warehouseValues: AutocompleteItem<WarehouseItem>[] = warehouse.data.map((item) => {
        return {
          label: item.name,
          value: item.name,
          item,
        };
      });
      setAutoCompleteData((draft) => {
        draft.provinces = optionValues;
        draft.warehouses = warehouseValues;
      });
    } catch (error) {
      LogApp(error, 'err');
    } finally {
      dispatch(setLoading(false));
    }
  };
  useEffect(() => {
    getAutocompleteData();
  }, []);
  return {
    autocompleteData,
    onSelectDistrict,
    onSelectProvince,
    onSelectWarehouse,
  };
};
