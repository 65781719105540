import { CartItem as CartItemInterface, Classification, SubClassification } from '@interfaces';
import { useMediaQuery } from '@utils';
import { AppCartSection } from './mobile/AppCart';
import { WebCartSection } from './web/Cart';
import { DebouncedFunc } from 'lodash';
interface CartSectionProps {
  cart: CartItemInterface[];
  getAttribute: (item: CartItemInterface) => string;
  getPrice: () => { number: string; text: string; totalPrice: string };
  increaseProductQuantity: (item: CartItemInterface, quantity: number) => void;
  decreaseProductQuantity: (item: CartItemInterface, quantity: number) => void;
  onUpdateParentClassification: (
    cartItem: CartItemInterface,
    classification: Classification,
  ) => void;
  onUpdateChildClassification: (
    cartItem: CartItemInterface,
    classification: SubClassification,
  ) => void;
  onDeleteCartItem: (item: CartItemInterface) => void;
  onBack: () => void;
  navigateToOrder: () => void;
  isLoading: boolean;
  onChangeQuantity: (quantity: number, item: CartItemInterface) => void;
  getDeductedMoney: () => { number: string; text: string };
  getDiscountMoney: () => { number: string; text: string };
  noteError: string;
  onNoteChange: (value: string) => void;
  getDiscountPercent: (cartItem: CartItemInterface) => number;
  getUserAddress: () => string;
  handleProductQuantity: DebouncedFunc<(item: CartItemInterface, quantity: number) => void>;
}
export const CartSection = (props: CartSectionProps) => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  if (isMobile) return <AppCartSection {...props} />;
  return <WebCartSection {...props} />;
};
