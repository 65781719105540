import { CartItem, Classification, SubClassification, UserInfo } from '@interfaces';

export const DEFAULT_CLASSIFICATION_PARAMS: Classification = {
  id: '',
  attribute_name: '',
  attribute_id: '',
  value: '',
  product_item: {
    id: '',
    import_price: 0,
    selling_price: 0,
    code: '',
    quantity: 0,
    solded_quantity: 0,
    reward: false,
    deduct_percent: 0,
  },
  sub_classifications: [],
  detail: true,
};
export const DEFAULT_SUB_CLASSIFICATION_PARAMS: SubClassification = {
  id: '',
  attribute_name: '',
  attribute_id: '',
  value: '',
  product_item: {
    id: '',
    import_price: 0,
    selling_price: 0,
    code: '',
    quantity: 0,
    solded_quantity: 0,
    reward: false,
    deduct_percent: 0,
  },
  sub_classifications: [],
  detail: true,
};
export const DEFAULT_CART_ITEM_PARAMS: CartItem = {
  product_id: '',
  product_item_id: '',
  name: '',
  amount: 0,
  price: 0,
  classification_name: '',
  classification_id: '',
  classification_parent_name: '',
  classification_parent_id: '',
  classifications: [],
  image: {
    id: '',
    url: '',
    content_type: '',
  },
  selectedClassifications: {
    parent: DEFAULT_CLASSIFICATION_PARAMS,
    child: DEFAULT_SUB_CLASSIFICATION_PARAMS,
  },
  deduct_percent: 0.0,
  reward: false,
  promotions: [],
  price_deducted: 0,
};
export const DEFAULT_ADDRESS = {
  id: '',
  wards: {
    ward_name: '',
    ward_name_en: '',
    ward_code_name: '',
    code: '',
  },
  districts: {
    district_name: '',
    district_name_en: '',
    district_code_name: '',
    code: '',
  },
  provinces: {
    province_name: '',
    province_name_en: '',
    province_code_name: '',
    code: '',
  },
  detail: '',
};
export const DEFAULT_ORDER_DETAIL = {
  id: '',
  code: '',
  status: 0,
  from_address: DEFAULT_ADDRESS,
  to_address: DEFAULT_ADDRESS,
  seller: {
    id: '',
    brand_name: '',
    phone_number: '',
  },
  customer: {
    id: '',
    customer_name: '',
    customer_phone_number: '',
  },
  promotions: [],
  order_items: [],
  total_money: 0,
  money_debt: 0,
  money_deducted: 0,
  created_at: 0,
  last_updated_at: 0,
  order_histories: [],
  debt: false,
  reviewed: false,
  note: '',
  money_addon: 0,
};
export const DEFAULT_HOME_DATA = {
  from_time: 0,
  to_time: 0,
  amount_order_pending: 0,
  amount_order_shipping: 0,
  amount_order_packing: 0,
  money_static_response: {
    total_revenue: 0,
    total_debt: 0,
    from_time: 0,
    to_time: 0,
    payment_deadline: 0,
  },
  products_hot_selling: [],
  products_latest: [],
  reward_statistic_response: {
    customer_id: '',
    total_money: 0,
    week_of_year: 0,
    year: 0,
    month: 0,
  },
};
export const DEFAULT_WAREHOUSE_ITEM = {
  id: '',
  name: '',
};
export const DEFAULT_USER_INFO: UserInfo = {
  id: '',
  bonus_coin: 0,
  status: false,
  seller: {
    id: '',
    brand_name: '',
    phone_number: '',
  },
  username: '',
  full_name: '',
  phone_number: '',
  email: '',
  dob: '',
  address: DEFAULT_ADDRESS,
  image: undefined,
  warehouse: DEFAULT_WAREHOUSE_ITEM,
};
export const DEFAULT_CATEGORY_PARAMS = {
  id: '',
  name: '',
  image_url: '',
  sub_categories: [],
};
export const DEFAULT_PRODUCT_PARAMS = {
  id: '',
  code: '',
  name: '',
  unit: '',
  type: '',
  origin: '',
  import_price: '',
  deduct_percent: 0,
  selling_price: '',
  quantity: '',
  solded_quantity: '',
  rating: '',
  description: '',
  url_image: '',
  images: [],
  warehouse: {
    id: '',
    name: '',
    code: '',
    phone_number: '',
    address_response: '',
  },
  categories: [],
  attributes: [],
  classifications: [],
  view: 0,
  promotions: [],
};
export const DEFAULT_REGISTER_PAYLOAD = {
  seller_id: '',
  username: '',
  password: '',
  full_name: '',
  phone_number: '',
  email: '',
  dob: '',
  image_id: '',
  address: {
    province_code: '',
    district_code: '',
    ward_code: '',
    detail: '',
  },
};
export const DEFAULT_ADDRESS_ITEM = {
  code: '',
  name: '',
  name_en: '',
  full_name: '',
  full_name_en: '',
  code_name: '',
};
export const DEFAULT_SELLER_ITEM = {
  id: '',
  name: '',
  phone_number: '',
  brand_name: '',
};
