import {
  LogoutIcon,
  ProfileIcon,
  SettingIcon,
  SharedButton,
  SharedImage,
  UserIcon,
} from '@components';
import { MAIN_THEME_DATA, PATH_CHANGE_PASSWORD, PATH_EDIT_PROFILE, RESET, testImg } from '@configs';
import { ProfileModule } from '@modules';
import { logout, selectUser, setAccessToken, useAppDispatch, useAppSelector } from '@redux';
import { useMediaQuery } from '@utils';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
const WEB_OPTIONS = [
  {
    id: 1,
    title: 'Tài khoản',
  },
  {
    id: 2,
    title: 'Bảo mật',
  },
  {
    id: 3,
    title: 'Đăng xuất',
  },
];
const MOBILE_OPTIONS = [
  {
    id: 1,
    title: 'Tài khoản',
  },
  {
    id: 2,
    title: 'Bảo mật',
  },
];
export const OPTION_VALUE = {
  ACCOUNT: 1,
  PRIVACY: 2,
  LOGOUT: 3,
};
const Content = ({ children, isMobile }: { children: React.ReactNode; isMobile: boolean }) => {
  if (isMobile) return <div className="content">{children}</div>;
  return <>{children}</>;
};
export const ProfilePage = () => {
  const userState = useAppSelector(selectUser);
  const userInfo = userState?.userInfo;
  const avatar = userState?.avatar;
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width: 768px)');
  const [selectedId, setSelectedId] = useState(OPTION_VALUE.ACCOUNT);
  const dispatch = useAppDispatch();
  const OPTIONS = isMobile ? MOBILE_OPTIONS : WEB_OPTIONS;
  const getIcon = (id: number) => {
    const isSelected = !isMobile && selectedId === id;
    switch (id) {
      case OPTION_VALUE.ACCOUNT:
        return <ProfileIcon color={isSelected ? 'white' : undefined} />;
      case OPTION_VALUE.PRIVACY:
        return (
          <SettingIcon
            secondColor={isSelected ? MAIN_THEME_DATA.bgColor : undefined}
            color={isSelected ? 'white' : undefined}
          />
        );
      default:
        return <LogoutIcon />;
    }
  };
  const onLogout = () => {
    dispatch(logout());
    dispatch({ type: RESET });
  };
  const onSelectItem = (id: number) => {
    if (id === OPTION_VALUE.ACCOUNT || id === OPTION_VALUE.PRIVACY) {
      if (!isMobile) setSelectedId(id);
      else {
        if (id === OPTION_VALUE.ACCOUNT) navigate(PATH_EDIT_PROFILE);
        else navigate(PATH_CHANGE_PASSWORD);
      }
    } else {
      onLogout();
    }
  };
  return (
    <StyledProfilePage>
      {isMobile && (
        <div className="user-info">
          <div className="base-info">
            <span>Xin chào</span>
            <span className="name">{userInfo.full_name}</span>
          </div>
          <SharedImage containerClassName="user-avatar" src={avatar ?? testImg} />
        </div>
      )}
      <Content isMobile={isMobile}>
        <div className="side-bar">
          {OPTIONS.map((item, index) => {
            const isSelected = !isMobile && selectedId === item.id;
            const isLastItem = index === OPTIONS.length - 1;
            return (
              <div
                onClick={() => {
                  onSelectItem(item.id);
                }}
                className={`nav-item ${!isLastItem && isMobile ? 'border-bt' : ''} ${
                  isSelected ? 'active-nav-item' : ''
                }`}
                key={item.id}
              >
                {getIcon(item.id)}
                <span className={`text ${isSelected ? 'active-text' : ''}`}>{item.title}</span>
              </div>
            );
          })}
        </div>
        {isMobile ? (
          <SharedButton
            text="Đăng xuất"
            backgroundColor={MAIN_THEME_DATA.mainColor}
            btnStyle="pad"
            onClick={onLogout}
          />
        ) : (
          <ProfileModule selectedId={selectedId} />
        )}
      </Content>
    </StyledProfilePage>
  );
};
export const StyledProfilePage = styled.div`
  padding: 0 12rem;
  .content {
    width: 100%;
    @media (max-width: 768px) {
      padding: 0 1.6rem;
    }
  }
  .user-info {
    background-color: ${MAIN_THEME_DATA.mainColor};
    display: flex;
    @media (max-width: 768px) {
      flex-direction: row-reverse;
      justify-content: start;
    }
    justify-content: space-between;
    padding: 1.6rem;
    margin-bottom: 1.6rem;
    .base-info {
      display: flex;
      flex-direction: column;
      margin-left: 1.6rem;
      span {
        display: inline-block;
        margin-bottom: 0.4rem;
        color: white;
        font-size: 1.4rem;
      }
      .name {
        font-size: 1.7rem;
        font-family: Roboto-Medium;
        font-weight: 500;
      }
    }
    .user-avatar {
      width: 7.8rem;
      aspect-ratio: 1;
      border-radius: 100px;
      img {
        border-radius: 100px;
      }
    }
  }
  .border-bt {
    border-bottom: 0.5px solid #d9d9d9;
  }
  display: flex;
  @media (max-width: 768px) {
    padding: 0 0;
    flex-direction: column;
  }
  .side-bar {
    @media (max-width: 768px) {
      width: 100%;
      height: fit-content;
      margin-right: 0;
      padding: 1rem 1.6rem;
      box-shadow: 0px 1px 16px 0px rgba(56, 56, 56, 0.15);
      margin-bottom: 1.6rem;
    }
    height: 100vh;
    width: 20%;
    background-color: white;
    overflow-x: hidden;
    border-radius: 1.2rem;
    margin-right: 2.4rem;
    padding: 1.6rem 0.8rem 0 0.8rem;
    .active-nav-item {
      background-color: ${MAIN_THEME_DATA.mainColor};
    }
    .nav-item {
      display: flex;
      border-radius: 1.2rem;
      padding: 0.8rem;
      @media (max-width: 768px) {
        border-radius: 0;
      }
      cursor: pointer;
      .text {
        font-size: 1.7rem;
        margin-left: 0.8rem;
      }
      .active-text {
        color: white;
      }
    }
  }
`;
