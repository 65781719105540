import { userAPI } from '@api';
import { ProfileForm } from '@components';
import { yupResolver } from '@hookform/resolvers/yup';
import { AddressItem, UpdatePasswordPayload, WarehouseItem } from '@interfaces';
import {
  selectAuth,
  selectSetting,
  selectUser,
  setLoading,
  useAppDispatch,
  useAppSelector,
} from '@redux';
import { LogApp, useAddressSelector } from '@utils';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
//@ts-ignore
import { NotificationContainer, NotificationManager } from 'react-notifications';

export const schema = yup.object().shape({
  password: yup.string().required('Vui lòng nhập mật khẩu'),
  new_password: yup
    .string()
    .required('Vui lòng nhập mật khẩu')
    .matches(
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$%^&+=!]).{8,}$/,
      'Mật khẩu phải chứa ít nhất 8 ký tự, bao gồm ít nhất một chữ hoa, một chữ thường, một số và một ký tự đặc biệt.',
    ),
  confirm_password: yup
    .string()
    .required('Vui lòng xác nhận mật khẩu')
    .oneOf([yup.ref('new_password'), null], 'Mật khẩu không khớp'),
});
export const ProfileModule = ({ selectedId }: { selectedId: number }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { privateId } = useAppSelector(selectAuth);
  const userState = useAppSelector(selectUser);
  const userInfo = userState?.userInfo;
  const avatar = userState?.avatar;
  const { autocompleteData, onSelectDistrict, onSelectProvince, onSelectWarehouse } =
    useAddressSelector();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    setError,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      password: '',
      new_password: '',
      confirm_password: '',
    },
  });
  const handleChangePassword = handleSubmit(async (value) => {
    try {
      dispatch(setLoading(true));
      const { password, new_password, confirm_password } = value;
      const payload: UpdatePasswordPayload = {
        password,
        new_password,
        confirm_password,
      };
      const res = await userAPI.updatePassword(privateId.userId, payload);
      NotificationManager.success('Thông báo', 'Thành công');
    } catch (error: any) {
      setError('confirm_password', { type: 'custom', message: error.data.detail });
    } finally {
      dispatch(setLoading(false));
    }
  });
  return (
    <ProfileForm
      selectedId={selectedId}
      watch={watch}
      register={register}
      errors={errors}
      userInfo={userInfo}
      handleChangePassword={handleChangePassword}
      avatar={avatar}
    />
  );
};
