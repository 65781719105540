import { OrderSuccess, SharedButton } from '@components';
import { MAIN_THEME_DATA, PATH_HOME, PATH_ORDER } from '@configs';
import { useMediaQuery } from '@utils';
import { useNavigate } from 'react-router';
import { styled } from 'styled-components';

export const OrderSuccessSection = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width: 768px)');
  return (
    <StyledOrderSuccessSection>
      <OrderSuccess width={isMobile ? 64 : 120} height={isMobile ? 64 : 120} />
      <h2 className="order-success">Đặt hàng thành công</h2>
      <span className={'text-center'}>
        Đơn hàng của anh/chị đang được xử lí. Cảm ơn anh/chị đã đặt hàng tại{' '}
        <span className="red-text">Đông Dương Sport</span>!
      </span>
      <div className="btn-block">
        <SharedButton
          onClick={() => {
            navigate(PATH_HOME);
          }}
          className="mr"
          text="Tiếp tục mua sắm"
        />
        <SharedButton
          onClick={() => {
            navigate(PATH_ORDER);
          }}
          textClassName="text-btn"
          btnStyle="line"
          textColor={MAIN_THEME_DATA.mainColor}
          text="Kiểm tra đơn hàng"
        />
      </div>
    </StyledOrderSuccessSection>
  );
};
const StyledOrderSuccessSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 1.6rem;
  .order-success {
    margin-top: 2.4rem;
    font-family: Roboto-Medium;
    color: #000;
    font-size: 2.4rem;
    font-weight: 500;
    line-height: 140%;
    @media (max-width: 768px) {
      font-size: 1.7rem;
    }
  }
  span {
    font-size: 1.7rem;
    @media (max-width: 768px) {
      font-size: 1.4rem;
    }
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }
  .red-text {
    color: ${MAIN_THEME_DATA.mainColor};
  }
  .btn-block {
    display: flex;
    width: 40%;
    @media (max-width: 768px) {
      width: 100%;
    }
    margin-top: 2.4rem;
    .mr {
      margin-right: 2.4rem;
    }
  }
`;
