import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { dateFormat, GENDER_TYPE, PATH_HOME, PATH_LOGIN } from '@configs';
import { getValueFromDateTimeString, LogApp } from '@utils';
import { SignUpForm } from '@components';
import { useState } from 'react';
import { debounce } from 'lodash';
import {
  selectApp,
  selectAuth,
  setAccessToken,
  setAccountInfo,
  setLoading,
  setTokenExpires,
  useAppDispatch,
  useAppSelector,
} from '@redux';
import { authAPI } from '@api';

const schema = yup.object().shape({
  gender: yup.string().required('Gender is required'),
  dateOfBirth: yup.string().required('Date of birth is required'),
  address: yup.string().required('Address is required'),
  postalCode: yup.string().required(''),
  phone: yup.string().required('Phone is required'),
});

export const SocialSignUpModule = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { themeMode } = useAppSelector(selectApp);
  const { googleRegisterHash } = useAppSelector(selectAuth);
  const [agreePolicy, setAgreePolicy] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    setError,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      gender: GENDER_TYPE[0].value,
      dateOfBirth: '',
      address: '',
      postalCode: '',
      phone: '',
    },
  });

  const handleRedirectToLogin = () => {
    navigate(PATH_LOGIN);
  };

  const handleChangeAgreePolicy = debounce(() => {
    setAgreePolicy(!agreePolicy);
  }, 500);

  const handleSignUp = handleSubmit((value) => {
    LogApp('Submit Sign up', value);
  });

  return <></>;
};
