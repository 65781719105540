import { MAIN_THEME_DATA } from '@configs';
import { ProductFilter, ProductFilterData } from '@interfaces';
import { List } from 'antd';
import { styled } from 'styled-components';
import { HomeCardItem } from '../../home/components/HomeCardItem';
import { LoadingList, Pagination, ShareSelectInput } from '../../shared';
import { LogApp } from '@utils';

interface ProductSearchResultProps {
  products: ProductFilterData;
  defaultActiveKey?: string;
  navigateToProductDetail: (id: string) => void;
  isLoading: boolean;
  keyword: string;
  onPageChange: (page: number) => void;
  payload: any;
}
export const MobileProductSearchResultSection = (props: ProductSearchResultProps) => {
  const { navigateToProductDetail, products, isLoading, keyword, onPageChange, payload } = props;
  return (
    <StyledProductSearchResult>
      <div className="head-block">
        <span className="text-17 text-center">
          Tìm thấy <span className="text-17 text-med">{products.limit}</span> sản phẩm cho từ khoá{' '}
          <span className="text-17 text-med">'{keyword}'</span>
        </span>
        {/* <div className="sort-block">
          <span className="select-title select-sort-title">Sắp xếp: </span>
          <ShareSelectInput placeholder="Xếp theo" className="select" data={[]} />
        </div> */}
      </div>
      <div className="main-block">
        {isLoading ? (
          <LoadingList rows={2} count={4} />
        ) : (
          <List
            style={{ marginTop: '2.4rem' }}
            grid={{
              gutter: 16,
              xs: 2,
              sm: 2,
            }}
            dataSource={products.data}
            renderItem={(item: ProductFilter) => (
              <List.Item>
                <HomeCardItem
                  onItemClick={() => {
                    navigateToProductDetail(item.code);
                  }}
                  hasPrice
                  footerClassName="product-footer-item"
                  title={item.name}
                  image={item.images?.[0]?.url}
                  content={item?.selling_price?.toLocaleString('it-IT', {
                    style: 'currency',
                    currency: 'VND',
                  })}
                  footer={''}
                  tags={[]}
                />
              </List.Item>
            )}
          />
        )}
        <Pagination
          className="pagination-bar"
          currentPage={payload.page}
          totalCount={products.limit}
          pageSize={products.count}
          onPageChange={(page: number) => {
            onPageChange(page);
          }}
        />
      </div>
    </StyledProductSearchResult>
  );
};
const StyledProductSearchResult = styled.div`
  padding: 0 1.6rem;
  .pagination-bar {
    margin-top: 1rem;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .text-med {
    font-family: Roboto-Medium;
  }
  .text-17 {
    font-size: 1.7rem;
  }
  .text-center {
    text-align: center;
  }
  .ant-tabs-top > .ant-tabs-nav {
    margin: 0;
  }
  .head-block {
    display: flex;
    flex-direction: column;
    .filter-block {
      display: flex;
      align-items: center;
      width: 100%;
      overflow: auto;
      overflow-y: hidden;
      .select {
        width: 15rem;
      }
      .mr {
        margin-right: 1.2rem;
      }
    }
    .sort-block {
      display: flex;
      flex-direction: column;
      margin-top: 0.8rem;
      .select {
        width: 15rem;
      }
      .select-sort-title {
      }
    }
    .select-title {
      color: #383838;
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 19.6px;
      margin-bottom: 0.4rem;
    }
  }
  .main-block {
    margin-top: 4rem;
    h2 {
      //styleName: Size 24/Medium;
      font-family: Roboto-Medium;
      font-size: 2.4rem;
      font-weight: 500;
      line-height: 34px;
    }
    .ant-tabs-tab-btn {
      color: ${MAIN_THEME_DATA.mainText};
      font-size: 1.7rem;
      font-family: Roboto-Regular;
      font-weight: 400;
      line-height: 23.8px;
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: ${MAIN_THEME_DATA.mainColor};
      font-size: 1.7rem;
      font-family: Roboto-Medium;
      font-weight: 500;
      line-height: 23.8px;
    }
    .ant-tabs-ink-bar {
      background: ${MAIN_THEME_DATA.mainColor};
      height: 3px;
    }
    .product-footer-item {
      text-align: left;
      font-family: Roboto-Regular;
      font-size: 1.2rem;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 16.8px */
      color: ${MAIN_THEME_DATA.mainText};
    }
  }
`;
