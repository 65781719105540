import { generateRandomId } from '@utils';
import { StarIcon } from 'src/components/Icon';
import { styled } from 'styled-components';

interface RatingProps {
  numStar: number;
}
export const Rating = (props: RatingProps) => {
  const { numStar } = props;
  const STARS = Array.from({ length: numStar }, () => ({
    id: generateRandomId(),
  }));
  return (
    <StyledRating>
      {STARS.map((item) => {
        return <StarIcon key={item.id} />;
      })}
    </StyledRating>
  );
};
const StyledRating = styled.div`
  display: flex;
  margin-bottom: 0.3rem;
`;
