import { MAIN_THEME_DATA, ORDER_STATUS_NUMBER, ORDER_STATUS_TEXT, testImg } from '@configs';
import { Order } from '@interfaces';
import { toVND } from '@utils';
import moment from 'moment';
import { SharedButton, SharedImage } from 'src/components/shared';
import { styled } from 'styled-components';
import { ProductItemList } from './ProductItemList';
import { BillLine } from 'src/components/cart/components/BillLine';
interface OrderItemProps {
  orderItem: Order;
  navigateToOrderDetail?: (id: string) => void;
  isMobile?: boolean;
  onReview?: (order: Order) => void;
  onUpdateOrder: (id: string) => Promise<void>;
}
const WebHeadItemFooter = ({ orderItem }: { orderItem: Order }) => {
  return (
    <div className="head-footer">
      {<span className="reason">{orderItem?.reason && `Lý do: ${orderItem?.reason}`}</span>}
      <div className="money-block">
        <span className="total">
          Tổng tiền:{' '}
          <span className={`${orderItem.debt ? 'debt' : 'money'} med-text`}>
            {toVND(orderItem.total_money)}
          </span>
        </span>
        {orderItem.debt && (
          <span className="total">
            Còn nợ: <span className="money med-text">{toVND(orderItem.money_debt)}</span>
          </span>
        )}
      </div>
    </div>
  );
};
const AppHeadItemFooter = ({ orderItem }: { orderItem: Order }) => {
  return (
    <div className="head-footer">
      <BillLine
        className="bill-item"
        title={'Tổng tiền'}
        content={toVND(orderItem.total_money)}
        isTotalPrice
        priceTextClassName={!orderItem.debt ? 'red-text' : ''}
      />
      {orderItem.debt && (
        <BillLine
          className="bill-item"
          title={'Còn nợ'}
          content={toVND(orderItem.money_debt)}
          isTotalPrice
          priceTextClassName="red-text"
        />
      )}
      {orderItem?.reason && (
        <BillLine
          className="bill-item"
          title={'Lý do'}
          content={orderItem?.reason || ''}
          priceTextClassName="red-text"
        />
      )}
    </div>
  );
};
export const OrderItem = (props: OrderItemProps) => {
  const { orderItem, navigateToOrderDetail, isMobile, onReview, onUpdateOrder } = props;
  return (
    <StyledOrderItem
      onClick={() => {
        navigateToOrderDetail?.(orderItem.id);
      }}
    >
      <div className="heading">
        <span className="med-text">
          {orderItem.code + ' - '}
          <span className="med-text date">
            {moment(orderItem.created_at).format('HH:MM:SS DD/MM/yyyy')}
          </span>
        </span>
        <span className="order-status">{ORDER_STATUS_TEXT[orderItem.status]?.toUpperCase()}</span>
      </div>
      <div className="order-content">
        <ProductItemList isMobile={isMobile} orderList={orderItem.order_items} />
      </div>
      <div className="footer">
        {isMobile ? (
          <AppHeadItemFooter orderItem={orderItem} />
        ) : (
          <WebHeadItemFooter orderItem={orderItem} />
        )}
        <div className="btn-block m-left-4">
          {orderItem.status === ORDER_STATUS_NUMBER.RECEIVED && !orderItem.reviewed && (
            <SharedButton
              onClick={(e) => {
                onReview?.(orderItem);
                e.stopPropagation();
              }}
              textClassName="text-btn"
              textColor={MAIN_THEME_DATA.mainColor}
              text="Đánh giá"
            />
          )}
          {orderItem.status === ORDER_STATUS_NUMBER.DELIVERING && (
            <SharedButton
              onClick={(e) => {
                e.stopPropagation();
                onUpdateOrder(orderItem.id);
              }}
              textClassName="text-btn"
              textColor={MAIN_THEME_DATA.mainColor}
              text="Đã nhận hàng"
            />
          )}
        </div>
      </div>
    </StyledOrderItem>
  );
};
const StyledOrderItem = styled.div`
  .bill-item {
    margin-bottom: 0.8rem;
  }
  .red-text {
    color: ${MAIN_THEME_DATA.mainColor};
  }
  @media (max-width: 768px) {
    border-radius: 12px;
    padding: 1.6rem;
  }
  border-radius: 16px;
  padding: 1.2rem 1.6rem;
  box-shadow: 0px 1px 16px 0px rgba(56, 56, 56, 0.15);
  background-color: white;
  cursor: pointer;
  .heading {
    margin-bottom: 1.2rem;
    display: flex;
    justify-content: space-between;
    padding-bottom: 0.8rem;
    border-bottom: 1px solid #d9d9d9;
    .order-status {
      color: ${MAIN_THEME_DATA.mainColor};
    }
  }
  .order-content {
  }
  .footer {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    margin-top: 0.8rem;
    .money-block {
      display: flex;
      flex-direction: column;
      align-items: end;
    }
    .head-footer {
      width: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      @media (max-width: 768px) {
        flex-direction: column;
        justify-content: unset;
      }
      .reason {
        font-size: 1.7rem;
        color: ${MAIN_THEME_DATA.mainColor};
      }
    }
    .total {
      font-size: 1.7rem;
      .money {
        font-size: 2.4rem;
        font-weight: 500;
        color: ${MAIN_THEME_DATA.mainColor};
      }
      .debt {
        font-size: 1.7rem;
        font-weight: 500;
      }
    }
    .btn-block {
      display: flex;
      @media (max-width: 768px) {
        width: 44%;
      }
      width: 15%;
      margin-top: 0.8rem;
      .mr {
        margin-right: 2.4rem;
      }
    }
  }
  .med-text {
    font-family: Roboto-Medium;
  }
  .date {
    color: #888;
  }
`;
