import { ProductFilterPayload } from '@interfaces';
import { ApiClient } from './axiosClient';

export const categoryAPI = {
  getCategories: () => {
    const url = '/categories?all=true';
    return ApiClient.get(url);
  },
  getCategory: (id: string) => {
    const url = `/categories/${id}`;
    return ApiClient.get(url);
  },
  getAttributes: (payload: ProductFilterPayload) => {
    const url = `/products/filter/attribute`;
    return ApiClient.post(url, payload);
  },
};
