import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '.';
import { RootResponse, UserInfo } from '@interfaces';
import { DEFAULT_USER_INFO } from '@configs';
import { authAPI } from '@api';

interface IUser {
  userInfo: UserInfo;
  avatar: string;
}

const initialState: IUser = {
  userInfo: DEFAULT_USER_INFO,
  avatar: '',
};
export const getUserInfo = createAsyncThunk('user/info', async (id: string) => {
  const response: RootResponse<UserInfo> = await authAPI.getProfile(id);
  return response;
});
const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    setUserInfo: (state, action: PayloadAction<UserInfo>) => {
      state.userInfo = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserInfo.pending, (state) => {})
      .addCase(getUserInfo.fulfilled, (state, { payload }) => {
        state.avatar = payload.data.image?.url;
      })
      .addCase(getUserInfo.rejected, (state) => {});
  },
});

export const { setUserInfo } = userSlice.actions;

export const selectUser = (state: RootState) => state.user;

export default userSlice.reducer;
