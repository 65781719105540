import { memo, useCallback, useEffect, useState } from 'react';

import { AppHeader, AuthHeader } from '@components';
import {
  PATH_CATEGORY,
  PATH_HOME,
  PATH_LOGIN,
  PATH_PRODUCT,
  PATH_PROFILE,
  PATH_SEARCH,
  WEB_NAV_BAR,
} from '@configs';
import {
  selectAuth,
  setAccessToken,
  showUnAuthModal,
  useAppDispatch,
  useAppSelector,
} from '@redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  getCategories,
  setCurrentChildCategory,
  setCurrentParentCategory,
} from 'src/redux/Slices/home';
import { Category, ProductFilter, ProductFilterRoot, SubCategory } from '@interfaces';
import { homeAPI } from '@api';
import { debounce } from 'lodash';
import { LogApp } from '@utils';

interface IProps {
  isAuth?: boolean;
}

export const AuthHeaderModule = memo((props: IProps) => {
  return <AuthHeader />;
});
