import { HEADER_HEIGHT, MAIN_THEME_DATA, MOBILE_HEADER_HEIGHT } from '@configs';
import {
  Banner,
  HomeResponse,
  NewArrivalProduct,
  ProductFilter,
  ProductsHotSelling,
  Promotion,
  WarehouseItem,
} from '@interfaces';
import { selectApp, useAppSelector } from '@redux';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { ShareInput, SharedButton, SharedImage } from '../shared';
import { PaginationOptions } from 'swiper/types';
import { HomeCardItem } from './components/HomeCardItem';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import 'react-horizontal-scrolling-menu/dist/styles.css';
import React from 'react';
import {
  AccountingIcon,
  DebtIcon,
  DeliveryIcon,
  FingerIcon,
  IConArrowLeft,
  IConArrowRight,
  IConCancel,
  LoadingIcon,
  MedalIcon,
  ProfitIcon,
  RoundedFillTickIcon,
  SupportIcon,
  TagIcon,
  TickIcon,
  TimeIcon,
} from '../Icon';
import { HomeList } from './components/HomeList';
import { InfoCard } from './components/InfoCard';
import { FooterIcon } from './components/FooterIcon';
import { LogApp, toVND, useMediaQuery } from '@utils';
import { AppModal } from '../AppModal';
import moment from 'moment';
import { BannerLoading } from './components/BannerLoading';
interface HomeSectionProps {
  homeData: HomeResponse;
  banners: Banner[];
  products: ProductFilter[];
  isUnAuthModalShow: boolean;
  onCloseModal: () => void;
  onNavigateToLogin: () => void;
  isLoading: boolean;
  onItemClick: (item: ProductsHotSelling) => void;
}
const pagination: PaginationOptions = {
  clickable: true,
  renderBullet: function (index, className) {
    return '<span class="' + className + '"></span>';
  },
};
export const HomeSection = ({ banners, isLoading, homeData, onItemClick }: HomeSectionProps) => {
  const { themeData } = useAppSelector(selectApp);
  const isMobile = useMediaQuery('(max-width: 768px)');
  const iconSize = !isMobile ? 32 : 20;
  const {
    amount_order_packing,
    amount_order_pending,
    amount_order_shipping,
    money_static_response,
    products_hot_selling,
    products_latest,
    reward_statistic_response,
  } = homeData;
  const { total_revenue, total_debt, payment_deadline } = money_static_response;
  return (
    <StyledHomePage $themeColor={themeData?.color || MAIN_THEME_DATA.mainColor}>
      <div className="swiper-block">
        {!isLoading ? (
          <Swiper
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            loop
            pagination={pagination}
            className="mySwiper"
            modules={[Pagination, Autoplay]}
          >
            {banners.map((item) => {
              return (
                <SwiperSlide key={item.id}>
                  <div className="slider-item">
                    <SharedImage
                      containerClassName="image-wrapper"
                      className="image"
                      src={item.url}
                    />
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        ) : (
          <BannerLoading />
        )}
      </div>
      <div className={`card-info-block`}>
        <InfoCard
          isLoading={isLoading}
          icon={<LoadingIcon width={iconSize} height={iconSize} />}
          title={amount_order_pending.toString()}
          footer={'Chờ xác nhận'}
        />
        <InfoCard
          isLoading={isLoading}
          icon={<TimeIcon width={iconSize} height={iconSize} />}
          title={amount_order_packing.toString()}
          footer={'Đang xuất kho'}
        />
        <InfoCard
          isLoading={isLoading}
          icon={<DeliveryIcon width={iconSize} height={iconSize} />}
          title={amount_order_shipping.toString()}
          footer={'Đang giao'}
        />
      </div>
      <div className={`card-info-block  ${isMobile ? 'pad-top-0' : ''}`}>
        <InfoCard
          isLoading={isLoading}
          hasIcon={!isMobile}
          bigCard
          icon={<AccountingIcon />}
          title={`Nhập hàng tháng ${moment().month() + 1}`}
          content={`Tính đến ${moment().format('DD/MM/YYYY')}`}
          footer={toVND(total_revenue)}
        />
        <InfoCard
          isLoading={isLoading}
          hasIcon={!isMobile}
          bigCard
          icon={<DebtIcon />}
          title={'Dư nợ còn lại'}
          content={`Hạn thanh toán ${moment(payment_deadline).format('DD/MM/YYYY')}`}
          footer={toVND(total_debt)}
        />
        <InfoCard
          isLoading={isLoading}
          hasIcon={!isMobile}
          bigCard
          icon={<ProfitIcon />}
          title={'Doanh Thu Thương Hiệu'}
          content={`Tháng ${reward_statistic_response.month}/${reward_statistic_response.year}`}
          footer={toVND(reward_statistic_response.total_money)}
        />
      </div>
      <HomeList
        isLoading={isLoading}
        hasPrice
        footerClassNameItem="product-footer-item"
        title="Sản phẩm bán chạy"
        data={products_hot_selling}
        onItemClick={onItemClick}
      />
      <HomeList
        isLoading={isLoading}
        hasPrice
        footerClassNameItem="product-footer-item"
        title="Sản phẩm, hàng mới về"
        data={products_latest}
        onItemClick={onItemClick}
      />
      {/* <HomeList title="Chương trình khuyến mãi" data={promotions} /> */}
      {!isMobile && (
        <div className="footer-icon-block">
          <FooterIcon icon={<MedalIcon />} title={'Sản phẩm'} content={'Chất lượng cao'} />
          <FooterIcon icon={<TagIcon />} title={'Chiết khấu'} content={'Cạnh tranh'} />
          <FooterIcon icon={<FingerIcon />} title={'Thanh toán'} content={'Dễ dàng'} />
          <FooterIcon icon={<SupportIcon />} title={'Hotline hỗ trợ'} content={'0854.596.886'} />
        </div>
      )}
      {/* <AppModal modalClassName="modal" open={warehouseModal}>
        <div>
          <div className="inner-modal">
            <div className="header">
              <ShareInput
                onChange={(e: any) => {
                  onSearchWarehouse(e.target.value);
                }}
                placeholder="Nhập tên kho"
              />
              <IConCancel />
            </div>
            <div className="main">
              <span>Vui lòng chọn kho để xem sản phẩm, giá, khuyến mãi khác nhau</span>
              <div className="warehouses">
                {warehouses.map((item) => {
                  LogApp(item.id)
                  return (
                    <div
                      onClick={() => {
                        onSelectWarehouse(item);
                      }}
                      key={item.id}
                      className="warehouse-item"
                    >
                      <span className="warehouse-txt">{item.name}</span>
                      {item.id === currentWarehouse.id && (
                        <RoundedFillTickIcon width={15} height={15} />
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </AppModal> */}
    </StyledHomePage>
  );
};
const StyledHomePage = styled.div<{ $themeColor: string }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .modal {
    .content {
      padding: 0;
      max-width: 37rem;
      max-height: 35rem;
      overflow-y: auto;
    }
  }
  .inner-modal {
    .header {
      background-color: ${MAIN_THEME_DATA.mainColor};
      display: flex;
      padding: 1rem;
      align-items: center;
      border-radius: 0.6rem 0.6rem 0 0;
      .inner-input {
        height: 4rem;
        font-size: 1.4rem;
      }
    }
    .main {
      padding: 1rem;
      .warehouses {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 1rem;
        .warehouse-item {
          width: calc((100% - 2rem) / 2);
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-bottom: 1px solid #f1f1f1;
          padding: 0.5rem 0.75rem;
          margin-bottom: 0.5rem;
          cursor: pointer;
          &:hover {
            background-color: ${MAIN_THEME_DATA.bgColor};
          }
          .warehouse-txt {
            font-size: 1.2rem;
            font-family: Roboto-Medium;
          }
        }
      }
    }
  }
  .product-footer-item {
    text-align: left;
    font-family: Roboto-Regular;
    font-size: 12px;
    @media (max-width: 768px) {
      font-size: 1rem;
    }
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 16.8px */
  }
  .swiper-block {
    width: 100%;
    display: flex;
    justify-content: center;
    .slider-item {
      width: 100%;
      .image-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        .image {
          width: calc((5 * 100%) / 6);
          aspect-ratio: 1200 / 536;
          @media (max-width: 768px) {
            width: calc(100% - 3.2rem);
            aspect-ratio: 358/160;
          }
          border-radius: 16px;
          object-fit: fill;
        }
      }
    }
  }
  @media (max-width: 768px) {
    .card-info-block::-webkit-scrollbar {
      display: none;
    }
    .pad-top-0 {
      padding-top: 0 !important;
    }
    /* Hide scrollbar for IE, Edge and Firefox */
    .card-info-block {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
  .card-info-block {
    margin-top: 4rem;
    display: flex;
    width: 80%;
    @media (max-width: 768px) {
      margin-top: 0;
      width: 100%;
      padding: 1.6rem;
      overflow-y: auto;
    }
    @media (min-width: 1920px) {
      margin-top: 1.6rem;
      width: 70%;
      padding: 0 1.6rem;
    }
    justify-content: space-between;
  }
  .footer-icon-block {
    margin-top: 8rem;
    display: flex;
    width: 75%;
    justify-content: space-between;
  }
`;
