import { GetOrderParams, RatePayload } from '@interfaces';
import { ApiClient } from './axiosClient';

export const orderAPI = {
  getOrders: (payload: GetOrderParams) => {
    const url = `/orders/filter/client?page=${payload.page}&size=10&all=false`;
    return ApiClient.post(url, payload);
  },
  getOrder: (id: string) => {
    const url = `/orders/${id}`;
    return ApiClient.get(url);
  },
  rateOrder: (payload: RatePayload) => {
    const url = `/reviews`;
    return ApiClient.post(url, payload);
  },
  updateOrder: (id: string, status: number) => {
    const url = `/orders/${id}?status=${status}`;
    return ApiClient.post(url);
  },
  cancelOrder: (id: string) => {
    const url = `/orders/${id}/cancel`;
    return ApiClient.post(url);
  },
  getOrderCounts: (payload: GetOrderParams) => {
    const url = `/orders/statistic/status`;
    return ApiClient.post(url, payload);
  },
};
