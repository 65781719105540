import { MAIN_THEME_DATA, enumNavKey } from '@configs';
import { CartIcon, CategoryIcon, HomeIcon, OrderIcon, UserIcon } from 'src/components/Icon';
import styled from 'styled-components';
interface NavIconProps {
  pathKey: enumNavKey;
  title: string;
  onClick: () => void;
  isSelected: boolean;
}
export const NavIcon = ({ pathKey, title, onClick, isSelected }: NavIconProps) => {
  const selectedColor = isSelected ? MAIN_THEME_DATA.mainColor : MAIN_THEME_DATA.mainText;
  const getNavIcon = (pathKey: enumNavKey) => {
    switch (pathKey) {
      case enumNavKey.HOME:
        return <HomeIcon color={selectedColor} />;
      case enumNavKey.CATEGORY:
        return <CategoryIcon color={selectedColor} />;
      case enumNavKey.CART:
        return <CartIcon color={selectedColor} />;
      case enumNavKey.ORDER:
        return <OrderIcon color={selectedColor} />;
      default:
        return <UserIcon color={selectedColor} />;
    }
  };
  return (
    <StyledNavIcon isSelected={isSelected} onClick={onClick}>
      {getNavIcon(pathKey)}
      <span className="nav-title">{title}</span>
    </StyledNavIcon>
  );
};
const StyledNavIcon = styled.div<{
  isSelected: boolean;
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  .nav-title {
    text-align: center;
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 0.4rem;
    ${(p) => `color: ${p.isSelected ? MAIN_THEME_DATA.mainColor : MAIN_THEME_DATA.mainText}`}
  }
`;
