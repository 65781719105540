import { styled } from 'styled-components';
interface BillLineProps {
  title: string;
  content: string;
  isTotalPrice?: boolean;
  isTextPrice?: boolean;
  className?: string;
  priceTextClassName?: string;
  titleTextClassName?: string;
}
export const BillLine = (props: BillLineProps) => {
  const {
    title,
    content,
    isTotalPrice = false,
    isTextPrice = false,
    className,
    priceTextClassName = '',
    titleTextClassName = '',
  } = props;
  return (
    <StyledBillLine className={className} isTotalPrice={isTotalPrice}>
      <span className={`item-text ${titleTextClassName}`}>{title}</span>
      <span
        className={`item-text ${isTotalPrice ? 'price-text' : ''} ${
          isTextPrice ? 'italic-price-text' : ''
        } ${priceTextClassName}`}
      >
        {content}
      </span>
    </StyledBillLine>
  );
};
const StyledBillLine = styled.div<{ isTotalPrice?: boolean }>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media (max-width: 768px) {
    margin-bottom: 0.8rem;
  }
  margin-bottom: 1.6rem;
  .item-text {
    @media (max-width: 768px) {
      font-size: 1.4rem;
    }
    font-size: 1.7rem;
    font-weight: ${(p) => (p?.isTotalPrice ? '500' : '400')};
    line-height: 23.8px;
  }
  .price-text {
    @media (max-width: 768px) {
      font-size: 1.4rem;
    }
    font-size: 2.4rem;
    font-family: Roboto-Medium;
  }
  .italic-price-text {
    font-style: italic;
  }
`;
