import React, { FC } from 'react';
import type { IBubbleProps } from './types';
import { Elements } from '../constants';
import whiteTick from '../assets/white-tick.svg';
import { STEP_STATUSES, LABEL_POSITION } from '../constants';
import styled from 'styled-components';
import { RoundedFillTickIcon } from 'src/components/Icon';
import { MAIN_THEME_DATA } from '@configs';
import Skeleton from 'react-loading-skeleton';
const MOBILE_SIZE = 16;
const WEB_SIZE = 24;
const Bubble: FC<IBubbleProps> = (props) => {
  const {
    step,
    renderAdornment,
    index,
    currentStepIndex,
    handleStepClick = null,
    showCursor,
    labelPosition,
    isLoading,
    isMobile,
  } = props;
  const isCompleted = step?.status === STEP_STATUSES.COMPLETED;
  return (
    <StyledBubble
      className={`
      ${showCursor && `cursorPointer`}
      ${index === currentStepIndex && `activeStepBubble`}
      ${
        step.status === STEP_STATUSES.UNVISITED &&
        currentStepIndex !== index &&
        `inactiveStepBubble`
      }
      `}
      onClick={(): void | null => handleStepClick && handleStepClick()}
      role="presentation"
      id="stepper-bubble"
    >
      {(renderAdornment && renderAdornment(step, index)) || (
        <>
          {isCompleted ? (
            <RoundedFillTickIcon
              width={isMobile ? MOBILE_SIZE : WEB_SIZE}
              height={isMobile ? MOBILE_SIZE : WEB_SIZE}
            />
          ) : (
            <div className="unvisitedDot" />
          )}
        </>
      )}
      <div
        className={`${`labelContainer`} ${`labelContainer__${
          labelPosition || LABEL_POSITION.RIGHT
        }`} ${!isCompleted ? 'inactive-dot' : ''}`}
      >
        {!isMobile && (
          <>
            {isLoading ? <Skeleton width="13rem" /> : <span className="leftText">{step.time}</span>}
          </>
        )}
        <div className="rightText">
          {step?.label && (
            <>
              {isLoading ? (
                <Skeleton width="13rem" />
              ) : (
                <span
                  className={`${`labelTitle`}
                  ${showCursor && `cursorPointer`}
                  ${isCompleted && `activeLabelTitle`}`}
                  onClick={(): void | null => handleStepClick && handleStepClick()}
                  role="presentation"
                  id={`stepper-label-${index}`}
                >
                  {step.label}
                </span>
              )}
            </>
          )}
          {isMobile && (
            <>
              {isLoading ? (
                <Skeleton width="13rem" />
              ) : (
                <span className="leftText">{step.time}</span>
              )}
            </>
          )}
          {step?.description && (
            <>
              {
                <span
                  className={`${`labelDescription`} ${isCompleted && `activeLabelDescription`}`}
                  onClick={(): void | null => handleStepClick && handleStepClick()}
                  role="presentation"
                  id={`stepper-desc-${index}`}
                >
                  {step.description}
                </span>
              }
            </>
          )}
        </div>
      </div>
    </StyledBubble>
  );
};
const StyledBubble = styled.div`
  border-radius: 50%;
  width: 24px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: visible;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  position: relative;
  .unvisitedDot {
    height: 12px;
    width: 12px;
    background: #888;
    border-radius: 6px;
  }
  .activeStepBubble {
    /* border: 7px solid #CBCBEF; */
    margin: 0;
  }
  .inactiveStepBubble {
    opacity: 0.4;
  }
  .whiteTickImg {
    object-fit: cover;
    width: 10px;
    height: 8px;
  }
  .cursorPointer {
    cursor: pointer;
  }
  .inactive-dot {
    top: -6px;
  }
  .labelContainer {
    position: absolute;
    width: max-content;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    .leftText {
      @media (max-width: 768px) {
        font-size: 1.4rem;
        color: #888;
      }
      font-size: 1.7rem;
      line-height: 140%;
    }
    .rightText {
      display: flex;
      flex-direction: column;
      margin-left: 3.2rem;
    }
    &__right {
      left: 44px;
      @media (max-width: 768px) {
        left: 0px;
      }
    }
    &__left {
      right: 44px;
      align-items: end;
    }
    .labelTitle {
      font-family: Roboto-Medium;
      font-size: 1.7rem;
      @media (max-width: 768px) {
        font-size: 1.4rem;
      }
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
    }
    .activeLabelTitle {
      color: ${MAIN_THEME_DATA.mainColor};
    }
    .labelDescription {
      font-size: 1.7rem;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
    }
    .activeLabelDescription {
      color: ${MAIN_THEME_DATA.mainColor};
    }
  }
`;
export default Bubble;
