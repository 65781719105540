import { CartPayload, OrderPayload } from '@interfaces';
import { ApiClient } from './axiosClient';

export const cartAPI = {
  getCart: (userId: string, warehouseId: string) => {
    const url = `/customers/${userId}/carts?warehouse_id=${warehouseId}`;
    return ApiClient.get(url);
  },
  updateCart: (payload: CartPayload[], userId: string, warehouseId: string) => {
    const url = `/customers/${userId}/carts?warehouse_id=${warehouseId}`;
    return ApiClient.put(url, payload);
  },
  addToCart: (payload: CartPayload[], userId: string, warehouseId: string) => {
    const url = `/customers/${userId}/carts?warehouse_id=${warehouseId}`;
    return ApiClient.post(url, payload);
  },
  deleteCart: (ids: string[], userId: string, warehouseId: string) => {
    const url = `/customers/${userId}/carts?warehouse_id=${warehouseId}`;
    return ApiClient.delete(url, ids);
  },
  createOrder: (payload: OrderPayload) => {
    const url = `/orders`;
    return ApiClient.post(url, payload);
  },
};
