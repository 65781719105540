import { MAIN_THEME_DATA } from '@configs';
import styled from 'styled-components';
interface FooterIconProps {
  icon: JSX.Element | JSX.Element[];
  title: string;
  content: string;
}
export const FooterIcon = ({ icon, title, content }: FooterIconProps) => {
  return (
    <StyledFooterIcon>
      <div className="icon">{icon}</div>
      <div className="text-block">
        <span className={`text text-14-main`}>{title}</span>
        <span className={`text text-24`}>{content}</span>
      </div>
    </StyledFooterIcon>
  );
};
const StyledFooterIcon = styled.div<{ bigCard?: boolean }>`
  display: flex;
  .icon {
    box-shadow: 0px 1px 16px rgba(56, 56, 56, 0.15);
    border-radius: 100px;
    padding: 1.6rem;
    background-color: white;
  }
  .text-block {
    margin-left: 1.2rem;
    display: flex;
    flex-direction: column;
  }
  .text {
    font-family: Roboto-Regular;
    font-weight: 400;
  }
  .text-14-main {
    color: ${MAIN_THEME_DATA.mainText};
    font-size: 1.4rem;
  }
  .text-24 {
    color: #d20c00;
    font-size: 2.4rem;
  }
`;
