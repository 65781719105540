import React from 'react';
import styled from 'styled-components';
import classnames from 'classnames';

import { DOTS, LogApp, usePagination } from '@utils';
import { MAIN_THEME_DATA } from '@configs';
import { NextIcon, PrevIcon } from '@components';

export const Pagination = (props: any) => {
  const { onPageChange, totalCount, siblingCount = 1, currentPage, pageSize, className } = props;
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (paginationRange && paginationRange.length < 1) {
    return null;
  }
  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };
  const lastPage = paginationRange && paginationRange[paginationRange.length - 1];
  return (
    <StyledPagination
      className="pagination-contain"
      leftDisabled={currentPage === 0}
      rightDisabled={currentPage + 1 === paginationRange?.length}
      $mainColor={MAIN_THEME_DATA.mainColor}
    >
      <ul className={classnames('pagination-container', { [className]: className })}>
        <li
          className={classnames('pagination-item  arrow-left', {
            disabled: currentPage === 0,
          })}
          onClick={onPrevious}
        >
          <PrevIcon size={18} strokeWidth={1.5} color={MAIN_THEME_DATA.mainColor} />
        </li>
        {paginationRange?.map((pageNumber) => {
          if (pageNumber === DOTS) {
            return <li className="pagination-item dots">&#8230;</li>;
          }

          return (
            <li
              className={classnames('pagination-item', {
                selected: Number(pageNumber) - 1 === currentPage,
              })}
              onClick={() => onPageChange(Number(pageNumber) - 1)}
            >
              {pageNumber}
            </li>
          );
        })}
        <li
          className={classnames('pagination-item  arrow-right', {
            disabled: currentPage + 1 === lastPage,
          })}
          onClick={onNext}
        >
          <NextIcon size={18} strokeWidth={1.5} color={MAIN_THEME_DATA.mainColor} />
        </li>
      </ul>
    </StyledPagination>
  );
};
const StyledPagination = styled.div<{
  leftDisabled: boolean;
  rightDisabled: boolean;
  $mainColor: string;
}>`
  .pagination-container {
    display: flex;
    list-style-type: none;
    .pagination-item {
      background-color: #ffd5d1;
      height: 2.4rem;
      width: 2.4rem;
      text-align: center;
      margin: auto 4px;
      color: ${MAIN_THEME_DATA.mainColor};
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      align-items: center;
      letter-spacing: 0.01071em;
      /* border: 1px solid #184d63; */
      /* border-radius: 16px; */
      line-height: 19.6px;
      font-size: 1.4rem;
      min-width: 2.4rem;
      font-family: Roboto-Regular;
      border-radius: 100px;
      &.dots {
        background-color: transparent;
        cursor: default;
      }
      &:hover {
        cursor: pointer;
      }

      &.selected {
        font-weight: bold;
        color: #f1f5f9;
        background-color: ${(p) => p.$mainColor};
        /* border-width: 2px; */
      }

      &.disabled {
        pointer-events: none;
        opacity: 0.45;
        .arrow::before {
          border-right: 0.12em solid rgba(0, 0, 0, 0.43);
          border-top: 0.12em solid rgba(0, 0, 0, 0.43);
        }

        &:hover {
          background-color: transparent;
          cursor: default;
        }
      }
    }
    .arrow-left {
      /* border-color: ${(p) => (p.leftDisabled ? '#d9d9d9' : MAIN_THEME_DATA.mainColor)}; */
    }
    .arrow-right {
      /* border-color: ${(p) => (p.rightDisabled ? '#d9d9d9' : MAIN_THEME_DATA.mainColor)}; */
    }
  }
`;
export default Pagination;
