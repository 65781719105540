import { IntroSection } from '@components';
import React from 'react';

interface IProps {
  children: JSX.Element;
  hasBackgroundImage?: boolean;
}

export const IntroSectionModule = (props: IProps) => {
  return <IntroSection {...props} />;
};
