import { MAIN_THEME_DATA, PATH_CHANGE_PASSWORD } from '@configs';
import {
  AddressItem,
  AuthAutocompleteData,
  SellerItem,
  UserInfo,
  WarehouseItem,
} from '@interfaces';
import { OPTION_VALUE } from '@pages';
import { FieldErrorsImpl, UseFormRegister, UseFormWatch } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ChangePasswordForm } from '../components/ChangePasswordForm';
import { EditProfileForm } from '../components/EditProfileForm';
import { getPath } from '@utils';
interface IProfileForm {
  register?: UseFormRegister<any>;
  handleChangePassword?: any;
  errors?: Partial<FieldErrorsImpl<any>>;
  userInfo?: UserInfo;
  avatar: string;
}
export const AppProfileForm = (props: IProfileForm) => {
  const { errors, register, userInfo, handleChangePassword, avatar } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const isChangePassword = getPath(location.pathname) === PATH_CHANGE_PASSWORD;
  return (
    <StyledProfileForm>
      {!isChangePassword ? (
        <EditProfileForm avatar={avatar} userInfo={userInfo} />
      ) : (
        <ChangePasswordForm
          handleChangePassword={handleChangePassword}
          register={register}
          errors={errors}
        />
      )}
    </StyledProfileForm>
  );
};
const StyledProfileForm = styled.div`
  padding: 1.6rem;
  border-radius: 1.2rem;
  width: 100%;
  .actions {
    width: 100%;
    margin-top: 1.6rem;
  }
  .user-input {
    margin-bottom: 1.6rem;
  }
  .user-wrapper {
    .user-form {
      margin-top: 2.4rem;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      .ant-select-selection-search {
        font-size: 1.7rem;
      }
      .ant-picker,
      .ant-select-selector {
        height: 4.6rem;
      }
      .shared-input {
        .inner-input {
          height: 4.8rem;
        }
      }
    }
    .user-info {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 1.2rem;
      .base-info {
        display: flex;
        flex-direction: column;
        span {
          display: inline-block;
          margin-bottom: 0.4rem;
          color: white;
          font-size: 1.7rem;
        }
        .name {
          font-size: 2.4rem;
          font-family: Roboto-Medium;
          font-weight: 500;
        }
      }
      .user-avatar {
        width: 10.4rem;
        aspect-ratio: 1;
        border-radius: 100px;
        img {
          border-radius: 100px;
        }
      }
    }
  }

  .change-password-form {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
`;
