import { IMAGES } from '@assets';
import {
  MAIN_THEME_DATA,
  PATHS_NO_HEADER,
  PATH_CART,
  PATH_CATEGORY,
  PATH_HOME,
  PATH_PRODUCT,
  PATH_PROFILE,
} from '@configs';
import { selectApp, useAppSelector } from '@redux';
import { getPath, useHeaderTitle } from '@utils';
import { useLocation, useNavigate } from 'react-router-dom';
import { CartIcon, ChevronDownIcon, IConArrowLeft, LocationIcon, SearchIcon } from '../../Icon';
import { SharedImage, ShareInput } from '../../shared';
import { StyledMobileHeader } from '../styles';
import { ProductFilter, WarehouseItem } from '@interfaces';
import { Dropdown } from 'antd';
import { ProductModal } from '../components/ProductModal';

interface IProps {
  isAuth?: boolean;
  handleOpenChange: (val: boolean) => void;
  handleProductOpenChange: (val: boolean) => void;
  handleSubmitSearch: () => void;
  productOpen: boolean;
  onNavigateToProductDetail: (item: ProductFilter) => void;
  products: ProductFilter[];
  keyword: string;
  onSearch: (value: string) => void;
  onOpenWarehouseModal: (value: boolean) => void;
  warehouses: WarehouseItem[];
  currentWarehouse: WarehouseItem;
  warehouseModal: boolean;
  avatar: string;
}
const PATHS_HAVE_SEARCH_INPUT = [PATH_HOME, PATH_PRODUCT, PATH_CATEGORY];
export const MobileHeader = (props: IProps) => {
  const {
    isAuth,
    productOpen,
    handleOpenChange,
    handleProductOpenChange,
    handleSubmitSearch,
    onNavigateToProductDetail,
    products,
    keyword,
    onSearch,
    currentWarehouse,
    onOpenWarehouseModal,
    avatar,
  } = props;
  const { themeData } = useAppSelector(selectApp);
  const location = useLocation();
  const haveSearchInput = PATHS_HAVE_SEARCH_INPUT.includes(getPath(location.pathname));
  const title = useHeaderTitle();
  const navigate = useNavigate();
  const noHeader = PATHS_NO_HEADER.includes(getPath(location.pathname));
  if (noHeader) return <></>;
  return (
    <StyledMobileHeader
      noHeader={noHeader}
      haveSearchInput={haveSearchInput}
      className="header"
      $appTheme={MAIN_THEME_DATA.mainColor}
      $themeData={themeData}
      $isAuth={isAuth}
    >
      {haveSearchInput ? (
        <>
          <div className="header-container">
            <div className="header-actions">
              <SharedImage containerClassName="img-ctn" src={IMAGES.logo} />
              {
                <div
                  onClick={() => {
                    onOpenWarehouseModal(true);
                  }}
                  className="warehouse-wrapper"
                >
                  <LocationIcon width={25} height={25} color="white" />
                  <div className="warehouse-info">
                    <div className="view-at">
                      <span className="view-at-txt">Xem hàng tại</span>
                      <ChevronDownIcon width={16} height={16} color="white" />
                    </div>
                    <span
                      // className='warehouse'
                      style={
                        currentWarehouse?.name?.length > 15 ? { fontSize: 8 } : { fontSize: 12 }
                      }
                    >
                      {currentWarehouse?.name}
                    </span>
                  </div>
                </div>
              }
            </div>
            <div className="header-title">
              <span className="title"></span>
            </div>
            <div className="header-right">
              <div className="app-btn settings-btn">
                <CartIcon
                  onClick={() => {
                    navigate(PATH_CART);
                  }}
                  color="#ffffff"
                />
              </div>
            </div>
          </div>
          <div className="search-container">
            {/* <ShareInput
              placeholder="Tìm kiếm sản phẩm"
              sufIcon={<SearchIcon color={MAIN_THEME_DATA.mainColor} />}
            /> */}
            <Dropdown
              placement="bottomCenter"
              trigger={['click']}
              open={productOpen}
              onOpenChange={handleProductOpenChange}
              className="dropdown"
              dropdownRender={() => (
                <ProductModal
                  onNavigateToProductDetail={onNavigateToProductDetail}
                  products={products}
                />
              )}
            >
              <form>
                <ShareInput
                  value={keyword}
                  onPressEnter={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      handleSubmitSearch();
                    }
                  }}
                  onChange={(e: any) => {
                    onSearch(e.target.value);
                  }}
                  placeholder="Tìm kiếm sản phẩm, danh mục..."
                  sufIcon={<SearchIcon color={MAIN_THEME_DATA.mainColor} />}
                />
              </form>
            </Dropdown>
          </div>
        </>
      ) : (
        <div className="back-header">
          <IConArrowLeft
            onClick={() => {
              navigate(-1);
            }}
            className="icon"
            color="white"
            width={24}
            height={24}
          />
          <h2 className="intro-x text-center heading-title">{title}</h2>
        </div>
      )}
    </StyledMobileHeader>
  );
};
