import { MAIN_THEME_DATA } from '@configs';
import { Category, CategoryDropdown, MegaCategoryDropdown, SubCategory } from '@interfaces';
import { LogApp } from '@utils';
import { useState } from 'react';
import { styled } from 'styled-components';

interface CategoryModalProps {
  categories: Category[];
  onNavigateToCategory: (item: Category) => void;
  onClickChildCategory: (item: SubCategory) => void;
}
export const CategoryModal = (props: CategoryModalProps) => {
  const { categories, onNavigateToCategory, onClickChildCategory } = props;
  const [currentIndex, setCurrentIndex] = useState(-1);
  return (
    <StyledCategoryModal>
      <div className="inline-modal">
        {categories.map((item, index) => {
          return (
            <div
              onMouseOver={() => {
                setCurrentIndex(index);
              }}
              // onMouseLeave={() => {
              //   setCurrentIndex(-1);
              // }}
              className="category-col"
              key={item.id}
            >
              <h4
                style={{ width: 'fit-content' }}
                onClick={() => {
                  onNavigateToCategory(item);
                  onClickChildCategory(item);
                }}
              >
                {item.name}
              </h4>
              {currentIndex === index && (
                <>
                  {item.sub_categories.map((val) => {
                    return (
                      <p
                        onClick={(e) => {
                          onNavigateToCategory(item);
                          onClickChildCategory(val);
                        }}
                        key={`${item.id}_${val.id}`}
                      >
                        {val.name}
                      </p>
                    );
                  })}
                </>
              )}
            </div>
          );
        })}
      </div>
      <div className="flex justify-center">
        <span className="note">*Di chuột vào từng danh mục để xem danh mục con</span>
      </div>
    </StyledCategoryModal>
  );
};
const StyledCategoryModal = styled.div`
  background-color: white;
  border-radius: 12px;
  width: 120rem;
  align-self: center;
  padding: 2.4rem;
  max-height: 50rem;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  .inline-modal {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /* justify-content: space-between; */
    .category-col {
      width: 28.8rem;
      margin-bottom: 1.2rem;
      cursor: pointer;
    }
  }
  .note {
    color: ${MAIN_THEME_DATA.mainColor};
    font-size: 1.2rem;
    font-style: italic;
    text-align: center;
  }
  h4 {
    font-family: Roboto-Medium;
    font-size: 1.7rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    color: ${MAIN_THEME_DATA.mainColor};
  }
  p {
    margin-bottom: 0;
    font-family: Roboto-Regular;
    font-size: 1.7rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    color: ${MAIN_THEME_DATA.mainText};
    margin-bottom: 0.4rem;
    cursor: pointer;
  }
`;
