import { Avatar } from 'antd';
import React, { BaseSyntheticEvent } from 'react';
import { UserOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { Camera } from '../../Icon';
import { SharedButton, SharedImage, ShareInput, ShareUploadImage } from '../../shared';
import { selectApp, selectUser, useAppSelector } from '@redux';
import { AUTH_THEME_COLOR, MAIN_THEME_DATA, PATH_EDIT_PROFILE, testImg } from '@configs';
import { useNavigate } from 'react-router-dom';
import { LogApp, useMediaQuery } from '@utils';
import moment from 'moment';
import { UploadImageModule } from '@modules';
import { SharedAutocomplete } from '../../shared/autocomplete';
import { FieldErrorsImpl, UseFormRegister, UseFormWatch } from 'react-hook-form';
import {
  AddressItem,
  AuthAutocompleteData,
  AutocompleteItem,
  SellerItem,
  UserInfo,
  WarehouseItem,
} from '@interfaces';
interface IEditProfileForm {
  userInfo?: UserInfo;
  avatar: string;
}
export const EditProfileForm = (props: IEditProfileForm) => {
  const { userInfo, avatar } = props;
  LogApp(userInfo, 'avuserInfot');
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width: 768px)');
  return (
    <div className="user-wrapper">
      <div className="user-info">
        {!isMobile && (
          <div className="base-info">
            <span>Xin chào</span>
            <span className="name">{userInfo?.full_name}</span>
          </div>
        )}
        <SharedImage containerClassName="user-avatar" src={avatar ?? testImg} />
      </div>
      <div className="user-form">
        <ShareInput
          containerClassName="user-input"
          required
          placeholder="Nhập họ và tên"
          name="full_name"
          type="text"
          onChange={() => {}}
          disabled
          defaultValue={userInfo?.full_name}
        />
        <ShareInput
          containerClassName="user-input"
          required
          placeholder="Nhập số điện thoại (Đã đăng ký Zalo)"
          name="phone_number"
          type="number"
          onChange={() => {}}
          disabled
          defaultValue={userInfo?.phone_number}
        />
        <SharedAutocomplete
          onSelect={(value) => {}}
          disabled
          containerClassName="user-input"
          data={[]}
          placeholder="Nhập tỉnh/thành phố"
          defaultValue={userInfo?.address?.provinces?.province_name}
          key={`${userInfo?.address?.provinces?.code}:province`}
          required
          filterOption={(inputValue: string, option: AutocompleteItem<AddressItem>) =>
            option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
          }
        />
        <SharedAutocomplete
          onSelect={(value) => {}}
          containerClassName="user-input"
          data={[]}
          placeholder="Nhập quận/huyện"
          defaultValue={userInfo?.address?.districts?.district_name}
          key={`${userInfo?.address?.districts?.code}:district`}
          required
          filterOption={(inputValue: string, option: AutocompleteItem<AddressItem>) =>
            option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
          }
          disabled
        />
        <SharedAutocomplete
          onSelect={(value) => {}}
          disabled
          containerClassName="user-input"
          data={[]}
          placeholder="Nhập phường/xã"
          defaultValue={userInfo?.address?.wards?.ward_name}
          key={`${userInfo?.address?.wards?.code}:ward`}
          required
          filterOption={(inputValue: string, option: AutocompleteItem<AddressItem>) =>
            option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
          }
        />
        <SharedAutocomplete
          onSelect={(value) => {}}
          containerClassName="user-input"
          disabled
          data={[]}
          placeholder="Chọn kho"
          defaultValue={userInfo?.warehouse?.name}
          key={`${userInfo?.warehouse?.id}:warehouse`}
          required
          filterOption={(inputValue: string, option: AutocompleteItem<WarehouseItem>) =>
            option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
          }
        />
        <SharedAutocomplete
          onSelect={(value) => {}}
          containerClassName="user-input"
          data={[]}
          placeholder="Chọn seller"
          defaultValue={userInfo?.seller?.brand_name}
          key={`${userInfo?.seller?.id}:seller`}
          required
          filterOption={(inputValue: string, option: AutocompleteItem<SellerItem>) =>
            option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
          }
          disabled
        />
        <ShareInput
          required
          placeholder="Nhập địa chỉ"
          name="address_detail"
          className="input"
          type="text"
          onChange={() => {}}
          disabled
          defaultValue={userInfo?.address.detail}
        />
        {/* <div className="actions">
          <SharedButton
            typeHtml="submit"
            text="Cập nhật thông tin"
            className="submit__btn"
            backgroundColor={MAIN_THEME_DATA.mainColor}
            btnStyle="pad"
          />
        </div> */}
      </div>
    </div>
  );
};
