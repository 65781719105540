import { MAIN_THEME_DATA } from '@configs';
import { PropsWithChildren } from 'react';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';
interface InfoCardProps {
  icon: JSX.Element | JSX.Element[];
  title: string;
  content?: string;
  footer: string;
  bigCard?: boolean;
  hasIcon?: boolean;
  isLoading?: boolean;
}
const Inner = ({
  icon,
  title,
  content,
  footer,
  bigCard,
  hasIcon = true,
  isLoading = true,
}: InfoCardProps) => {
  return;
};
function StarWrapper({ children }: PropsWithChildren<unknown>) {
  return (
    <div
      style={{
        minWidth: '45.2rem',
      }}
    >
      {children}
    </div>
  );
}
export const InfoCard = ({
  icon,
  title,
  content,
  footer,
  bigCard,
  hasIcon = true,
  isLoading = true,
}: InfoCardProps) => {
  return (
    <StyledInfoCard bigCard={bigCard}>
      {isLoading ? (
        <Skeleton circle height="100%" containerClassName="avatar-skeleton" />
      ) : (
        <>{hasIcon && <div className="icon">{icon}</div>}</>
      )}
      <div className="text-block">
        {isLoading ? (
          <Skeleton count={3} height="100%" duration={0.9} />
        ) : (
          <>
            <span className={`text ${bigCard ? 'text-17-main' : 'text-32'}`}>{title}</span>
            <span className="text text-17-88">{content}</span>
            <span className={`text  ${bigCard ? 'text-32' : 'text-14-main'}`}>{footer}</span>
          </>
        )}
      </div>
    </StyledInfoCard>
  );
};

const StyledInfoCard = styled.div<{ bigCard?: boolean }>`
  .avatar-skeleton {
    height: 8rem;
    aspect-ratio: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: ${(p) => (p?.bigCard ? '1rem' : '0')};
  }
  box-shadow: 0px 1px 16px rgba(56, 56, 56, 0.15);
  background-color: white;
  border-radius: 16px;
  padding: 1.2rem 2.4rem;
  display: flex;
  flex-direction: ${(p) => (p?.bigCard ? 'row-reverse' : 'row')};
  align-items: center;
  justify-content: ${(p) => (p?.bigCard ? 'center' : 'space-between')};
  min-width: ${(p) => (p?.bigCard ? '36.8rem' : '20.8rem')};
  .text-center {
    text-align: center;
  }
  @media (max-width: 768px) {
    padding: 0.6rem 0.8rem;
    border-radius: ${(p) => (p?.bigCard ? '16px' : '8px')};
    min-width: ${(p) => (p?.bigCard ? '14.2rem' : '9.4rem')};
    margin-right: ${(p) => (p?.bigCard ? '1.2rem' : '')};
  }
  .icon {
    padding: 1.6rem;
    @media (max-width: 768px) {
      padding: 0.6rem;
    }
    border-radius: 100px;
    background-color: ${(p) => (p?.bigCard ? 'transparent' : '#FFF4F3')};
  }
  .text-block {
    display: flex;
    flex-direction: column;
    margin-left: 0.8rem;
    flex: 1;
  }
  .text {
    font-family: Roboto-Regular;
    font-weight: 400;
    text-align: ${(p) => (p?.bigCard ? 'left' : 'center')};
  }
  .text-14-main {
    color: ${MAIN_THEME_DATA.mainText};
    font-size: 1.4rem;
    @media (max-width: 768px) {
      font-size: 1rem;
    }
  }
  .text-17-main {
    color: ${MAIN_THEME_DATA.mainText};
    font-size: 1.7rem;
    @media (max-width: 768px) {
      font-size: 1.4rem;
    }
  }
  .text-17-88 {
    color: #888888;
    font-size: 1.7rem;
    @media (max-width: 768px) {
      font-size: 1.2rem;
    }
  }
  .text-32 {
    color: #d20c00;
    font-size: 3.2rem;
    @media (max-width: 768px) {
      font-size: 1.6rem;
    }
    font-weight: ${(p) => (p?.bigCard ? '500' : '400')};
  }
`;
