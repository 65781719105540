import { BagIcon } from 'src/components/Icon';
import { styled } from 'styled-components';
interface RoundedIconProps {
  icon: JSX.Element;
  title: string;
}
export const RoundedIcon = (props: RoundedIconProps) => {
  const { icon, title } = props;
  return (
    <StyledRoundedIcon>
      <div className="icon-wrapper">{icon}</div>
      <span className="icon-title">{title}</span>
    </StyledRoundedIcon>
  );
};
const StyledRoundedIcon = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  .icon-wrapper {
    padding: 1rem;
    border: 2px solid white;
    border-radius: 200px;
    width: fit-content;
  }
  .icon-title {
    font-size: 1.2rem;
    color: white;
    font-weight: 400;
    line-height: 140%;
    margin-top: 0.4rem;
  }
`;
