import md5 from 'md5';
import moment from 'moment';

export const genAuthHash = (userId: string, orderId: string) => {
  const secretKey = process.env.ORDER_SUCCESS_SECRET_KEY;
  const plainStr = userId + orderId + secretKey + moment().format('DD/MM/YYYY');
  const checkHash = md5(plainStr);
  return checkHash;
};
export const genOrderHash = (userId: string, orderId: string) => {
  const secretKey = process.env.ORDER_SUCCESS_SECRET_KEY;
  const plainStr = userId + orderId + secretKey + moment().format('DD/MM/YYYY');
  const checkHash = md5(plainStr);
  return checkHash;
};
