import { AppModal, SharedButton } from '@components';
import { MAIN_THEME_DATA } from '@configs';
import styled from 'styled-components';
interface LoginFailModalProps {
  opened: boolean;
  onConfirm: () => void;
  onClose: () => void;
}
export const LoginFailModal = (props: LoginFailModalProps) => {
  const { opened, onClose, onConfirm } = props;
  return (
    <AppModal open={opened}>
      <StyledInnerModal>
        <span className="modal-title">Thông báo</span>
        <div>
          <span className="modal-message">
            Tài khoản của bạn chưa được kích hoạt do hồ sơ chưa hợp lệ. Vui lòng cập nhật lại hồ sơ
          </span>
          <div className="btn-block">
            <SharedButton text="Đóng" onClick={onClose} btnStyle="line" />
            <SharedButton
              onClick={onConfirm}
              className="border-red"
              text="Cập nhật hồ sơ"
              backgroundColor={MAIN_THEME_DATA.mainColor}
              btnStyle="pad"
            />
          </div>
        </div>
      </StyledInnerModal>
    </AppModal>
  );
};
const StyledInnerModal = styled.div`
  @media (max-width: 768px) {
    width: unset;
  }
  width: 60rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  span {
    display: inline-block;
  }
  .modal-message {
    text-align: center;
  }
  .modal-title {
    font-size: 2.4rem;
    @media (max-width: 768px) {
      font-size: 1.7rem;
    }
    font-family: Roboto-Medium;
    text-align: center;
  }
  .btn-block {
    display: flex;
    margin-top: 2rem;
    width: 100%;
    justify-content: space-around;
    .btn {
      width: 40%;
      padding: 1.4rem 1.6rem;
      .text-btn {
        @media (max-width: 768px) {
          font-size: 1.4rem;
        }
      }
    }
    .border-red {
      border: 1px solid ${MAIN_THEME_DATA.mainColor};
    }
  }
`;
