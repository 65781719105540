import { Layout } from 'antd';
import styled, { css } from 'styled-components';

import { enumThemeMode, HEADER_HEIGHT, MOBILE_FOOTER_HEIGHT, MOBILE_HEADER_HEIGHT } from '@configs';
import { IThemesData } from '@interfaces';

const { Footer } = Layout;

export const StyledMobileFooter = styled((props: any) => <Footer {...props} />)<{
  $appTheme?: string;
  $themeData?: IThemesData;
  $isAuth?: boolean;
}>`
  box-shadow: 0 0 11px rgb(0 0 0 / 13%);
  transition: 0.2s;
  bottom: 0;
  left: 0;
  height: ${MOBILE_FOOTER_HEIGHT};
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  position: fixed;
  width: 100%;
  z-index: 100;
  align-items: center;
  padding: 0 0;
`;
export const StyledWebFooter = styled((props: any) => <Footer {...props} />)<{
  $appTheme?: string;
  $themeData?: IThemesData;
  $isAuth?: boolean;
}>`
  background-color: white;
  width: 100%;
  padding: 0;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-top: 4rem;
  /* align-items: center; */
  .content-block {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 7.4rem;
  }
  .copy-right-block {
    background-color: #d20c00;
    padding: 1.6rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .title {
      color: white;
      font-size: 1.7rem;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 23.8px */
    }
    a {
      text-decoration: underline;
    }
  }
`;
