import { AppProfileForm, EditProfileForm } from '@components';
import { yupResolver } from '@hookform/resolvers/yup';
import { AddressItem, WarehouseItem, SellerItem } from '@interfaces';
import { selectUser, useAppDispatch, useAppSelector } from '@redux';
import { LogApp, useAddressSelector, useMediaQuery } from '@utils';
import { RegisterOptions, UseFormRegisterReturn, useForm } from 'react-hook-form';
import { Navigate, useNavigate } from 'react-router-dom';
import { schema } from './ProfileModule';
import { PATH_HOME } from '@configs';

export const EditProfileModule = () => {
  const userState = useAppSelector(selectUser);
  const avatar = userState?.avatar;
  const userInfo = userState?.userInfo;
  const isMobile = useMediaQuery('(max-width: 768px)');
  if (!isMobile) return <Navigate to={PATH_HOME} />;
  return <AppProfileForm avatar={avatar} userInfo={userInfo} />;
};
