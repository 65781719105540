import { MAIN_THEME_DATA, enumNavKey } from '@configs';
import { FooterContactItem } from '@interfaces';
// import { FooterContactItem } from '@interfaces';
import {
  CartIcon,
  CategoryIcon,
  HomeIcon,
  OrderIcon,
  PhoneIcon,
  UserIcon,
} from 'src/components/Icon';
import styled from 'styled-components';
interface FooterColProps {
  title: string;
  colData: FooterContactItem[];
  isContact?: boolean;
  textSize?: number;
}
export const FooterCol = ({
  colData,
  title,
  isContact = false,
  textSize = 1.4,
}: FooterColProps) => {
  return (
    <StyledFooterCol>
      <h2>{title}</h2>
      {colData.map((item) => {
        const Icon = item?.icon;
        return (
          <StyledContactItem key={item.id} textSize={textSize}>
            {Icon && <Icon />}
            {item.link !== '' ? (
              <a target="_blank" href={item.link}>
                {item.title}
              </a>
            ) : (
              <span>{item.title}</span>
            )}
          </StyledContactItem>
        );
      })}
    </StyledFooterCol>
  );
};
const StyledFooterCol = styled.div`
  h2 {
    font-size: 2.4rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 33.6px */
    margin-bottom: 1.6rem;
  }
  .social-block {
    display: flex;
    flex-direction: row;
    margin-top: 4rem;
    .social-item {
      margin-right: 1.6rem;
    }
  }
`;
const StyledContactItem = styled.div<{ textSize?: number }>`
  display: flex;
  flex-direction: row;
  margin-bottom: 1.8rem;
  a {
    font-size: ${(p) => p?.textSize && `${p.textSize}rem`};
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    margin-left: 1.2rem;
    color: #000;
  }
  span {
    font-size: ${(p) => p?.textSize && `${p.textSize}rem`};
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    margin-left: 1.2rem;
    color: #000;
  }
`;
