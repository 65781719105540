import { Layout } from 'antd';
import styled from 'styled-components';

import {
  MAIN_THEME_DATA,
  MOBILE_FOOTER_HEIGHT,
  MOBILE_HEADER_HEIGHT,
  PATH_SIGN_UP,
  PATH_UPDATE_PROFILE,
  PATH_VERIFY_OTP,
} from '@configs';
import { ILayout } from '@interfaces';
import { AuthHeaderModule, InnerAppModule } from '@modules';
import { selectApp, selectAuth, selectLayout, useAppSelector } from '@redux';
import { useLayoutEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Image } from 'antd';
import { IMAGES } from '@assets';
export const NO_LOGO_PATHS = [PATH_VERIFY_OTP, PATH_SIGN_UP, PATH_UPDATE_PROFILE];
export const MobileAuthLayout = (props: ILayout) => {
  const { children } = props;
  const navigate = useNavigate();
  const { sidebarCollapsed } = useAppSelector(selectLayout);

  const { merchantId } = useAppSelector(selectApp);
  const { accessToken } = useAppSelector(selectAuth);
  const location = useLocation();
  useLayoutEffect(() => {}, [merchantId]);
  return (
    <StyledMobileAuthLayout className="main-layout">
      <AuthHeaderModule />
      <Layout className="content-layout">
        {/* <SidebarModule /> */}
        <StyledPageInner>
          {!NO_LOGO_PATHS.includes(location.pathname) && (
            <Image className="logo" preview={false} src={IMAGES.color_logo} />
          )}
          <InnerAppModule {...props}>{children}</InnerAppModule>
        </StyledPageInner>
      </Layout>
    </StyledMobileAuthLayout>
  );
};

const StyledMobileAuthLayout = styled((props: any) => <Layout {...props} />)`
  min-height: 100vh;
  .content-layout {
    max-width: 50rem;
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    .ant-image {
      display: flex;
      justify-content: center;
      margin-bottom: 4rem;
    }
    .logo {
      width: 13.6rem;
      aspect-ratio: 2.125;
    }
  }
  background: #fff;
`;

const StyledPageInner = styled.div`
  background-color: ${MAIN_THEME_DATA.bgColor};
  transition: all 0.3s;
  padding-top: calc(${MOBILE_HEADER_HEIGHT} + 1.6rem);
  padding-bottom: ${MOBILE_FOOTER_HEIGHT};
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
`;
