//@ts-nocheck
import { MAIN_THEME_DATA } from '@configs';
import { Category, SubCategory } from '@interfaces';
import { List, Tabs } from 'antd';
import { styled } from 'styled-components';
import { TabItem } from './TabItem';

interface CategorySectionProps {
  childTabItems: SubCategory[];
  defaultActiveKey?: string;
  navigateToCategoryDetail: (id: string, val: SubCategory) => void;
  onChangeSubCategory: (key: string) => void;
  category: Category[];
  tabItems: Array<{ key: string; label: string }>;
}
export const MobileCategoryListSection = (props: CategorySectionProps) => {
  const {
    navigateToCategoryDetail,
    category,
    tabItems,
    defaultActiveKey,
    childTabItems,
    onChangeSubCategory,
  } = props;
  return (
    <StyledMobileCategorySection>
      <div className="main-block">
        <Tabs
          tabPosition="left"
          tabBarStyle={{
            borderBottomWidth: 1,
            borderBottomColor: '#D9D9D9',
          }}
          onChange={(key) => {
            onChangeSubCategory(key);
          }}
          activeKey={defaultActiveKey}
          items={tabItems.map((item, index) => {
            return {
              label: (
                <TabItem
                  image={item.image}
                  title={item.label}
                  isSelected={defaultActiveKey === item.key}
                />
              ),
              key: item.key,
              children: (
                <List
                  grid={{
                    xs: 3,
                  }}
                  dataSource={childTabItems}
                  renderItem={(val: SubCategory) => (
                    <List.Item>
                      <TabItem
                        onClick={() => {
                          navigateToCategoryDetail(item.key, val);
                          onChangeSubCategory(val.id);
                        }}
                        title={val.name}
                      />
                    </List.Item>
                  )}
                />
              ),
            };
          })}
        />
      </div>
    </StyledMobileCategorySection>
  );
};
const StyledMobileCategorySection = styled.div`
  margin-top: -16px;
  .ant-tabs-nav {
    border-bottom-width: 0 !important;
  }
  .ant-tabs-tabpane {
    padding: 1.2rem !important;
  }
  .ant-tabs-tab-active {
    background-color: ${MAIN_THEME_DATA.bgColor};
  }
  .main-block {
    h2 {
      //styleName: Size 24/Medium;
      font-family: Roboto-Medium;
      font-size: 2.4rem;
      font-weight: 500;
      line-height: 34px;
    }
    .ant-tabs-tab {
      justify-content: center;
      padding: 1.2rem;
    }
    .ant-tabs-tab-btn {
      color: ${MAIN_THEME_DATA.mainText};
      font-size: 1.7rem;
      font-family: Roboto-Regular;
      font-weight: 400;
      line-height: 23.8px;
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: ${MAIN_THEME_DATA.mainColor};
      font-size: 1.7rem;
      font-family: Roboto-Medium;
      font-weight: 500;
      line-height: 23.8px;
    }
    .ant-tabs-ink-bar {
      background: ${MAIN_THEME_DATA.mainColor};
      height: 3px;
    }
    .product-footer-item {
      text-align: left;
      font-family: Roboto-Regular;
      font-size: 1.2rem;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 16.8px */
      color: ${MAIN_THEME_DATA.mainText};
    }
  }
`;
