import { useMediaQuery } from '@utils';
import { WebHomeList } from './web';
import { MobileHomeList } from './mobile';
import { NewArrivalProduct, ProductsHotSelling, Promotion } from '@interfaces';
interface HomeListProps {
  title: string;
  data: ProductsHotSelling[];
  footerClassNameItem?: string;
  hasPrice?: boolean;
  isLoading?: boolean;
  onItemClick: (item: ProductsHotSelling) => void;
}
export const HomeList = (props: HomeListProps) => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  return isMobile ? <MobileHomeList {...props} /> : <WebHomeList {...props} />;
};
