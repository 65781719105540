import { MAIN_THEME_DATA } from '@configs';
import {
  AddressItem,
  AuthAutocompleteData,
  SellerItem,
  UserInfo,
  WarehouseItem,
} from '@interfaces';
import { useMediaQuery } from '@utils';
import { FieldErrorsImpl, UseFormRegister, UseFormWatch } from 'react-hook-form';
import styled from 'styled-components';
import { AppProfileForm } from './app/AppProfileForm';
import { WebProfileForm } from './web/WebProfileForm';
interface IProfileForm {
  selectedId: number;
  watch: UseFormWatch<any>;
  register: UseFormRegister<any>;
  handleChangePassword: any;
  errors: Partial<FieldErrorsImpl<any>>;
  userInfo: UserInfo;
  avatar: string;
}
export const ProfileForm = (props: IProfileForm) => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  if (isMobile) return <AppProfileForm {...props} />;
  return <WebProfileForm {...props} />;
};
