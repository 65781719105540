import { GetOrderParams, Order, OrderData } from '@interfaces';
import { useMediaQuery } from '@utils';
import { BaseSyntheticEvent } from 'react';
import { FieldErrorsImpl, UseFormRegister } from 'react-hook-form';
import { AppOrderSection } from './mobile/AppOrderSection';
import { WebOrderSection } from './web/WebOrderSection';
interface OrderSectionProps {
  orders: OrderData;
  onChangeStatus: (status: string) => void;
  navigateToOrderDetail: (id: string) => void;
  onPageChange: (page: number) => void;
  onDateChange: (date: string[]) => void;
  onRateChange: (start: number) => void;
  isLoading: boolean;
  payload: GetOrderParams;
  onReview: (order: Order) => void;
  modalData: { opened: boolean; orderItem: Order };
  rating: number;
  errors: Partial<FieldErrorsImpl<any>>;
  register: UseFormRegister<any>;
  onClose: () => void;
  submitReview: (e?: BaseSyntheticEvent<any, any, any> | undefined) => Promise<void>;
  onUpdateOrder: (id: string) => Promise<void>;
  orderCount: any;
}
export const OrderSection = (props: OrderSectionProps) => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  if (isMobile) return <AppOrderSection {...props} />;
  return <WebOrderSection {...props} />;
};
