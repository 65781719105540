import { ProductFilterData } from '@interfaces';
import { useMediaQuery } from '@utils';
import { MobileProductSearchResultSection } from './mobile/MobileSearchResult';
import { WebProductSearchResultSection } from './web/WebProductSearchResult';
interface ProductSearchResultProps {
  products: ProductFilterData;
  defaultActiveKey?: string;
  navigateToProductDetail: (id: string) => void;
  isLoading: boolean;
  keyword: string;
  onPageChange: (page: number) => void;
  payload: any;
}
export const ProductSearchResultSection = (props: ProductSearchResultProps) => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  if (isMobile) return <MobileProductSearchResultSection {...props} />;
  return <WebProductSearchResultSection {...props} />;
};
