import { categoryAPI, homeAPI } from '@api';
import { CategorySection, MobileCategorySection } from '@components';
import { PATH_PRODUCT } from '@configs';
import {
  Category,
  CategoryAttributes,
  CategoryDetailRoot,
  ProductFilterData,
  ProductFilterRoot,
  RootResponse,
} from '@interfaces';
import { selectAuth, useAppDispatch, useAppSelector } from '@redux';
import { useMediaQuery } from '@utils';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { selectHome, setCurrentChildCategory } from 'src/redux/Slices/home';
import { useImmer } from 'use-immer';
const MAX_ITEM_PER_PAGE = 25;
const MAX_PRICE = 100000000;
export const CategoryModule = () => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const dispatch = useAppDispatch();
  const { privateId } = useAppSelector(selectAuth);
  const { currentWarehouse } = useAppSelector(selectHome);
  // const { userId, warehouseId } = privateId;
  const warehouseId = currentWarehouse.id;
  const [payload, setPayload] = useImmer({
    page: 0,
  });
  const [category, setCategory] = useState<Category>({
    id: '',
    name: '',
    image: null,
    sub_categories: [],
  });
  const [products, setProducts] = useState<ProductFilterData>({
    limit: MAX_ITEM_PER_PAGE,
    count: 0,
    data: [],
    brands: [],
    suppliers: [],
  });
  const { currentChildCategory } = useAppSelector(selectHome);
  const [filters, setFilters] = useImmer<{
    brand: string | null;
    supplier: string | null;
    min_price: number;
    max_price: number;
  }>({
    brand: null,
    supplier: null,
    min_price: 0,
    max_price: MAX_PRICE,
  });
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const { id } = useParams();
  const navigateToProductDetail = (id: string) => {
    navigate(PATH_PRODUCT + `/${id}`);
  };
  const getCategory = async () => {
    try {
      const res: CategoryDetailRoot = await categoryAPI.getCategory(id || '');
      setCategory(res.data);
    } catch (error) {}
  };
  const getProductBySubCategory = async () => {
    setIsLoading(true);
    try {
      const res: ProductFilterRoot = await homeAPI.getProductByFilter({
        category_ids: [currentChildCategory.id],
        page: payload.page,
        warehouse_id: warehouseId,
        size: MAX_ITEM_PER_PAGE,
        supplier_name: filters.supplier,
        brand_name: filters.brand,
        max_price: filters.max_price,
        min_price: filters.min_price,
      });
      const attributes: RootResponse<CategoryAttributes> = await categoryAPI.getAttributes({
        category_ids: [currentChildCategory.id],
        page: payload.page,
        warehouse_id: warehouseId,
        size: MAX_ITEM_PER_PAGE,
        supplier_name: filters.supplier,
        brand_name: filters.brand,
        max_price: filters.max_price,
        min_price: filters.min_price,
      });
      setProducts({
        data: res.data.products,
        limit: res.data.amount,
        count: MAX_ITEM_PER_PAGE,
        brands: [
          { value: 'all', label: 'Tất cả' },
          ...attributes.data.brands.map((item) => {
            return {
              value: item,
              label: item,
            };
          }),
        ],
        suppliers: [
          { value: 'all', label: 'Tất cả' },
          ...attributes.data.suppliers.map((item) => {
            return {
              value: item,
              label: item,
            };
          }),
        ],
      });
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  const getTabItems = () => {
    return [
      { key: category.id, label: 'Tất cả' },
      ...category.sub_categories.map((item) => {
        return {
          key: item.id,
          label: item.name,
        };
      }),
    ];
  };
  const onChangeSubCategory = (key: string) => {
    const foundIndex = category.sub_categories.findIndex((item) => item.id === key);
    if (foundIndex !== -1) dispatch(setCurrentChildCategory(category.sub_categories[foundIndex]));
    else if (category.id === key) dispatch(setCurrentChildCategory(category));
  };
  const onPageChange = (page: number) => {
    setPayload((draft) => {
      draft.page = page;
    });
  };
  const onFilterBrand = (value: string) => {
    setFilters((d) => {
      if (value === 'all') d.brand = null;
      else d.brand = value;
    });
  };
  const onFilterSupplier = (value: string) => {
    setFilters((d) => {
      if (value === 'all') d.supplier = null;
      else d.supplier = value;
    });
  };
  const onFilterPrice = (value: number[]) => {
    setFilters((d) => {
      d.min_price = value[0];
      d.max_price = value[1];
    });
  };
  useEffect(() => {
    getCategory();
  }, [id]);
  useEffect(() => {
    if (currentChildCategory) getProductBySubCategory();
  }, [currentChildCategory, payload.page, currentWarehouse.id, filters.brand, filters.supplier]);
  if (isMobile)
    return (
      <MobileCategorySection
        isLoading={isLoading}
        products={products}
        defaultActiveKey={currentChildCategory.id}
        tabItems={getTabItems()}
        category={category}
        navigateToProductDetail={navigateToProductDetail}
        onChangeSubCategory={onChangeSubCategory}
        onPageChange={onPageChange}
        payload={payload}
        onFilterBrand={onFilterBrand}
        onFilterSupplier={onFilterSupplier}
        onFilterPrice={onFilterPrice}
        getProductBySubCategory={getProductBySubCategory}
        filters={filters}
      />
    );
  return (
    <CategorySection
      isLoading={isLoading}
      products={products}
      defaultActiveKey={currentChildCategory.id}
      tabItems={getTabItems()}
      category={category}
      navigateToProductDetail={navigateToProductDetail}
      onChangeSubCategory={onChangeSubCategory}
      onPageChange={onPageChange}
      payload={payload}
      onFilterBrand={onFilterBrand}
      onFilterSupplier={onFilterSupplier}
      onFilterPrice={onFilterPrice}
      getProductBySubCategory={getProductBySubCategory}
      filters={filters}
    />
  );
};
