import { Layout } from 'antd';
import styled from 'styled-components';

import { ILayout } from '@interfaces';
import { FooterModule, HeaderModule, InnerAppModule, SidebarModule } from '@modules';
import {
  HEADER_HEIGHT,
  HEADER_PADDING_TOP,
  MAIN_THEME_DATA,
  PATH_404,
  PATH_CATEGORY,
  PATH_PRODUCT,
  PATH_PRODUCT_DETAIL,
  PATH_PROFILE,
  SIDEBAR_COLLAPSED_WIDTH,
  SIDEBAR_WIDTH,
} from '@configs';
import { selectApp, selectLayout, useAppSelector } from '@redux';
import { LogApp, generateRandomId } from '@utils';
import { useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { selectHome } from 'src/redux/Slices/home';
const HAS_BREADCRUMB = [PATH_CATEGORY, PATH_PRODUCT];
const NO_PADDING_BOTTOM = [PATH_PROFILE];
export const WebLayout = (props: ILayout) => {
  const { children } = props;
  const navigate = useNavigate();
  const { sidebarCollapsed } = useAppSelector(selectLayout);
  const { merchantId, currentPath } = useAppSelector(selectApp);
  LogApp(currentPath, 'currentPath');
  const { currentChildCategory, currentParentCategory, currentProduct } =
    useAppSelector(selectHome);
  const getPageHasBreadCrumb = () => {
    return HAS_BREADCRUMB.some((item) => currentPath?.includes(item));
  };
  const hasBreadcrumb = getPageHasBreadCrumb();
  const breadCrumb = [
    currentParentCategory?.name || 'Không có danh mục',
    currentChildCategory?.name || 'Không có danh mục',
    currentProduct.name,
  ];
  return (
    <StyledWebLayout className="main-layout">
      <HeaderModule />
      {/* {hasBreadcrumb && (
        <StyledBreadCrumb>
          <div className="bread-crumb-wrapper">
            {breadCrumb.map((item, index) => {
              if (index === 2 && !currentPath.includes(PATH_PRODUCT)) return <></>;
              return (
                <div key={generateRandomId()} className="bread-crumb-item">
                  {index !== 0 && <span className="slash">/</span>}
                  <span className={`${index === breadCrumb.length - 1 ? 'red' : ''}`}>{item}</span>
                </div>
              );
            })}
          </div>
        </StyledBreadCrumb>
      )} */}
      <Layout className="content-layout">
        {/* <SidebarModule /> */}
        <StyledPageInner hasBreadcrumb={hasBreadcrumb} sidebarCollapsed={sidebarCollapsed}>
          <InnerAppModule {...props}>{children}</InnerAppModule>
        </StyledPageInner>
      </Layout>
      <FooterModule />
    </StyledWebLayout>
  );
};

const StyledWebLayout = styled((props: any) => <Layout {...props} />)`
  min-height: 100vh;
  .ant-layout-header {
    line-height: unset;
  }
  .content-layout {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    background-color: #fff4f3;
  }
`;

const StyledPageInner = styled.div<{
  sidebarCollapsed?: boolean;
  backgroundColor?: string;
  backgroundColor2?: string;
  hasBreadcrumb?: boolean;
  layoutNoPadding?: boolean;
}>`
  /* background-color: ${(p: any) => p?.theme.colors?.bgPage}; */
  transition: all 0.3s;
  /* margin-left: ${(p) => (p.sidebarCollapsed ? SIDEBAR_COLLAPSED_WIDTH : SIDEBAR_WIDTH)}; */
  ${(props) => {
    return !props?.hasBreadcrumb
      ? `padding-top: calc(${HEADER_HEIGHT} + 4rem);`
      : `padding-top: ${HEADER_HEIGHT};`;
  }}
  padding-bottom: 8.2rem;
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1;
`;
const StyledBreadCrumb = styled.div`
  padding: 1rem 0;
  background-color: #ffd0cd;
  margin-top: ${HEADER_HEIGHT};
  padding-left: 12rem;
  .bread-crumb-wrapper {
    display: flex;
    .red {
      color: ${MAIN_THEME_DATA.mainColor} !important;
    }
    .slash {
      margin: 0 0.8rem;
    }
    .bread-crumb-item {
      display: flex;
      span {
        color: ${MAIN_THEME_DATA.mainText};
        font-size: 1.4rem;
        font-family: Roboto-Regular;
        font-weight: 400;
        line-height: 19.6px;
        display: inline-block;
      }
    }
  }
`;
