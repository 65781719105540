import { MAIN_THEME_DATA, ORDER_STATUS, ORDER_STATUS_NUMBER, ORDER_STATUS_TEXT } from '@configs';
import { Order } from '@interfaces';
import { LogApp, toVND } from '@utils';
import moment from 'moment';
import Skeleton from 'react-loading-skeleton';
import { styled } from 'styled-components';
import { IConArrowLeft } from '../../Icon';
import { ProductItemList } from '../components/ProductItemList';
import { BillLine } from '../../cart/components/BillLine';
import { LoadingCartItem } from '../../cart/components/LoadingCart';
import Stepper from '../../cart/components/steps';
import { LABEL_POSITION, STEP_STATUSES } from '../../cart/components/steps/constants';
import { IStep } from '../../cart/components/steps/stepper-component/types';
import { HeadLoading, AddressLoading, BillLoading } from '../components/CommonLoading';
import { DEFAULT_TIMELINE } from '../OrderDetail';
import { PopoverPopup, SharedButton } from '@components';
interface OrderDetailSectionProps {
  order: Order;
  goBack: () => void;
  getOrderAddress: () => string;
  orderTimeline: IStep[];
  isLoading: boolean;
  getTotalDeductPercent: () => string;
  getDiscountPercent: (index: number) => number;
  calculateTotalPrice: () => number;
  cancelOrder: () => void;
}

export const WebOrderDetailSection = (props: OrderDetailSectionProps) => {
  const {
    order,
    goBack,
    getOrderAddress,
    orderTimeline,
    isLoading,
    getTotalDeductPercent,
    getDiscountPercent,
    calculateTotalPrice,
    cancelOrder,
  } = props;
  return (
    <StyledOrderDetail>
      <div className="heading">
        <div className="flex">
          <IConArrowLeft onClick={goBack} strokeWidth={4} className="icon" width={24} height={24} />
          <h2 className="heading-title">Chi tiết đơn hàng</h2>
        </div>
        {order.status === ORDER_STATUS_NUMBER.WAITING_FOR_ACCEPT && (
          <div>
            <PopoverPopup
              content={
                <StyledConfirmPopup>
                  <p className="text-sm">Xác nhận huỷ đơn hàng này?</p>
                </StyledConfirmPopup>
              }
              isHaveConfirmButton
              onConfirm={cancelOrder}
            >
              <SharedButton className="btn" text="Huỷ đơn hàng" />
            </PopoverPopup>
          </div>
        )}
      </div>
      <div className="card">
        {isLoading ? (
          <LoadingCartItem hasBottomLine type="order" />
        ) : (
          <ProductItemList
            orderList={order.order_items}
            getDiscountPercent={(index) => getDiscountPercent(index)}
          />
        )}
      </div>
      <div className="card">
        {isLoading ? (
          <HeadLoading />
        ) : (
          <div className="bill-heading">
            <span className="text-med red-text">
              {order.code + ' - '}
              <span className="text-med date red-text">
                {moment(order.created_at).format('DD/MM/yyyy')}
              </span>
            </span>
            <span className="order-status">{ORDER_STATUS_TEXT[order.status].toUpperCase()}</span>
          </div>
        )}
        <div className="bill-content">
          <div className="address">
            <span className="item-title">Địa chỉ nhận hàng</span>
            {isLoading ? (
              <AddressLoading />
            ) : (
              <>
                <span className="text-17">{order.customer.customer_name}</span>
                <span className="">{order.customer.customer_phone_number}</span>
                <span className="">{getOrderAddress()}</span>
                <span className="">Ghi chú: {order.note}</span>
              </>
            )}
          </div>
          <div className="bill">
            <span className="item-title">Tóm tắt đơn hàng</span>
            <div className="bill-lines">
              {isLoading ? (
                <BillLoading />
              ) : (
                <>
                  <BillLine
                    className="mb-8"
                    title="Tạm tính"
                    content={toVND(calculateTotalPrice())}
                  />
                  <BillLine
                    className="mb-8"
                    title="Tổng chiết khấu"
                    content={getTotalDeductPercent()}
                  />
                  <BillLine
                    className="mb-8 border-bt"
                    title="Phí vận chuyển"
                    content={toVND(order.money_addon)}
                  />
                  <BillLine
                    titleTextClassName="text-med"
                    priceTextClassName="text-17 text-med"
                    className="mb-8"
                    title="Tổng tiền"
                    content={toVND(order.total_money + order.money_addon)}
                  />
                  {!!order.money_debt && (
                    <BillLine
                      titleTextClassName="text-med"
                      priceTextClassName="text-17 text-med red-text"
                      className="mb-8"
                      title="Còn nợ"
                      content={toVND(order.money_debt)}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="stepper-heading">
          <span className="text-med text-17">Theo dõi đơn hàng</span>
        </div>
        <div className="stepper">
          <Stepper
            isLoading={isLoading}
            steps={isLoading ? DEFAULT_TIMELINE : orderTimeline}
            currentStepIndex={0}
            labelPosition={LABEL_POSITION.RIGHT}
          />
        </div>
      </div>
    </StyledOrderDetail>
  );
};
const StyledConfirmPopup = styled.div`
  .btn {
    padding: 0.4rem 0.3rem;
  }
`;
const StyledOrderSection = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 12rem;
  .btn-block {
    display: flex;
    width: 100%;
    margin-top: 2.4rem;
    .mr {
      margin-right: 2.4rem;
    }
  }
  .m-top-26 {
    margin-top: 2.6rem;
  }
  .check-box-text {
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }
  .ant-select {
    margin-bottom: 1rem;
  }
  .ant-select-selection-placeholder {
    font-size: 1.4rem !important;
  }
  .inner-input {
    height: 4rem !important;
    font-size: 1.4rem !important;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0;
  }
  .m-top-24 {
    margin-top: 2.4rem;
  }
  .m-bot-16 {
    margin-bottom: 1.6rem;
  }
  .header-block {
    display: flex;
    padding: 0 12rem;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .title {
      /* Size 32/Medium */
      color: ${MAIN_THEME_DATA.mainText};
      font-family: Roboto-Medium;
      font-size: 3.2rem;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 44.8px */
    }
  }
  .content-block {
    margin-top: 3.2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    .step-block {
      width: calc(66%);
      background-color: #d20c00;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      padding: 1.6rem 8.9rem;
    }
    .form-block {
      width: calc(66%);
      border-radius: 0px 0px 12px 12px;
      box-shadow: 0px 1px 16px 0px rgba(56, 56, 56, 0.15);
      background-color: white;
      padding: 2.4rem;
      .title {
        font-family: Roboto-Medium;
        font-size: 1.7rem;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        margin-bottom: 1.6rem;
      }
    }
  }
`;
const StyledOrderDetail = styled.div`
  .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
    background: ${MAIN_THEME_DATA.mainColor};
  }
  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail:after {
    background: #888;
  }
  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-tail:after {
    background: #888;
  }
  .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-tail:after {
    background: #888;
  }
  /* .ant-steps-item-title:after {
    left: unset;
    top: 30px;
  }
  .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:nth-child(3) {
    padding-left: 0;
  }

  .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:nth-child(4) {
    padding-left: 0;
  } */
  .ant-steps {
  }
  .text-med {
    font-family: Roboto-Medium;
  }
  .text-17 {
    font-size: 1.7rem;
  }
  .mb-8 {
    margin-bottom: 0.8rem;
  }
  .border-bt {
    border-bottom: 1px solid #d9d9d9;
    padding-bottom: 0.8rem;
  }
  span {
    /* display: inline-block; */
  }
  padding: 0 12rem;
  .heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .btn {
      padding: 1.5rem 1.5rem;
    }
    .heading-title {
      font-family: Roboto-Medium;
      font-size: 2.4rem;
      font-weight: 500;
      line-height: 140%;
    }
    .icon {
      cursor: pointer;
      align-self: center;
      margin-right: 1rem;
    }
  }
  .card {
    margin-top: 2.4rem;
    background-color: white;
    border-radius: 16px;
    padding: 1.2rem 1.6rem 2rem 1.6rem;
  }
  .stepper-heading {
    padding-bottom: 0.8rem;
    border-bottom: 1px solid #d9d9d9;
    margin-bottom: 1.2rem;
  }
  .bill-heading {
    display: flex;
    justify-content: space-between;
    padding-bottom: 0.8rem;
    border-bottom: 1px solid #d9d9d9;
    .order-status {
      color: ${MAIN_THEME_DATA.mainColor};
    }
  }
  .bill-content {
    margin-top: 0.8rem;
    display: flex;
    justify-content: space-between;
    .address {
      width: 50%;
      display: flex;
      flex-direction: column;
    }
    .bill {
      width: 50%;
      .bill-lines {
        margin-top: 0.8rem;
      }
    }
    .item-title {
      font-family: Roboto-Medium;
      font-size: 1.7rem;
      font-weight: 500;
      line-height: 140%;
    }
  }
  .red-text {
    color: ${MAIN_THEME_DATA.mainColor};
  }
`;
