import { Divider } from 'antd';
import React, { forwardRef } from 'react';

import {
  AUTH_HEADER_TITLE,
  enumThemeMode,
  MAIN_THEME_DATA,
  MAT_SM_SCREEN_WIDTH,
  MOBILE_AUTH_HEADER_HEIGHT,
  MOBILE_HEADER_HEIGHT,
  PATH_FORGOT_PASSWORD,
  PATH_LOGIN,
  PATH_RESET_PASSWORD,
  PATH_VERIFY_OTP,
} from '@configs';
import { BreadcrumbsModule, MenuDropdownModule } from '@modules';
import {
  CartIcon,
  CategoryIcon,
  HeaderMenuIcon,
  IConArrowLeft,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  SearchIcon,
  UserHeaderIcon,
} from '../../Icon';
import { SharedDropdown, SharedImage, ShareInput } from '../../shared';
import { StyledMobileHeader } from '../styles';
import { useMediaQuery } from '@utils';
import { selectApp, useAppSelector } from '@redux';
import { IMAGES } from '@assets';
import { styled } from 'styled-components';
import { Header } from 'antd/lib/layout/layout';
import { useLocation, useNavigate } from 'react-router-dom';

interface IProps {
  isAuth?: boolean;
}
const BACK_TO_LOGIN = [PATH_RESET_PASSWORD, PATH_VERIFY_OTP, PATH_FORGOT_PASSWORD];
export const MobileAuthHeader = (props: IProps) => {
  const { isAuth } = props;
  const { themeData } = useAppSelector(selectApp);
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <StyledMobileAuthHeader>
      {location.pathname !== PATH_LOGIN && (
        <IConArrowLeft
          onClick={() => {
            if (BACK_TO_LOGIN.includes(location.pathname)) navigate(PATH_LOGIN);
            else navigate(-1);
          }}
          className="icon"
          color="white"
          width={24}
          height={24}
        />
      )}
      {/*@ts-ignore*/}
      <h2 className="intro-x text-center heading-title">{AUTH_HEADER_TITLE[location.pathname]}</h2>
    </StyledMobileAuthHeader>
  );
};
export const StyledMobileAuthHeader = styled((props: any) => <Header {...props} />)`
  transition: 0.2s;
  top: 0;
  left: 0;
  height: ${MOBILE_AUTH_HEADER_HEIGHT};
  background-color: ${MAIN_THEME_DATA.mainColor};
  display: flex;
  position: fixed;
  width: 100%;
  z-index: 100;
  align-items: flex-end;
  justify-content: center;
  padding: 0 0 1.6rem 0;
  .icon {
    position: absolute;
    bottom: 1.6rem;
    left: 1.6rem;
    cursor: pointer;
  }
  h2 {
    font-size: 1.7rem;
    color: white;
    font-weight: 500;
    font-family: Roboto-Medium;
  }
`;
