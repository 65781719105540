import {
  Classification,
  ProductDetailResponse,
  ProductFilter,
  ProductsHotSelling,
  SubClassification,
} from '@interfaces';
import { useMediaQuery } from '@utils';
import { AppProductSection } from './mobile/AppProduct';
import { WebProductSection } from './web/WebProduct';
interface ProductSectionProps {
  error: string;
  product: ProductDetailResponse;
  onSelectClassification: (classification: Classification) => void;
  onSelectSubClassification: (classification: SubClassification) => void;
  selectedClassification: Classification;
  selectedSubClassification: SubClassification;
  productPrice: string;
  quantity: number;
  increaseProductQuantity: () => void;
  decreaseProductQuantity: () => void;
  onAddToCart: () => void;
  onBuyNow: () => void;
  isLoading: boolean;
  sameProducts: ProductFilter[];
  onItemClick: (item: ProductsHotSelling) => void;
  onChangeQuantity: (quantity: number) => void;
  onModalInteract: (value: boolean) => void;
  getAttribute: () => string;
  modalData: { opened: boolean; product: ProductDetailResponse };
  isNotFound: boolean;
  onInteractAddProductModal: (value: boolean) => void;
  modalOpened: boolean;
  galleryRef: any;
  getProductDeduction: () => number;
}
export const ProductSection = (props: ProductSectionProps) => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  if (isMobile) return <AppProductSection {...props} />;
  return <WebProductSection {...props} />;
};
