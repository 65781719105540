import { SharedButton, Trash } from '@components';
import { generateRandomId } from '@utils';
import Skeleton from 'react-loading-skeleton';
import { styled } from 'styled-components';

export const BannerLoading = () => {
  return (
    <StyledBannerLoading>
      <Skeleton width="100%" height="100%" />
    </StyledBannerLoading>
  );
};
const StyledBannerLoading = styled.div`
  border-radius: 16px;
  width: calc((5 * 100%) / 6);
  aspect-ratio: 1200 / 536;
  @media (max-width: 768px) {
    width: calc(100% - 3.2rem);
    aspect-ratio: 358/160;
  }
  .react-loading-skeleton {
    border-radius: 16px;
  }
`;
