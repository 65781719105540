import { AppModal, OrderSuccess, SharedButton } from '@components';
import { MAIN_THEME_DATA } from '@configs';
import { useMediaQuery } from '@utils';
import Countdown from 'antd/lib/statistic/Countdown';
import moment from 'moment';
import React from 'react';
import { styled } from 'styled-components';

interface SignUpProps {
  opened?: boolean;
  redirectToLogin?: () => void;
}
export const SignUpModal = (props: SignUpProps) => {
  const { opened, redirectToLogin } = props;
  const isMobile = useMediaQuery('(max-width: 768px)');
  return (
    <AppModal open={opened}>
      <StyledModalInner>
        <OrderSuccess width={isMobile ? 60 : 120} height={isMobile ? 60 : 120} />
        <h2 className="sign-up-success">Đăng kí thành công</h2>
        <span>Tài khoản của anh/chị đã được tạo. Vui lòng chờ quản trị viên phê duyệt</span>
        <div className="time-block">
          <span className="resend-after text">Trở về trang đăng nhập trong</span>
          {opened &&
            React.createElement(Countdown, {
              className: 'countdown-resendOTP',
              onFinish: () => redirectToLogin?.(),
              // value: Date.now() + 1000 * 60,
              value: moment().add(4, 's').valueOf(),
              format: 'mm:ss',
            })}
        </div>
        <div className="btn-block">
          <SharedButton onClick={redirectToLogin} className="mr" text="Quay về trang đăng nhập" />
        </div>
      </StyledModalInner>
    </AppModal>
  );
};
const StyledModalInner = styled.div`
  width: 80rem;
  @media (max-width: 768px) {
    width: unset;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .time-block {
    margin-top: 1rem;
    .text {
      font-size: 1.7rem;
      color: #888;
    }
    .ant-statistic-content-value {
      color: #888;
    }
    .ant-statistic-content {
      font-size: unset;
    }
    display: flex;
    align-items: flex-end;
    .resend-after {
      margin-right: 0.5rem;
    }
  }
  .sign-up-success {
    margin-top: 2.4rem;
    @media (max-width: 768px) {
      font-size: 1.7rem;
      margin: 1.6rem 0;
    }
    font-family: Roboto-Medium;
    color: #000;
    font-size: 2.4rem;
    font-weight: 500;
    line-height: 140%;
  }
  span {
    font-size: 1.7rem;
    @media (max-width: 768px) {
      font-size: 1.4rem;
    }
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    text-align: center;
  }
  .red-text {
    color: ${MAIN_THEME_DATA.mainColor};
  }
  .btn-block {
    display: flex;
    width: 30%;
    @media (max-width: 768px) {
      width: 100%;
    }
    margin-top: 2.4rem;
    .mr {
      margin-right: 2.4rem;
    }
  }
`;
