import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { authAPI } from '@api';
import { VerifyOTPForm } from '@components';
import { PATH_FORGOT_PASSWORD, PATH_LOGIN, PATH_RESET_PASSWORD } from '@configs';
import { selectAuth, setLoading, useAppDispatch, useAppSelector } from '@redux';
import { LogApp } from '@utils';
import { debounce } from 'lodash';
import { useLayoutEffect, useState } from 'react';
//@ts-ignore
import { NotificationManager } from 'react-notifications';
const schema = yup.object().shape({});
//@ts-ignore
export const VerifyOTPModule = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { verifyOTPHash, forgotUsername } = useAppSelector(selectAuth);
  const [otp, setOtp] = useState('');
  const [resendOTPCountdown, setResendOTPCountdown] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    reset,
    setError,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });
  const onChangeOtp = (code: string) => {
    setOtp(code);
  };
  useLayoutEffect(() => {
    if (!verifyOTPHash) {
      navigate(PATH_LOGIN);
    }
  }, []);
  const handleRedirectToForgot = () => {
    navigate(-1);
  };

  const checkMemberExits = (hash: string) => {
    LogApp('log');
  };

  const handleVerifyOTP = async () => {
    if (!otp) {
      NotificationManager.error('Thông báo', 'Vui lòng nhập mã xác minh');
      return;
    }
    try {
      dispatch(setLoading(true));
      const res = await authAPI.verifyOtp({ username: forgotUsername ?? '', otp });
      navigate(PATH_RESET_PASSWORD);
    } catch (error: any) {
      LogApp(error, 'err');
      NotificationManager.error('Thông báo', error.data.detail);
    } finally {
      dispatch(setLoading(false));
    }
  };
  const handleResendEmail = debounce(() => {
    if (!resendOTPCountdown) {
      handleSendOTP();
    } else {
      NotificationManager.error('Thông báo', 'Thử lại sau');
    }
  }, 500);

  const onEndResendOTPCountdown = () => {
    setResendOTPCountdown(false);
  };
  const handleSendOTP = async () => {
    try {
      dispatch(setLoading(true));
      const res = await authAPI.forgotPassword(forgotUsername ?? '');
      NotificationManager.success('Thông báo', 'Thành công');
    } catch (error: any) {
      NotificationManager.error('Thông báo', error.data.detail);
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <VerifyOTPForm
      forgotUsername={forgotUsername}
      countdown={resendOTPCountdown}
      register={register}
      redirectToForgot={handleRedirectToForgot}
      errors={errors}
      handleVerifyOTP={handleVerifyOTP}
      handleResendEmail={handleResendEmail}
      onEndResendOTPCountdown={onEndResendOTPCountdown}
      onChangeOtp={onChangeOtp}
      otp={otp}
    />
  );
};
