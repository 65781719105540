import { Layout } from 'antd';
import styled from 'styled-components';

import {
  MAIN_THEME_DATA,
  MOBILE_AUTH_HEADER_HEIGHT,
  MOBILE_FOOTER_HEIGHT,
  MOBILE_HEADER_HEIGHT,
  PATHS_NO_HEADER,
  PATH_CATEGORY,
  PATH_HOME,
  PATH_PRODUCT,
} from '@configs';
import { ILayout } from '@interfaces';
import { HeaderModule, InnerAppModule } from '@modules';
import { selectApp, selectLayout, useAppSelector } from '@redux';
import { LogApp, getPath } from '@utils';
import { useLayoutEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FooterModule } from 'src/modules/footer/Footer';
const PATHS_HAVE_SEARCH_INPUT = [PATH_HOME, PATH_PRODUCT, PATH_CATEGORY];

export const MobileLayout = (props: ILayout) => {
  const { children } = props;
  const navigate = useNavigate();
  const { sidebarCollapsed } = useAppSelector(selectLayout);

  const { merchantId } = useAppSelector(selectApp);
  const location = useLocation();
  useLayoutEffect(() => {}, [merchantId]);
  const noHeader = PATHS_NO_HEADER.includes(getPath(location.pathname));
  const haveSearchInput = PATHS_HAVE_SEARCH_INPUT.includes(getPath(location.pathname));

  return (
    <StyledMobileLayout className="main-layout">
      <HeaderModule />
      <Layout className="content-layout">
        {/* <SidebarModule /> */}
        <StyledPageInner $noHeader={noHeader} $haveSearchInput={haveSearchInput}>
          <InnerAppModule {...props}>{children}</InnerAppModule>
        </StyledPageInner>
      </Layout>
      <FooterModule />
    </StyledMobileLayout>
  );
};

const StyledMobileLayout = styled((props: any) => <Layout {...props} />)`
  min-height: 100vh;
  .ant-layout-header {
    line-height: unset;
  }
  .content-layout {
    max-width: 50rem;
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 0 auto;
  }
  background: #fff;
`;

const StyledPageInner = styled.div<{
  $noHeader?: boolean;
  $haveSearchInput?: boolean;
}>`
  background-color: ${MAIN_THEME_DATA.bgColor};
  transition: all 0.3s;
  /* padding-top: calc(${MOBILE_HEADER_HEIGHT} + 1.6rem); */
  ${(props: any) => {
    return !props?.$noHeader
      ? `padding-top: calc(${
          props?.$haveSearchInput ? MOBILE_HEADER_HEIGHT : MOBILE_AUTH_HEADER_HEIGHT
        } + 1.6rem);`
      : `padding-top: 0;`;
  }} //HEADER_HEIGHT + 4rem;
  padding-bottom: ${MOBILE_FOOTER_HEIGHT};
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1;
`;
