import { AppProfileForm, ChangePasswordForm, EditProfileForm } from '@components';
import { yupResolver } from '@hookform/resolvers/yup';
import { AddressItem, WarehouseItem, SellerItem, UpdatePasswordPayload } from '@interfaces';
import { selectAuth, selectUser, useAppDispatch, useAppSelector } from '@redux';
import { useAddressSelector, useMediaQuery } from '@utils';
import { RegisterOptions, UseFormRegisterReturn, useForm } from 'react-hook-form';
import { Navigate, useNavigate } from 'react-router-dom';
import { schema } from './ProfileModule';
import { userAPI } from '@api';
//@ts-ignore
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { PATH_HOME } from '@configs';
export const ChangePasswordModule = () => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { privateId } = useAppSelector(selectAuth);
  const userState = useAppSelector(selectUser);
  const avatar = userState?.avatar;
  const { autocompleteData, onSelectDistrict, onSelectProvince, onSelectWarehouse } =
    useAddressSelector();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    setError,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      password: '',
      new_password: '',
      confirm_password: '',
    },
  });
  const handleChangePassword = handleSubmit(async (value) => {
    try {
      const { password, new_password, confirm_password } = value;
      const payload: UpdatePasswordPayload = {
        password,
        new_password,
        confirm_password,
      };
      const res = await userAPI.updatePassword(privateId.userId, payload);
      NotificationManager.success('Thông báo', 'Thành công');
    } catch (error: any) {
      setError('confirm_password', { type: 'custom', message: error.data.detail });
    }
  });
  if (!isMobile) return <Navigate to={PATH_HOME} />;
  return (
    <AppProfileForm
      avatar={avatar}
      register={register}
      handleChangePassword={handleChangePassword}
      errors={errors}
    />
  );
};
