import { HomeModule } from '@modules';
import React, { useEffect } from 'react';

export const HomePage = () => {
  return (
    <>
      <HomeModule />
    </>
  );
};
