import { BaseSyntheticEvent, memo } from 'react';
import { FieldErrorsImpl, FieldValues, UseFormRegister } from 'react-hook-form';

import { SharedButton, ShareInput } from '@components';
import { MAIN_THEME_DATA } from '@configs';
import { useTheme } from '@theme';
import styled from 'styled-components';
import { StyledMobileAuth } from '../Login';

interface IProps {
  errors: Partial<FieldErrorsImpl<any>>;
  register: UseFormRegister<FieldValues>;
  redirectToLogin?: () => void;
  handleForgotPassword: (e?: BaseSyntheticEvent<any, any, any> | undefined) => Promise<void>;
}

export const AppForgotPasswordForm = memo((props: IProps) => {
  const { errors, register, handleForgotPassword, redirectToLogin } = props;

  const { theme } = useTheme();

  return (
    <StyledMobileAuth>
      <StyledForgotPasswordSection>
        <form>
          <ShareInput
            placeholder="Số điện thoại"
            name="username"
            className="input"
            errors={errors['username']?.message}
            register={register}
          />
          <div className="actions">
            <SharedButton
              onClick={handleForgotPassword}
              text="Tiếp tục"
              className="submit__btn login-btn"
              backgroundColor={MAIN_THEME_DATA.mainColor}
              btnStyle="pad"
            />
          </div>
        </form>
      </StyledForgotPasswordSection>
    </StyledMobileAuth>
  );
});

const StyledForgotPasswordSection = styled.div`
  .input {
    margin-bottom: 1.6rem;
  }
  .actions {
    margin-top: 0.8rem;
    .action-text {
      margin-top: 0.8rem;
    }
  }
`;
