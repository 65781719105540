import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import md5 from 'md5';
import moment from 'moment';

import { PATH_LOGIN, PATH_VERIFY_OTP } from '@configs';
import { LogApp } from '@utils';
import { ForgotPasswordForm } from '@components';
import { setForgotUsername, setLoading, setVerifyOTPHash, useAppDispatch } from '@redux';
import { authAPI } from '@api';

const schema = yup.object().shape({
  username: yup.string().required('Vui lòng nhập số điện thoại'),
});
export const ForgotPasswordModule = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    reset,
    setError,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const handleRedirectToLogin = () => {
    navigate(PATH_LOGIN);
  };

  const genForgotEmailHash = (username: string) => {
    const secretKey = process.env.REACT_APP_SEND_FORGOT_PASS_KEY;
    const plainStr = username + secretKey + moment().format('DD/MM/YYYY');
    const checkHash = md5(plainStr);
    return checkHash;
  };

  const handleForgotPassword = handleSubmit(async (value) => {
    try {
      dispatch(setLoading(true));
      const res = await authAPI.forgotPassword(value.username);
      dispatch(setForgotUsername(value.username));
      dispatch(setVerifyOTPHash(genForgotEmailHash(value.username)));
      navigate(PATH_VERIFY_OTP);
    } catch (error: any) {
      setError('username', { message: error.data.detail });
    } finally {
      dispatch(setLoading(false));
    }
  });

  return (
    <ForgotPasswordForm
      register={register}
      redirectToLogin={handleRedirectToLogin}
      errors={errors}
      handleForgotPassword={handleForgotPassword}
    />
  );
};
