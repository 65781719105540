import { BaseSyntheticEvent, memo } from 'react';
import { FieldErrorsImpl, UseFormRegister } from 'react-hook-form';

import {
  AppModal,
  IConArrowLeft,
  OrderSuccess,
  SharedButton,
  ShareInput,
  StyledAuth,
} from '@components';
import { MAIN_THEME_DATA } from '@configs';
import { IntroSectionModule } from '@modules';
import { useTheme } from '@theme';
import { Statistic } from 'antd';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import { StyledMobileAuth } from '../Login';
import { SignUpModal } from '../components/SignUpModal';
import { LogApp } from '@utils';

interface IProps {
  errors: Partial<FieldErrorsImpl<any>>;
  register: UseFormRegister<any>;
  redirectToLogin?: () => void;
  goBack?: () => void;
  handleResetPassword: (e?: BaseSyntheticEvent<any, any, any> | undefined) => Promise<void>;
  opened?: boolean;
}

export const AppResetPasswordForm = memo((props: IProps) => {
  const { errors, register, handleResetPassword, redirectToLogin, goBack, opened } = props;
  const { Countdown } = Statistic;
  const { theme } = useTheme();
  LogApp(opened, 'opened');
  return (
    <StyledMobileAuth>
      <StyledResetPasswordForm>
        {/* @ts-ignore */}
        <form onSubmit={handleResetPassword} className="reset-form">
          <ShareInput
            placeholder="Mật khẩu mới"
            name="password"
            className="input"
            type="password"
            onChange={() => {}}
            errors={errors['password']?.message}
            register={register}
            haveShowPassIcon
          />
          <ShareInput
            placeholder="Nhập lại mật khẩu"
            name="confirm_password"
            className="input"
            type="password"
            onChange={() => {}}
            errors={errors['confirm_password']?.message}
            register={register}
            haveShowPassIcon
          />
          <div className="actions">
            <SharedButton
              typeHtml="submit"
              text="Xác nhận"
              className="submit__btn login-btn"
              backgroundColor={MAIN_THEME_DATA.mainColor}
              btnStyle="pad"
            />
          </div>
        </form>
      </StyledResetPasswordForm>
      <SignUpModal opened={opened} redirectToLogin={redirectToLogin} />
    </StyledMobileAuth>
  );
});
export const StyledResetPasswordForm = styled.section`
  .input {
    margin-bottom: 1.6rem;
  }
  .actions {
    margin-top: 3.2rem;
  }
`;
