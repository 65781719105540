import { Divider, Dropdown, Image, MenuProps } from 'antd';
import React, { forwardRef } from 'react';

import { enumNavKey, enumThemeMode, MAIN_THEME_DATA, MAT_SM_SCREEN_WIDTH, testImg } from '@configs';
import { BreadcrumbsModule, MenuDropdownModule } from '@modules';
import {
  CartIcon,
  ChevronDownIcon,
  HeaderMenuIcon,
  LocationIcon,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  ProfileIcon,
  SearchIcon,
  UserHeaderIcon,
  UserIcon,
} from '../../Icon';
import { SharedDropdown, SharedImage, ShareInput } from '../../shared';
import { StyledWebHeader } from '../styles';
import { LogApp, useMediaQuery } from '@utils';
import { selectApp, selectAuth, selectUser, useAppSelector } from '@redux';
import { IMAGES } from '@assets';
import {
  Category,
  MegaCategoryDropdown,
  NavBarItem,
  ProductFilter,
  SubCategory,
} from '@interfaces';
import { CategoryModal } from '../components/CategoryModal';
import { selectHome } from 'src/redux/Slices/home';
import { ProductModal } from '../components/ProductModal';

interface IProps {
  isAuth?: boolean;
  open: boolean;
  productOpen: boolean;
  navItems: NavBarItem[];
  onNavItemClick: (path: string) => void;
  onNavigateToCategory: (item: Category) => void;
  onClickChildCategory: (id: SubCategory) => void;
  handleOpenChange: (val: boolean) => void;
  handleProductOpenChange: (val: boolean) => void;
  handleSubmitSearch: () => void;
  navigateToProfile: () => void;
  onSearch: (value: string) => void;
  products: ProductFilter[];
  onNavigateToProductDetail: (item: ProductFilter) => void;
  keyword: string;
  onOpenWarehouseModal: (value: boolean) => void;
  avatar: string;
  navigateToCart: () => void;
}
const UnAuthBlock = () => {
  return (
    <>
      <UserIcon color="white" />
      <div className="text-wrapper">
        <a className="right-text">Đăng nhập</a>
        <span className="right-text">/</span>
        <a className="right-text">Đăng kí</a>
      </div>
    </>
  );
};
export const WebHeader = (props: IProps) => {
  const {
    isAuth,
    navItems,
    onNavItemClick,
    onNavigateToCategory,
    handleOpenChange,
    open,
    onClickChildCategory,
    navigateToProfile,
    onSearch,
    productOpen,
    handleProductOpenChange,
    products,
    onNavigateToProductDetail,
    handleSubmitSearch,
    keyword,
    onOpenWarehouseModal,
    avatar,
    navigateToCart,
  } = props;
  const { themeData, currentPath } = useAppSelector(selectApp);
  const { accessToken } = useAppSelector(selectAuth);
  const { userInfo } = useAppSelector(selectUser);
  const { category, currentWarehouse } = useAppSelector(selectHome);
  return (
    <StyledWebHeader
      className="header"
      $appTheme={MAIN_THEME_DATA.mainColor}
      $themeData={themeData}
      $isAuth={isAuth}
    >
      <div className="header-container">
        <div className="header-actions">
          <Image preview={false} src={IMAGES.logo} />
        </div>
        <div className="mid-header">
          {
            <div
              onClick={() => {
                onOpenWarehouseModal(true);
              }}
              className="warehouse-wrapper"
            >
              <LocationIcon width={25} height={25} color="white" />
              <div className="warehouse-info">
                <div className="view-at">
                  <span className="view-at-txt">Xem hàng tại</span>
                  <ChevronDownIcon width={16} height={16} color="white" />
                </div>
                <span style={currentWarehouse?.name?.length > 15 ? { fontSize: 10 } : {}}>
                  {currentWarehouse?.name}
                </span>
              </div>
            </div>
          }
          <Dropdown
            placement="bottomCenter"
            trigger={['click']}
            open={productOpen}
            onOpenChange={handleProductOpenChange}
            className="dropdown"
            dropdownRender={() => (
              <ProductModal
                onNavigateToProductDetail={onNavigateToProductDetail}
                products={products}
              />
            )}
          >
            <form className="search-input">
              <ShareInput
                value={keyword}
                onPressEnter={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    handleSubmitSearch();
                  }
                }}
                onChange={(e: any) => {
                  onSearch(e.target.value);
                }}
                placeholder="Tìm kiếm sản phẩm, danh mục..."
                sufIcon={
                  <SearchIcon
                    onClick={() => {
                      handleSubmitSearch();
                    }}
                    color={MAIN_THEME_DATA.mainColor}
                  />
                }
              />
            </form>
          </Dropdown>
        </div>
        <div className="header-right">
          {accessToken ? (
            <div className="auth-right-block">
              <CartIcon
                onClick={() => {
                  navigateToCart();
                }}
                color="white"
                height={32}
                width={32}
                className="cart"
              />
              <div className="flex items-center" onClick={navigateToProfile}>
                <span className="name">{userInfo.full_name}</span>
                <SharedImage containerClassName="user-avatar" src={avatar ?? testImg} />
              </div>
            </div>
          ) : (
            <UnAuthBlock />
          )}
        </div>
      </div>
      <div className="header-bottom">
        <div className="nav-block">
          {navItems.map((item) => {
            const isSelected = item.path === currentPath;
            return (
              <div
                onClick={() => {
                  if (item.pathKey !== enumNavKey.CATEGORY) onNavItemClick(item.path);
                }}
                key={item.pathKey}
                className={`nav-item  ${isSelected ? 'justify-between' : 'justify-center'}`}
              >
                <span />
                {item.pathKey === enumNavKey.CATEGORY ? (
                  <Dropdown
                    placement="bottomCenter"
                    trigger={['click']}
                    open={open}
                    onOpenChange={handleOpenChange}
                    className="dropdown"
                    dropdownRender={() => (
                      <CategoryModal
                        onClickChildCategory={(item) => {
                          onClickChildCategory(item);
                        }}
                        onNavigateToCategory={(id) => {
                          handleOpenChange(false);
                          onNavigateToCategory(id);
                        }}
                        categories={category}
                      />
                    )}
                  >
                    <span
                      onClick={() => {
                        handleOpenChange(true);
                      }}
                    >
                      {item.title}
                    </span>
                  </Dropdown>
                ) : (
                  <span>{item.title}</span>
                )}
                {isSelected && <div className={`border-bottom`} />}
              </div>
            );
          })}
        </div>
      </div>
    </StyledWebHeader>
  );
};
