import { homeAPI } from '@api';
import { IMAGES } from '@assets';
import { DEFAULT_HOME_DATA, PATH_LOGIN, PATH_PRODUCT } from '@configs';
import {
  Banner,
  BannerResponse,
  HomeDataPayload,
  HomeResponse,
  HomeRoot,
  ProductFilter,
  ProductsHotSelling,
  Promotion,
  RootResponse,
  WarehouseItem,
} from '@interfaces';
import {
  selectApp,
  selectAuth,
  selectSetting,
  showUnAuthModal,
  useAppDispatch,
  useAppSelector,
} from '@redux';
import { LogApp } from '@utils';
import { debounce } from 'lodash';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { HomeSection } from 'src/components/home/HomeSection';
import {
  getWarehouses,
  selectHome,
  setCurrentChildCategory,
  setCurrentParentCategory,
  setCurrentWarehouse,
} from 'src/redux/Slices/home';
import { useImmer } from 'use-immer';
const testImg = 'https://zocker.vn/pic/Product/zocker-inspire-pro-cam-1_1358_HasThumb.webp';
export const HomeModule = () => {
  const dispatch = useAppDispatch();
  const { isUnAuthModalShow } = useAppSelector(selectApp);
  const { privateId } = useAppSelector(selectAuth);
  const { warehouseModal, warehouses, currentWarehouse, currentChildCategory } =
    useAppSelector(selectHome);
  // const { userId, warehouseId } = privateId;
  // const warehouseId = privateId?.warehouseId;
  const warehouseId = currentWarehouse?.id;
  const userId = privateId?.userId;
  const navigate = useNavigate();
  const [isBannerShown, setIsBannerShown] = useState(true);
  const { settings } = useAppSelector(selectSetting);
  const [homeData, setHomeData] = useImmer<HomeResponse>(DEFAULT_HOME_DATA);
  const [banners, setBanners] = useState<Banner[]>([]);
  const [promotions, setPromotions] = useState<Promotion[]>([]);
  const [products, setProducts] = useState<ProductFilter[]>([]);
  const [keyword, setKeyword] = useState('');

  const [isLoading, setIsLoading] = useState(true);
  const onCloseModal = () => {
    dispatch(showUnAuthModal(false));
  };
  const onNavigateToLogin = () => {
    onCloseModal();
    navigate(PATH_LOGIN);
  };
  const getHomeData = async () => {
    try {
      const payload: HomeDataPayload = {
        from_date: null,
        to_date: null,
        warehouse_id: warehouseId,
        customer_id: userId,
      };
      const res: HomeRoot = await homeAPI.getHomeData(payload);
      const homeProduct: HomeRoot = await homeAPI.getHomeProduct(payload);
      const images: RootResponse<BannerResponse> = await homeAPI.getBanners();
      setBanners(images.data.content);
      const {
        amount_order_packing,
        amount_order_pending,
        amount_order_shipping,
        money_static_response,
        reward_statistic_response,
      } = res.data;
      const newData = {
        from_time: 0,
        to_time: 0,
        amount_order_pending,
        amount_order_shipping,
        amount_order_packing,
        money_static_response,
        products_hot_selling: homeProduct.data.products_hot_selling,
        products_latest: homeProduct.data.products_latest,
        reward_statistic_response,
      };
      setHomeData(newData);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  LogApp(homeData, 'home');
  useEffect(() => {
    getHomeData();
  }, [warehouseId]);
  const onItemClick = (item: ProductsHotSelling) => {
    const category = item?.categories?.[0];
    const subCategory = item?.categories?.[0]?.sub_categories?.[0];
    dispatch(setCurrentParentCategory(category));
    dispatch(setCurrentChildCategory(subCategory));
    navigate(`${PATH_PRODUCT}/${item.code}`);
  };
  return (
    <>
      <HomeSection
        homeData={homeData}
        isUnAuthModalShow={isUnAuthModalShow}
        banners={banners}
        products={products}
        onCloseModal={onCloseModal}
        onNavigateToLogin={onNavigateToLogin}
        isLoading={isLoading}
        onItemClick={onItemClick}
      />
    </>
  );
};
