import { MAIN_THEME_DATA } from '@configs';
import { Category, ProductFilter, ProductFilterData } from '@interfaces';
import { List, Slider, Tabs } from 'antd';
import { styled } from 'styled-components';
import { HomeCardItem } from '../../home/components/HomeCardItem';
import { LoadingList, Pagination, ShareSelectInput, SharedButton } from '../../shared';
import { toVND } from '@utils';
const MIN_PRICE = 0;
const MAX_PRICE = 100000000;
const STEP = 100000;
interface CategorySectionProps {
  products: ProductFilterData;
  defaultActiveKey?: string;
  navigateToProductDetail: (id: string) => void;
  onChangeSubCategory: (key: string) => void;
  category: Category;
  tabItems: Array<{ key: string; label: string }>;
  onPageChange: (page: number) => void;
  payload: any;
  isLoading: boolean;
  onFilterBrand: (value: string) => void;
  onFilterSupplier: (value: string) => void;
  onFilterPrice: (value: number[]) => void;
  getProductBySubCategory: () => void;
  filters: {
    brand: string | null;
    supplier: string | null;
    min_price: number;
    max_price: number;
  };
}
export const MobileCategorySection = (props: CategorySectionProps) => {
  const {
    navigateToProductDetail,
    category,
    tabItems,
    defaultActiveKey,
    products,
    onChangeSubCategory,
    isLoading,
    payload,
    onPageChange,
    onFilterBrand,
    onFilterSupplier,
    onFilterPrice,
    getProductBySubCategory,
    filters,
  } = props;
  return (
    <StyledMobileCategorySection>
      <div className="head-block">
        <span className="select-title">Bộ lọc:</span>
        <div className="filter-block">
          <ShareSelectInput
            style={{ width: '12rem' }}
            className="select mr"
            dropdownStyle={{ minWidth: '32rem', padding: 16 }}
            dropdownRender={() => {
              const formatter = (value: number | undefined) => `${toVND(value || 0)}`;
              return (
                <div>
                  <div className="flex justify-between">
                    <span style={{ fontFamily: 'Roboto-Regular', fontSize: 17 }}>
                      {toVND(filters.min_price)}
                    </span>
                    <span style={{ fontFamily: 'Roboto-Regular', fontSize: 17 }}>
                      {toVND(filters.max_price)}
                    </span>
                  </div>
                  <Slider
                    onChange={(value) => {
                      onFilterPrice(value);
                    }}
                    tooltip={{
                      formatter,
                    }}
                    min={MIN_PRICE}
                    max={MAX_PRICE}
                    step={STEP}
                    range
                    defaultValue={[0, MAX_PRICE]}
                  />
                  <div className="flex justify-between">
                    <SharedButton
                      textStyle={{ fontSize: 14 }}
                      style={{ paddingTop: 6, paddingBottom: 6, marginRight: 16 }}
                      onClick={() => {
                        getProductBySubCategory();
                      }}
                      btnStyle="line"
                      textColor={MAIN_THEME_DATA.mainColor}
                      text="Đóng"
                    />
                    <SharedButton
                      textStyle={{ fontSize: 14 }}
                      style={{ paddingTop: 7, paddingBottom: 7 }}
                      onClick={() => {
                        getProductBySubCategory();
                      }}
                      text="Xem kết quả"
                    />
                  </div>
                </div>
              );
            }}
            placeholder="Khoảng giá"
            data={[]}
          />
          <ShareSelectInput
            placeholder="Chất liệu"
            className="select mr"
            onChange={(v) => {
              onFilterBrand(v);
            }}
            data={products.brands}
          />
          <ShareSelectInput
            placeholder="Xuất xứ"
            className="select"
            onChange={(v) => {
              onFilterSupplier(v);
            }}
            data={products.suppliers}
          />
        </div>
        {/* <div className="sort-block">
          <span className="select-title select-sort-title">Sắp xếp: </span>
          <ShareSelectInput placeholder="Xếp theo" className="select" data={[]} />
        </div> */}
      </div>
      <div className="main-block">
        <h2>{category.name}</h2>
        <Tabs
          tabBarStyle={{
            borderBottomWidth: 1,
            borderBottomColor: '#D9D9D9',
          }}
          onChange={(key) => {
            onChangeSubCategory(key);
          }}
          activeKey={defaultActiveKey}
          items={tabItems}
        />
        {isLoading ? (
          <LoadingList rows={2} count={4} />
        ) : (
          <List
            grid={{
              gutter: 16,
              xs: 2,
              sm: 2,
            }}
            dataSource={products.data}
            renderItem={(item: ProductFilter) => (
              <List.Item>
                <HomeCardItem
                  onItemClick={() => {
                    navigateToProductDetail(item.code);
                  }}
                  hasPrice
                  footerClassName="product-footer-item"
                  title={item.name}
                  image={item.images?.[0]?.url}
                  content={item?.selling_price?.toLocaleString('it-IT', {
                    style: 'currency',
                    currency: 'VND',
                  })}
                  footer={''}
                  tags={[]}
                />
              </List.Item>
            )}
          />
        )}
      </div>
      <Pagination
        className="pagination-bar"
        currentPage={payload.page}
        totalCount={products.limit}
        pageSize={products.count}
        onPageChange={(page: number) => {
          onPageChange(page);
        }}
      />
    </StyledMobileCategorySection>
  );
};
const StyledMobileCategorySection = styled.div`
  padding: 0 1.6rem;
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 3.3rem !important;
  }
  .pagination-bar {
    margin-top: 1rem;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .head-block {
    display: flex;
    flex-direction: column;
    .filter-block {
      display: flex;
      align-items: center;
      width: 100%;
      overflow: auto;
      overflow-y: hidden;
      .select {
        width: 10rem;
      }
      .mr {
        margin-right: 1.2rem;
      }
    }
    .sort-block {
      display: flex;
      flex-direction: column;
      margin-top: 0.8rem;
      .select {
        width: 10rem;
      }
      .select-sort-title {
      }
    }
    .select-title {
      color: #383838;
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 19.6px;
      margin-bottom: 0.4rem;
    }
  }
  .main-block {
    margin-top: 1.6rem;
    h2 {
      //styleName: Size 24/Medium;
      font-family: Roboto-Medium;
      font-size: 1%.7;
      font-weight: 500;
      line-height: 23.8px;
    }
    .ant-tabs-tab + .ant-tabs-tab {
      margin-left: 1.2rem;
    }
    .ant-tabs-tab-btn {
      color: ${MAIN_THEME_DATA.mainText};
      font-size: 1.4rem;
      font-family: Roboto-Regular;
      font-weight: 400;
      line-height: 23.8px;
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: ${MAIN_THEME_DATA.mainColor};
      font-size: 1.4rem;
      font-family: Roboto-Medium;
      font-weight: 500;
      line-height: 23.8px;
    }
    .ant-tabs-ink-bar {
      background: ${MAIN_THEME_DATA.mainColor};
      height: 3px;
    }
    .product-footer-item {
      text-align: left;
      font-family: Roboto-Regular;
      font-size: 1.2rem;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 16.8px */
      color: ${MAIN_THEME_DATA.mainText};
    }
  }
`;
