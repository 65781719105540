import { AppModal, IConCancel, Minus, Plus, SharedButton, SharedImage } from '@components';
import { QuantityControl } from './QuantityControl';
import {
  CartPayload,
  CartUpdateResponse,
  Classification,
  ProductDetailResponse,
} from '@interfaces';
import { LogApp, toVND, useMediaQuery } from '@utils';
import styled from 'styled-components';
import { MAIN_THEME_DATA } from '@configs';
import { useImmer } from 'use-immer';
import { useEffect } from 'react';
import { selectAuth, setLoading, useAppDispatch, useAppSelector } from '@redux';
import { selectHome } from 'src/redux/Slices/home';
import { cartAPI } from '@api';
//@ts-ignore
import { NotificationManager } from 'react-notifications';
interface AddProductModalProps {
  selectedClassification: Classification;
  product: ProductDetailResponse;
  productItems: CartPayload[];
  modalOpened: boolean;
  onClose: () => void;
  classifications: Classification[];
}
const MAX_ITEM = 10000;
const MAX_QUANTITY = 100;
const MIN_QUANTITY = 20;
export const AddProductModal = (props: AddProductModalProps) => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const dispatch = useAppDispatch();
  const { selectedClassification, product, modalOpened, onClose, classifications } = props;
  const { privateId } = useAppSelector(selectAuth);
  const { currentWarehouse } = useAppSelector(selectHome);
  const { userId } = privateId;
  const [productItems, setProductItems] = useImmer(props.productItems);
  const hasSubClassification = classifications.every((item) => item.sub_classifications.length > 0);
  const onIncrease = (productItemId: string) => {
    setProductItems((d) => {
      const foundIndex = d.findIndex((item) => item.product_item_id === productItemId);
      if (foundIndex !== -1) {
        const foundItem = d[foundIndex];
        if (foundItem.quantity < MAX_ITEM) foundItem.quantity++;
        else foundItem.quantity = MAX_ITEM;
      }
    });
  };
  const onChangeQuantity = (productItemId: string, value: number) => {
    setProductItems((d) => {
      const foundIndex = d.findIndex((item) => item.product_item_id === productItemId);
      if (foundIndex !== -1) {
        const foundItem = d[foundIndex];
        if (value < MAX_ITEM) foundItem.quantity = value;
        else foundItem.quantity = MAX_ITEM;
      }
    });
  };
  const onDecrease = (productItemId: string) => {
    setProductItems((d) => {
      const foundIndex = d.findIndex((item) => item.product_item_id === productItemId);
      if (foundIndex !== -1) {
        const foundItem = d[foundIndex];
        if (foundItem.quantity > 0) foundItem.quantity--;
      }
    });
  };
  const getQuantityByProductItem = (productItemId: string) => {
    const foundIndex = productItems.findIndex((item) => item.product_item_id === productItemId);
    if (foundIndex !== -1) return productItems[foundIndex].quantity;
    return 0;
  };
  const getTotalQuantity = () => {
    return productItems.reduce((acc, cur) => {
      return acc + cur.quantity;
    }, 0);
  };
  const getTotalPrice = () => {
    return productItems.reduce((acc, cur) => {
      return acc + cur.quantity * (cur.price ?? 0);
    }, 0);
  };
  const addToCart = async () => {
    try {
      const isAllQuantityZero = productItems.every((item) => {
        return item.quantity === 0;
      });
      if (isAllQuantityZero) {
        onClose();
        return;
      }
      dispatch(setLoading(true));
      const payload: CartPayload[] = productItems.filter((item) => item.quantity > 0);
      const res: CartUpdateResponse = await cartAPI.addToCart(
        payload,
        privateId.userId,
        currentWarehouse.id,
      );
      NotificationManager.success('Thông báo', 'Thành công');
      onClose();
    } catch (error) {
    } finally {
      dispatch(setLoading(false));
    }
  };
  const getQuantity = (quantity: number) => {
    if (quantity <= 20) return MIN_QUANTITY;
    if (quantity >= 100) return MAX_QUANTITY;
    return quantity;
  };
  useEffect(() => {
    if (props.productItems.length) setProductItems(props.productItems);
  }, [props.productItems]);
  const attrName = hasSubClassification
    ? selectedClassification?.sub_classifications?.[0]?.attribute_name
    : selectedClassification.attribute_name;
  const classificationItems = hasSubClassification
    ? selectedClassification.sub_classifications
    : classifications;
  return (
    <AppModal modalClassName="modal" open={modalOpened}>
      <StyledInnerModal>
        <div className="header">
          <span className="title">THÊM SẢN PHẨM VÀO GIỎ HÀNG</span>
          <IConCancel
            onClick={() => {
              onClose();
              setProductItems(props.productItems);
            }}
          />
        </div>
        <div className="main">
          <div className="head">
            <SharedImage containerClassName="img" src={selectedClassification.image?.url ?? ''} />
            <div className="flex flex-col">
              <span>{product.name}</span>
              <span>
                {selectedClassification.attribute_name}:{' '}
                <span className="attr-val">{selectedClassification.value}</span>
              </span>
            </div>
          </div>
          <div className="main-content">
            <div className="attr-header">
              <span className={isMobile ? 'mobile-attr-header-col-width' : 'flex-1 text-left'}>
                {attrName}
              </span>
              <span
                className={
                  isMobile ? 'mobile-attr-header-col-width text-center' : 'flex-1 text-left'
                }
              >
                Tồn kho
              </span>
              <span
                className={
                  isMobile ? 'mobile-attr-header-col-width text-center' : 'flex-1 text-center'
                }
              >
                Đơn giá
              </span>
              <span
                className={
                  isMobile ? 'mobile-attr-header-large-col-width text-right' : 'flex-1 text-center'
                }
              >
                Số lượng đặt
              </span>
            </div>
            <div className="attr-content">
              {classificationItems?.map((item, index) => {
                return (
                  <div className="attr-item">
                    <span
                      className={
                        isMobile
                          ? 'mobile-attr-content-col-width attr-value'
                          : 'flex-1 attr-value mtop-12'
                      }
                    >
                      {item.value}
                    </span>
                    <div
                      className={
                        isMobile
                          ? 'mobile-attr-content-col-width flex justify-center'
                          : 'flex-1 ml-5'
                      }
                    >
                      <span
                        style={{ display: 'inline-block' }}
                        className={
                          isMobile
                            ? 'mobile-attr-content-col-width attr-value text-center'
                            : 'w-3/6 attr-value mtop-12'
                        }
                      >
                        {getQuantity(item.product_item.quantity)}
                      </span>
                    </div>
                    <span
                      className={
                        isMobile
                          ? 'mobile-attr-content-large-col-width attr-value text-center'
                          : 'flex-1 attr-value mtop-12 inline-block'
                      }
                    >
                      {toVND(
                        item.product_item.selling_price *
                          (1 - item.product_item.deduct_percent / 100),
                      )}
                    </span>
                    {!isMobile ? (
                      <div className="flex items-end">
                        <QuantityControl
                          // className={index === 0 ? 'mtop-0' : ''}
                          hasTitle={false}
                          size="small"
                          onDecrease={() => {
                            onDecrease(item.product_item.id);
                          }}
                          onIncrease={() => {
                            onIncrease(item.product_item.id);
                          }}
                          quantity={getQuantityByProductItem(item.product_item.id)}
                          onChangeQuantity={(val) => {
                            onChangeQuantity(item.product_item.id, val);
                          }}
                        />
                      </div>
                    ) : (
                      <div className="flex mobile-attr-content-large-col-width justify-center items-center">
                        <Minus
                          onClick={() => {
                            onDecrease(item.product_item.id);
                          }}
                          strokeColor={MAIN_THEME_DATA.mainColor}
                          strokeWidth={2}
                          width={15}
                          height={15}
                        />
                        <input
                          onChange={(e) => {
                            onChangeQuantity(item.product_item.id, Number(e.target.value));
                          }}
                          type="text"
                          pattern="\d*"
                          maxLength={4}
                          className="mobile-quantity"
                          value={getQuantityByProductItem(item.product_item.id)}
                        />
                        <Plus
                          onClick={() => {
                            onIncrease(item.product_item.id);
                          }}
                          strokeColor={MAIN_THEME_DATA.mainColor}
                          strokeWidth={2}
                          width={15}
                          height={15}
                        />
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="foot">
            <div className="flex justify-around mb-3">
              <div className="foot-block">
                <span className="title">Số lượng</span>
                <span className="value">{getTotalQuantity()}</span>
              </div>
              <div className="foot-block">
                <span className="title">Thành tiền</span>
                <span className="value">{toVND(getTotalPrice())}</span>
              </div>
            </div>
            <SharedButton onClick={addToCart} textClassName="title-btn" text="Thêm vào giỏ hàng" />
          </div>
        </div>
      </StyledInnerModal>
    </AppModal>
  );
};
const StyledInnerModal = styled.div`
  width: 100%;
  overflow-y: auto;
  max-height: 70rem;
  .mtop-0 {
    margin-top: 0;
  }
  .mtop-12 {
    margin-top: 1.2rem;
  }
  @media (max-width: 768px) {
    max-height: 60rem;
  }
  .mobile-quantity {
    background-color: transparent;
    width: 100%;
    text-align: center;
  }
  .mobile-attr-header-col-width {
    width: calc(20% - 2rem);
  }
  .mobile-attr-header-large-col-width {
    width: calc((20% - 2rem) * 2);
  }
  .mobile-attr-content-col-width {
    width: calc(17% - 2rem);
  }
  .mobile-attr-content-large-col-width {
    width: calc((17% - 2rem) * 2);
  }

  .header {
    background-color: ${MAIN_THEME_DATA.mainColor};
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    align-items: center;
    border-radius: 0.6rem 0.6rem 0 0;
    .title {
      color: white;
      font-size: 1.6rem;
    }
    .inner-input {
      height: 4rem;
      font-size: 1.4rem;
    }
  }
  .main {
    padding: 2rem;
    .head {
      margin-bottom: 1rem;
      display: flex;
      width: 100%;
      align-items: center;
      .img {
        margin-right: 2rem;
        width: 10rem;
        height: 10rem;
      }
      .attr-val {
        font-weight: 700;
      }
    }
    .main-content {
      width: 100%;
      border: 1px solid ${MAIN_THEME_DATA.mainColor};
      background-color: ${MAIN_THEME_DATA.bgColor};
      .attr-header {
        display: flex;
        flex-direction: row;
        @media (max-width: 768px) {
          justify-content: space-between;
          padding: 1rem;
        }

        padding: 1rem 0 1rem 1rem;
        border-bottom: 1px solid ${MAIN_THEME_DATA.mainColor};
        span {
          font-size: 1.6rem;
          @media (max-width: 768px) {
            font-size: 1.2rem;
          }
        }
      }
      .attr-content {
        padding: 0 1rem 1rem 1rem;
        .attr-item {
          display: flex;
          align-items: center;
          @media (max-width: 768px) {
            justify-content: space-between;
          }
          .attr-value {
            font-size: 1.8rem;
            font-weight: 700;
            @media (max-width: 768px) {
              font-size: 1rem;
            }
          }
        }
      }
    }
    .foot {
      margin-top: 2rem;
      .title-btn {
        font-size: 2rem;
      }
      .foot-block {
        display: flex;
        flex-direction: column;
        align-items: center;
        .title {
          font-size: 1.6rem;
        }
        .value {
          font-size: 1.6rem;
          font-weight: 700;
        }
      }
    }
  }
`;
