import { BaseSyntheticEvent, memo } from 'react';
import { FieldErrorsImpl, FieldValues, UseFormRegister } from 'react-hook-form';

import { IConArrowLeft, SharedButton, ShareInput, StyledAuth } from '@components';
import { MAIN_THEME_DATA } from '@configs';
import { IntroSectionModule } from '@modules';
import { useTheme } from '@theme';
import styled from 'styled-components';

interface IProps {
  errors: Partial<FieldErrorsImpl<any>>;
  register: UseFormRegister<FieldValues>;
  redirectToLogin?: () => void;
  handleForgotPassword: (e?: BaseSyntheticEvent<any, any, any> | undefined) => Promise<void>;
}

export const WebForgotPasswordForm = memo((props: IProps) => {
  const { errors, register, handleForgotPassword, redirectToLogin } = props;

  const { theme } = useTheme();

  return (
    <StyledAuth>
      <IntroSectionModule>
        <StyledForgotPasswordForm className="forgot-password__section">
          <div className="heading">
            <IConArrowLeft onClick={redirectToLogin} className="icon" width={24} height={24} />
            <h2 className="intro-x text-left xl:text-left heading-title">Quên mật khẩu</h2>
          </div>
          {/* @ts-ignore */}
          <form>
            <ShareInput
              placeholder="Số điện thoại"
              name="username"
              className="input"
              register={register}
              errors={errors['username']?.message}
            />
            <div className="actions">
              <SharedButton
                onClick={handleForgotPassword}
                text="Tiếp tục"
                className="submit__btn login-btn"
                backgroundColor={MAIN_THEME_DATA.mainColor}
                btnStyle="pad"
              />
            </div>
          </form>
        </StyledForgotPasswordForm>
      </IntroSectionModule>
    </StyledAuth>
  );
});

export const StyledForgotPasswordForm = styled.section`
  width: 57%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 0.5rem;
  padding: 2.4rem 3.2rem;
  .actions {
    margin-top: 3.2rem;
  }
  .heading {
    position: relative;
    display: flex;
    justify-content: center;
    .icon {
      position: absolute;
      left: 0;
      top: 2px;
      cursor: pointer;
    }
  }
`;
