import { BaseSyntheticEvent, memo } from 'react';
import { FieldErrorsImpl, UseFormRegister, UseFormSetValue, UseFormWatch } from 'react-hook-form';

import { IConArrowLeft, SharedButton, ShareInput, StyledAuth } from '@components';
import { MAIN_THEME_DATA } from '@configs';
import {
  AddressItem,
  AuthAutocompleteData,
  AutocompleteItem,
  RegisterData,
  SellerItem,
  WarehouseItem,
} from '@interfaces';
import { IntroSectionModule, UploadImageModule } from '@modules';
import { selectApp, useAppSelector } from '@redux';
import { useTheme } from '@theme';
import { LogApp, useMediaQuery } from '@utils';
import { SharedAutocomplete } from '../shared/autocomplete';
import { StyledSignUpForm } from './signUpStyle';
import { AppSignUpForm } from './app/AppSignUp';
import { WebSignUpForm } from './web/WebSignUp';

interface IProps {
  errors: Partial<FieldErrorsImpl<any>>;
  agreePolicy?: boolean;
  isSocialSignUp?: boolean;
  watch: UseFormWatch<any>;
  register: UseFormRegister<any>;
  redirectToLogin?: () => void;
  handleSignUp: (e?: BaseSyntheticEvent<any, any, any> | undefined) => Promise<void>;
  setValue: UseFormSetValue<any>;
  changeAgreePolicy?: () => void;
  onSelectProvince: (value: AddressItem) => void;
  onSelectDistrict: (value: AddressItem) => void;
  onSelectWard: (value: AddressItem) => void;
  onSelectWarehouse: (value: WarehouseItem) => void;
  onSelectSeller: (value: SellerItem) => void;
  autocompleteData: AuthAutocompleteData;
  registerInfo?: RegisterData;
  changeSelectedImage: (value: any) => void;
  imageError: string;
  isUpdate: boolean;
}

export const SignUpForm = memo((props: IProps) => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  if (isMobile) return <AppSignUpForm {...props} />;
  return <WebSignUpForm {...props} />;
});
