import { TransactionHistory } from '@interfaces';
import { Divider } from 'antd';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';

interface HistoryTabProps {
  data: TransactionHistory[];
}
export const HistoryTab = ({ data }: HistoryTabProps) => {
  return (
    <StyledHistoryTab>
      {data.map((item) => {
        const isEarned = item.type === 'keep';
        return (
          <div className="history-item">
            <div className="left-block">
              <h2>Cashback</h2>
              <div className="bottom">
                <span>{item?.type?.toLocaleUpperCase()}</span>
                <Divider className="divider" type="vertical" />
                <span>{moment(new Date()).format('DD/MM/yyyy')}</span>
              </div>
            </div>
            <div className="right-block">
              <h2 className={isEarned ? 'earned-text' : 'used-text'}>
                {(isEarned ? '+' : '-') + item.amount}$
              </h2>
            </div>
          </div>
        );
      })}
    </StyledHistoryTab>
  );
};
const StyledHistoryTab = styled.div`
  background-color: ${(p) => p.theme.colors.bgPage};
  padding: 1.5rem;
  .left-block {
    .bottom {
      display: flex;
      align-items: center;
    }
  }
  .right-block {
    .earned-text {
      color: #378a00;
    }
    .used-text {
      color: #980000;
    }
  }
  .history-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1.4rem 2.2rem 1.4rem 1.9rem;
    margin-bottom: 1.4rem;
    border-radius: 14px;
    background-color: white;
    h2 {
      font-size: 1.6rem;
      font-weight: 600;
    }
    span {
      font-size: 0.8rem;
      color: #707070;
    }
  }
  .divider {
    height: 0.7rem;
    margin: 0 1rem;
    align-self: center;
    border-left: 1px solid #707070;
    opacity: 0.31;
  }
`;
