import { SharedImage } from '@components';
import { MAIN_THEME_DATA } from '@configs';
import {
  Category,
  CategoryDropdown,
  MegaCategoryDropdown,
  ProductFilter,
  SubCategory,
} from '@interfaces';
import { toVND } from '@utils';
import { useState } from 'react';
import { styled } from 'styled-components';

interface ProductModalProps {
  products: ProductFilter[];
  onNavigateToProductDetail: (item: ProductFilter) => void;
}
export const ProductModal = (props: ProductModalProps) => {
  const { products, onNavigateToProductDetail } = props;
  return (
    <StyledProductModal>
      <div className="inline-modal">
        {products.length === 0 ? (
          <span className="empty-text">Không tìm thấy sản phẩm</span>
        ) : (
          <>
            {products.map((item, index) => {
              return (
                <div
                  onClick={() => {
                    onNavigateToProductDetail(item);
                  }}
                  className={`product-col ${
                    index === products.length - 1 ? 'no-border no-mb' : ''
                  }`}
                  key={item.id}
                >
                  <span className="product-name med-text">{item.name}</span>
                </div>
              );
            })}
          </>
        )}
      </div>
    </StyledProductModal>
  );
};
const StyledProductModal = styled.div`
  background-color: white;
  border-radius: 8px;
  @media (max-width: 768px) {
    margin: 0 1.6rem;
  }
  .no-border {
    border-bottom: 0 !important;
  }
  .no-mb {
    margin-bottom: 0 !important;
  }
  .inline-modal {
    @media (max-width: 768px) {
      height: 25rem;
    }
    height: 30rem;
    overflow-y: auto;
    padding-top: 1.2rem;
    .product-col {
      cursor: pointer;
      margin-bottom: 1.2rem;
      padding-left: 1.2rem;
      padding-bottom: 1.2rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #d9d9d9;
      span {
        display: inline-block;
      }
      .product-name {
        font-size: 1.7rem;
        @media (max-width: 768px) {
          font-size: 1.4rem;
        }
        font-weight: 500;
        line-height: 140%;
      }
    }
  }
  h4 {
    font-family: Roboto-Medium;
    font-size: 1.7rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    color: ${MAIN_THEME_DATA.mainColor};
    margin-bottom: 0.8rem;
  }
  .empty-text {
    font-family: Roboto-Medium;
    font-size: 1.7rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    color: ${MAIN_THEME_DATA.mainText};
    margin-top: 2rem;
    display: inline-block;
    text-align: center;
    width: 100%;
  }
`;
