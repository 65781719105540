import { Order } from '@interfaces';
import { BaseSyntheticEvent } from 'react';
import { FieldErrorsImpl, UseFormRegister } from 'react-hook-form';
import { AppModal } from 'src/components/AppModal';
import { XIcon } from 'src/components/Icon';
import styled from 'styled-components';
import { ProductItemList } from './ProductItemList';
import { Rate } from 'antd';
import { SharedButton } from 'src/components/shared';
import { MAIN_THEME_DATA } from '@configs';

interface RatingModalProps {
  modalData: { opened: boolean; orderItem: Order };
  rating: number;
  errors: Partial<FieldErrorsImpl<any>>;
  register: UseFormRegister<any>;
  onClose: () => void;
  submitReview: (e?: BaseSyntheticEvent<any, any, any> | undefined) => Promise<void>;
  onRateChange: (star: number) => void;
}
const RATES = ['Rất kém', 'Kém', 'Tạm được', 'Khá tốt', 'Hài lòng'];
export const RatingModal = (props: RatingModalProps) => {
  const { modalData, rating, onRateChange, register, errors, onClose, submitReview } = props;
  return (
    <AppModal open={modalData.opened}>
      <StyledRatingModal onSubmit={submitReview}>
        <div className="order-heading">
          <span className="text-center  size-24 medium-font weight-500">Đánh giá sản phẩm</span>
          <XIcon
            className="icon"
            onClick={() => {
              onClose();
            }}
          />
        </div>
        <div className="order-content">
          <ProductItemList orderList={modalData.orderItem.order_items} />
          <div className="rate-block">
            <Rate
              tooltips={RATES}
              onChange={(value) => {
                onRateChange(value);
              }}
              defaultValue={5}
            />
            {<span className="rate-text">{RATES[rating - 1]}</span>}
            <div className="rate-detail">
              <textarea {...register('detail')} placeholder="Nội dung đánh giá" />
              {errors['detail']?.message && (
                <p className="input-text-error">{errors['detail']?.message}</p>
              )}
            </div>
          </div>
        </div>
        <div className="actions">
          <SharedButton typeHtml="submit" text="Gửi đánh giá" />
        </div>
      </StyledRatingModal>
    </AppModal>
  );
};
const StyledRatingModal = styled.form`
  width: 80rem;
  @media (max-width: 768px) {
    width: 35.8rem;
  }
  padding-bottom: 2.4rem;
  .icon {
    position: absolute;
    right: 2.4rem;
    cursor: pointer;
  }
  .order-heading {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.6rem 0;
    background-color: #ffd0cd;
    border-radius: 1.2rem 1.2rem 0 0;
    position: relative;
  }
  .mb-4r {
    margin-bottom: 0.4rem;
  }
  .mb-8r {
    margin-bottom: 0.8rem;
  }
  .medium-font {
    font-family: Roboto-Medium;
  }
  .weight-500 {
    font-weight: 500;
  }
  .size-24 {
    font-size: 2.4rem;
    @media (max-width: 768px) {
      font-size: 1.7rem;
    }
    line-height: 140%;
  }
  .red-text {
    color: ${MAIN_THEME_DATA.mainColor};
  }
  .actions {
    padding: 0 1.6rem;
    .text-btn {
      font-size: 1.7rem;
    }
  }
  .order-content {
    margin-bottom: 2.4rem;
    padding: 0 1.6rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    .rate-block {
      .rate-detail {
        width: 100%;
        margin: 4rem 0;
        @media (max-width: 768px) {
          margin-top: 1.6rem;
          margin-bottom: 0;
        }
      }
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .rate-text {
        margin-top: 1.6rem;
        font-size: 1.7rem;
        @media (max-width: 768px) {
          margin-top: 0.8rem;
          font-size: 1.4rem;
        }
      }
    }

    span {
      display: inline-block;
    }
    margin-top: 1.6rem;
    display: flex;
    .img-ctn {
      width: 9.6rem;
      aspect-ratio: 1;
      border-radius: 10px;
      img {
        border-radius: 10px;
      }
    }
    .text-block {
      display: flex;
      flex-direction: column;
      margin-left: 1.6rem;
      .price-block {
        display: flex;
      }
      .text {
        font-size: 1.4rem;
        line-height: 140%;
      }
      .original-price {
        text-decoration: line-through;
        margin-left: 0.4rem;
      }
    }
  }
  .order-title {
    font-family: Roboto-Regular;
    .quantity {
      color: ${MAIN_THEME_DATA.mainColor};
      font-size: 1.7rem;
      font-family: Roboto-Medium;
    }
  }
`;
