import { orderAPI } from '@api';
import { OrderProgressSection } from '@components';
import { DEFAULT_ORDER_DETAIL, PATH_HOME } from '@configs';
import { Order, OrderDetailRoot, OrderProgress } from '@interfaces';
import { useMediaQuery } from '@utils';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { STEP_STATUSES } from 'src/components/cart/components/steps/constants';
import { IStep } from 'src/components/cart/components/steps/stepper-component/types';
export const OrderProgressModule = () => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const { id } = useParams();
  const [order, setOrder] = useState<Order>(DEFAULT_ORDER_DETAIL);
  const [isLoading, setIsLoading] = useState(true);
  const getOrder = async () => {
    try {
      const res: OrderDetailRoot = await orderAPI.getOrder(id || '');
      setOrder(res.data);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  const getOderTimeline = (): IStep[] => {
    return order.order_histories.map((item, index) => {
      return {
        label: item.action,
        time: moment(item.created_at).format('HH:mm DD/MM/YYYY'),
        status: STEP_STATUSES.COMPLETED,
      };
    });
  };
  useEffect(() => {
    getOrder();
  }, []);
  if (!isMobile) return <Navigate to={PATH_HOME} />;
  return (
    <OrderProgressSection orderProgress={getOderTimeline()} order={order} isLoading={isLoading} />
  );
};
