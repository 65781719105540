import { BaseSyntheticEvent, memo } from 'react';
import { FieldErrorsImpl, UseFormRegister } from 'react-hook-form';

import {
  AppModal,
  IConArrowLeft,
  OrderSuccess,
  SharedButton,
  ShareInput,
  StyledAuth,
} from '@components';
import { MAIN_THEME_DATA } from '@configs';
import { IntroSectionModule } from '@modules';
import { useTheme } from '@theme';
import { Statistic } from 'antd';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import { SignUpModal } from '../components/SignUpModal';

interface IProps {
  errors: Partial<FieldErrorsImpl<any>>;
  register: UseFormRegister<any>;
  redirectToLogin?: () => void;
  goBack?: () => void;
  handleResetPassword: (e?: BaseSyntheticEvent<any, any, any> | undefined) => Promise<void>;
  opened?: boolean;
}

export const WebResetPasswordForm = memo((props: IProps) => {
  const { errors, register, handleResetPassword, redirectToLogin, goBack, opened } = props;
  const { Countdown } = Statistic;
  const { theme } = useTheme();

  return (
    <StyledAuth>
      <IntroSectionModule>
        <StyledResetPasswordForm className="reset-password__section">
          <div className="heading">
            <IConArrowLeft onClick={goBack} className="icon" width={24} height={24} />
            <h2 className="intro-x text-left xl:text-left heading-title">Thiết lập mật khẩu</h2>
          </div>
          {/* @ts-ignore */}
          <form onSubmit={handleResetPassword} className="reset-form">
            <ShareInput
              placeholder="Mật khẩu mới"
              name="password"
              className="input"
              type="password"
              onChange={() => {}}
              errors={errors['password']?.message}
              register={register}
              haveShowPassIcon
            />
            <ShareInput
              placeholder="Nhập lại mật khẩu"
              name="confirm_password"
              className="input"
              type="password"
              onChange={() => {}}
              errors={errors['confirm_password']?.message}
              register={register}
              haveShowPassIcon
            />
            <div className="actions">
              <SharedButton
                typeHtml="submit"
                text="Xác nhận"
                className="submit__btn login-btn"
                backgroundColor={MAIN_THEME_DATA.mainColor}
                btnStyle="pad"
              />
            </div>
          </form>
        </StyledResetPasswordForm>
      </IntroSectionModule>
      <SignUpModal opened={opened} redirectToLogin={redirectToLogin} />
    </StyledAuth>
  );
});
const StyledModalInner = styled.div`
  width: 80rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .time-block {
    margin-top: 1rem;
    .text {
      font-size: 1.7rem;
      color: #888;
    }
    .ant-statistic-content-value {
      color: #888;
    }
    .ant-statistic-content {
      font-size: unset;
    }
    display: flex;
    align-items: flex-end;
    .resend-after {
      margin-right: 0.5rem;
    }
  }
  .order-success {
    margin-top: 2.4rem;
    font-family: Roboto-Medium;
    color: #000;
    font-size: 2.4rem;
    font-weight: 500;
    line-height: 140%;
  }
  span {
    font-size: 1.7rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }
  .red-text {
    color: ${MAIN_THEME_DATA.mainColor};
  }
  .btn-block {
    display: flex;
    width: 30%;
    margin-top: 2.4rem;
    .mr {
      margin-right: 2.4rem;
    }
  }
`;
export const StyledResetPasswordForm = styled.section`
  width: 57%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 0.5rem;
  padding: 2.4rem 3.2rem;
  .shared-input {
    margin-bottom: 2rem;
    .inner-input {
      height: 4.6rem;
    }
  }
  .actions {
    margin-top: 3.2rem;
  }
  .heading {
    position: relative;
    display: flex;
    justify-content: center;
    .icon {
      position: absolute;
      left: 0;
      top: 2px;
      cursor: pointer;
    }
  }
`;
