export const PATH_DEFAULT = '/';
export const PATH_MAIN = '/member';
export const PATH_PRODUCT = '/product';
export const PATH_HOME = '/';
export const PATH_SEARCH = '/search';
export const PATH_CATEGORY = '/category';
export const PATH_CART = '/cart';
export const PATH_ORDER = '/order';
export const PATH_ORDER_PROGRESS = PATH_ORDER + '/progress';
export const PATH_ORDER_PROGRESS_DETAIL = PATH_ORDER_PROGRESS + '/:id';
export const PATH_ORDER_DETAIL = PATH_ORDER + '/:id';
export const PATH_ORDER_SUCCESS = '/order-success';
export const PATH_ORDER_SUCCESS_WITH_HASH = PATH_ORDER_SUCCESS + '/:hash';
//category
export const PATH_CATEGORY_DETAIL = PATH_CATEGORY + '/:id';

//product
export const PATH_PRODUCT_DETAIL = PATH_PRODUCT + '/:id';
//auth
export const PATH_LOGIN = '/login';
export const PATH_SIGN_UP = '/register';
export const PATH_FORGOT_PASSWORD = '/forgot-password';
export const PATH_VERIFY_OTP = '/verify-email';
export const PATH_RESET_PASSWORD = '/reset-password';
export const PATH_SOCIAL_SIGN_UP = '/social-register';
export const PATH_SETUP_PASSWORD = '/setup-password';
export const PATH_UPDATE_PROFILE = '/update-profile';
//profile
export const PATH_PROFILE = '/profile';
export const PATH_EDIT_PROFILE = PATH_PROFILE + '/edit';
export const PATH_CHANGE_PASSWORD = PATH_PROFILE + '/change-password';
//error
export const PATH_404 = '/*';
