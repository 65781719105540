import { ChangeEvent } from 'react';
import styled from 'styled-components';

interface IProps {
  uploadBtnRef: any;
  previewUrls?: string[];
  onUpload: () => void;
  onChangeImage: (e: ChangeEvent<any>) => void;
}

export const ShareUploadImageBtn = (props: IProps) => {
  const { uploadBtnRef, previewUrls, onUpload, onChangeImage } = props;

  return (
    <StyledInfoItem className="upload__image">
      {previewUrls?.map((item: string, index: number) => {
        <img
          onClick={onUpload}
          src={item || 'https://bookskozuchi.com/images/thumbs/0012699_415.jpeg'}
          alt=""
          className="avatar"
          key={index}
        />;
      })}

      <StyledUploadButton>
        <input
          ref={uploadBtnRef}
          type="file"
          id="avatar"
          name="avatar"
          accept="image/png, image/jpeg"
          onChange={onChangeImage}
        />
      </StyledUploadButton>
    </StyledInfoItem>
  );
};

const StyledInfoItem = styled.div`
  width: 12.8rem;
  height: 12.8rem;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &:hover {
    img {
      opacity: 0.7;
    }
  }
`;

const StyledUploadButton = styled.div`
  display: none;
  input {
    display: none;
  }
`;
