import { BaseSyntheticEvent, memo } from 'react';
import { FieldErrorsImpl, FieldValues, UseFormRegister } from 'react-hook-form';

import { useMediaQuery } from '@utils';
import styled from 'styled-components';
import { AppForgotPasswordForm } from './app/AppForgotPassword';
import { WebForgotPasswordForm } from './web/WebForgotPassword';

interface IProps {
  errors: Partial<FieldErrorsImpl<any>>;
  register: UseFormRegister<FieldValues>;
  redirectToLogin?: () => void;
  handleForgotPassword: (e?: BaseSyntheticEvent<any, any, any> | undefined) => Promise<void>;
}

export const ForgotPasswordForm = memo((props: IProps) => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  if (isMobile) return <AppForgotPasswordForm {...props} />;
  return <WebForgotPasswordForm {...props} />;
});

export const StyledForgotPasswordForm = styled.section`
  width: 57%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 0.5rem;
  padding: 2.4rem 3.2rem;
  .actions {
    margin-top: 3.2rem;
  }
  .heading {
    position: relative;
    display: flex;
    justify-content: center;
    .icon {
      position: absolute;
      left: 0;
      top: 2px;
      cursor: pointer;
    }
  }
`;
