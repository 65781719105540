import { MAIN_THEME_DATA } from '@configs';
import { ProductTag } from '@interfaces';
import { SharedCard, SharedImage } from 'src/components/shared';
import { styled } from 'styled-components';
interface HomeCardItemProps {
  title: string;
  image: string;
  content: string;
  footer: string;
  isMobile?: boolean;
  contentClassName?: string;
  footerClassName?: string;
  tags?: ProductTag[];
  hasPrice?: boolean;
  onItemClick: () => void;
}
export const HomeCardItem = ({
  contentClassName,
  footerClassName,
  title,
  image,
  content,
  footer,
  tags,
  isMobile,
  hasPrice,
  onItemClick,
}: HomeCardItemProps) => {
  return (
    <StyledHomeCardItem onClick={onItemClick} isMobile={isMobile} hasPrice={hasPrice}>
      <SharedImage containerClassName="image-wrapper" className="image" src={image} />
      <div className="info-block">
        <p className="text">{title}</p>
        <p className={`content weight-400 ${contentClassName ? contentClassName : ''}`}>
          {content}
        </p>
        <p className={`weight-400 ${footerClassName ? `${footerClassName}` : `footer`}`}>
          {footer}
        </p>
      </div>
      <div className="tag-block">
        {tags?.map((item) => {
          return (
            <div className="tag-item" key={item.id}>
              <span>{item.content}</span>
            </div>
          );
        })}
      </div>
    </StyledHomeCardItem>
  );
};

const StyledHomeCardItem = styled.div<{ isMobile?: boolean; hasPrice?: boolean }>`
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 1px 16px 0px rgba(56, 56, 56, 0.15);
  min-height: 30.5rem;
  .ant-skeleton-image {
    border-top-right-radius: 1.6rem;
    border-top-left-radius: 1.6rem;
  }
  cursor: pointer;
  @media (max-width: 768px) {
    min-height: unset;
  }
  width: 100%;
  position: relative;
  .tag-block {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 1.6rem;
    @media (max-width: 768px) {
      top: 0.75rem;
    }
    .tag-item {
      margin-bottom: 0.8rem;
      padding: 0.4rem 1.6rem;
      @media (max-width: 768px) {
        padding: 0.2rem 0.75rem;
      }
      background-color: white;
      border-top-right-radius: 1.2rem;
      border-bottom-right-radius: 1.2rem;
      span {
        /* Size 14/Regular */
        font-family: Roboto-Regular;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 19.6px */
        color: ${MAIN_THEME_DATA.mainColor};
      }
    }
  }
  p {
    margin-bottom: 0;
  }
  .image-wrapper {
    .image {
      width: 100%;
      aspect-ratio: calc(222 / 180);
      /* object-fit: fill; */
      border-top-right-radius: 1.6rem;
      border-top-left-radius: 1.6rem;
      @media (max-width: 768px) {
        aspect-ratio: calc(132 / 83);
        border-top-right-radius: 7px;
        border-top-left-radius: 7px;
      }
    }
  }
  .info-block {
    padding: 1.2rem 1.2rem 1.6rem 1.2rem;
    @media (max-width: 768px) {
      padding: 0.6rem 0.6rem 0.8rem 0.6rem;
    }
    .text {
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 19.6px */
      font-family: Roboto-Medium;
      min-height: 4rem;
      @media (max-width: 768px) {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      color: ${(p) => MAIN_THEME_DATA.mainText};
    }
    .weight-400 {
      font-weight: 400;
    }
    .content {
      color: ${(p) => (p?.hasPrice ? MAIN_THEME_DATA.mainColor : MAIN_THEME_DATA.mainText)};
      font-weight: ${(p) => (p?.hasPrice ? '500' : '400')};
      font-family: ${(p) => (p?.hasPrice ? 'Roboto-Medium' : 'Roboto-Regular')};
      margin: 0.4rem 0;
      @media (max-width: 768px) {
        font-size: 1.2rem;
      }
    }
    .footer {
      color: red;
      text-align: center;
      font-family: Roboto-Medium;
      font-size: 1.2rem;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
    }
    .mobile-footer {
      max-lines: 3;
      max-height: 10rem;
    }
  }
`;
