import { APP_HEADER_TITLE } from '@configs';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getPath } from '../helpers';
export const useHeaderTitle = () => {
  const location = useLocation();
  const [title, setTitle] = useState('');
  const currentPath = location.pathname;
  //@ts-ignore
  return APP_HEADER_TITLE[getPath(currentPath)];
};
