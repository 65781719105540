import { memo } from 'react';

import { ICon } from '@interfaces';
import { MAIN_THEME_DATA } from '@configs';

export const IConArrowDown = memo((props: ICon) => {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.1133 7.5L10.1133 12.5L5.11328 7.5"
        stroke={props.color}
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
});

export const IConArrowLeft = memo((props: ICon) => {
  const { color = MAIN_THEME_DATA.mainColor, width = 24, height = 24, strokeWidth = 1.5 } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.5 5L8.5 12L15.5 19"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
});

export const IConArrowRight = memo((props: ICon) => {
  const { color = MAIN_THEME_DATA.mainColor, onClick, width = 24, height = 24 } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.5 5L15.5 12L8.5 19"
        stroke={color}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
});
