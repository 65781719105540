import { Category } from './category';
import { Image } from './home';

export interface Promotion {
  id: string;
  name: string;
  code: string;
  description: string;
  start_day: number;
  end_day: number;
  discount_percent: number;
  type: string;
  promotion_items: PromotionItem[];
}
export interface PromotionItem {
  id: string;
  promotion_id: string;
  condition_amount: number;
  discount_percent: number;
}
export interface ProductDetailRoot {
  status: number;
  message: string;
  data: ProductDetailResponse;
  timestamp: string;
}
export enum PromotionType {
  AMOUNT = 0,
  MONEY,
}
export interface ProductDetailResponse {
  id: string;
  code: any;
  name: string;
  unit: string;
  type: string;
  origin: string;
  deduct_percent: number;
  import_price: any;
  selling_price: any;
  quantity: any;
  solded_quantity: any;
  rating: any;
  description: string;
  url_image: any;
  view: number;
  images: ProductImage[];
  warehouse: Warehouse;
  categories: Category[];
  attributes: ProductAttribute[];
  classifications: Classification[];
  promotions: Promotion[];
}

export interface ProductImage {
  id: string;
  url: string;
  content_type: string;
}

export interface Warehouse {
  id: string;
  name: string;
  code: string;
  phone_number: string;
  address_response: any;
}

export interface ProductAttribute {
  attribute_id: string;
  attribute_name: string;
  value: string;
  detail: boolean;
}
export interface Classification {
  id: string;
  attribute_name: string;
  attribute_id: string;
  value: string;
  product_item: ProductItem;
  sub_classifications: SubClassification[];
  detail: boolean;
  image?: Image;
  deduct_percent?: number;
}
export interface ProductItem {
  id: string;
  import_price: number;
  selling_price: number;
  code: string;
  quantity: number;
  solded_quantity: number;
  reward: boolean;
  deduct_percent: number;
}
export interface SubClassification {
  id: string;
  attribute_name: string;
  attribute_id: string;
  value: string;
  product_item: ProductItem;
  sub_classifications: SubClassification[];
  detail: boolean;
}
export interface ProductDetailPayload {
  id: string;
  view: number;
}
