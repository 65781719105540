import { MAIN_THEME_DATA, ORDER_STATUS_TEXT } from '@configs';
import { Order } from '@interfaces';
import { toVND, useMediaQuery } from '@utils';
import moment from 'moment';
import Skeleton from 'react-loading-skeleton';
import { styled } from 'styled-components';
import { IConArrowLeft } from '../Icon';
import { ProductItemList } from './components/ProductItemList';
import { BillLine } from '../cart/components/BillLine';
import { LoadingCartItem } from '../cart/components/LoadingCart';
import Stepper from '../cart/components/steps';
import { LABEL_POSITION, STEP_STATUSES } from '../cart/components/steps/constants';
import { IStep } from '../cart/components/steps/stepper-component/types';
import { AppOrderDetailSection } from './mobile/AppOrderDetailSection';
import { WebOrderDetailSection } from './web/WebOrderDetailSection';
interface OrderDetailSectionProps {
  order: Order;
  goBack: () => void;
  getOrderAddress: () => string;
  navigateToOrderProgress: (id: string) => void;
  orderTimeline: IStep[];
  isLoading: boolean;
  getTotalDeductPercent: () => string;
  getDiscountPercent: (index: number) => number;
  calculateTotalPrice: () => number;
  cancelOrder: () => void;
}
export const DEFAULT_TIMELINE = [
  { label: '', time: '', status: STEP_STATUSES.COMPLETED },
  { label: '', time: '', status: STEP_STATUSES.COMPLETED },
  { label: '', time: '', status: STEP_STATUSES.COMPLETED },
];
export const OrderDetailSection = (props: OrderDetailSectionProps) => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  if (isMobile) return <AppOrderDetailSection {...props} />;
  return <WebOrderDetailSection {...props} />;
};
