import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootResponse } from '@interfaces';
import { RootState } from '.';
import { fetchSettingList } from '../actions';

interface IInitialState {
  loading?: boolean;
  settings?: RootResponse<any>;
}

const initialState: IInitialState = {
  settings: undefined,
  loading: false,
};

const setting = createSlice({
  name: 'setting',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSettingList.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        fetchSettingList.fulfilled,
        (state, { payload }: PayloadAction<RootResponse<any>>) => {
          state.loading = false;
          state.settings = payload;
        },
      )
      .addCase(fetchSettingList.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const selectSetting = (state: RootState) => state.setting;

// export const {} = setting.actions;

export default setting.reducer;
