import { LoginPayload, RegisterPayload } from '@interfaces';
import { ApiClient, DefaultApiParams } from './axiosClient';

export const authAPI = {
  login: (body: LoginPayload) => {
    const url = '/auth/login';
    return ApiClient.post(url, body);
  },
  getProvinces: (name?: string) => {
    const url = `/provinces?keyword=${name}&size=10&page=0&all=true`;
    return ApiClient.get(url);
  },
  getDistricts: (province_code: string, keyword?: string) => {
    const url = `/districts`;
    return ApiClient.post(url, { province_code, keyword });
  },
  getWards: (district_code: string, keyword?: string) => {
    const url = `/wards`;
    return ApiClient.post(url, { district_code, keyword });
  },
  getWarehouses: (params?: DefaultApiParams) => {
    const url = `/warehouses/items`;
    return ApiClient.get(url, { params });
  },
  getSellers: (warehouse_id: string) => {
    const url = `/sellers/items?warehouse_id=${warehouse_id}&size=100`;
    return ApiClient.get(url);
  },
  uploadImages: (payload: FormData) => {
    const url = `/images`;
    return ApiClient.post(url, payload);
  },
  signUp: (payload: RegisterPayload) => {
    const url = `/customers`;
    return ApiClient.post(url, payload);
  },
  updateProfile: (payload: any, id: string) => {
    const url = `/customers/${id}`;
    return ApiClient.put(url, payload);
  },
  getProfile: (id: string) => {
    const url = `/customers/${id}`;
    return ApiClient.get(url);
  },
  checkVersion: () => {
    const url = `/tools/version`;
    return ApiClient.get(url);
  },
  forgotPassword: (username: string) => {
    const url = `/accounts/forgot-password?username=${username}`;
    return ApiClient.get(url);
  },
  verifyOtp: ({ username, otp }: { username: string; otp: string }) => {
    const url = `/accounts/verify-otp`;
    return ApiClient.post(url, { username, otp });
  },
  resetPassword: ({
    new_password,
    confirm_password,
    username,
  }: {
    new_password: string;
    confirm_password: string;
    username: string;
  }) => {
    const url = `/accounts/external-change-password`;
    return ApiClient.post(url, { password: null, new_password, confirm_password, username });
  },
};
