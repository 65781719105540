import { ICon } from '@interfaces';
import * as React from 'react';
export const Plus = (props: ICon) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    viewBox="0 0 24 24"
    fill="none"
    stroke="#383838"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    icon-name="plus"
    data-lucide="plus"
    {...props}
  >
    <line
      strokeWidth={props?.strokeWidth || 1}
      stroke={props.strokeColor ?? '#383838'}
      x1={12}
      y1={5}
      x2={12}
      y2={19}
    />
    <line
      strokeWidth={props?.strokeWidth || 1}
      stroke={props.strokeColor ?? '#383838'}
      x1={5}
      y1={12}
      x2={19}
      y2={12}
    />
  </svg>
);
