import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { selectAuth, setCurrentPath, useAppDispatch, useAppSelector } from '@redux';
import { ALL_THEMES, PATH_LOGIN, PATH_MAIN } from '@configs';
import { useEffect, useLayoutEffect } from 'react';
import { LogApp, setToLS } from '@utils';
import { themes } from '@theme';

export const AppRoute = () => {
  const { accessToken } = useAppSelector(selectAuth);
  const dispatch = useAppDispatch();
  // if (process.env.NODE_ENV === 'development') {
  //   return <Outlet />;
  // }
  const location = useLocation();
  useEffect(() => {
    dispatch(setCurrentPath(location.pathname));
  }, [location.pathname]);
  useLayoutEffect(() => {
    setToLS(ALL_THEMES, themes);
    // WebFont.load({
    //   google: {
    //     families: getFonts(),
    //   },
    // });
  }, []);

  return accessToken ? <Outlet /> : <Navigate to={PATH_LOGIN} />;
};
