import { Divider } from 'antd';
import React, { forwardRef } from 'react';

import { enumThemeMode, MAIN_THEME_DATA, MAT_SM_SCREEN_WIDTH } from '@configs';
import { BreadcrumbsModule, MenuDropdownModule } from '@modules';
import {
  CartIcon,
  CategoryIcon,
  HeaderMenuIcon,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  SearchIcon,
  UserHeaderIcon,
} from '../Icon';
import { SharedDropdown, SharedImage, ShareInput } from '../shared';
import { useMediaQuery } from '@utils';
import { selectApp, useAppSelector } from '@redux';
import { StyledMobileFooter, StyledWebFooter } from './footer.styled';
import { FooterContactItem } from '@interfaces';
import { FooterCol } from './components/FooterCol';
import { SendEmailCol } from './components/SendEmailCol';

interface IProps {
  contacts: FooterContactItem[];
  intros: FooterContactItem[];
  policies: FooterContactItem[];
}

export const WebFooter = (props: IProps) => {
  const { contacts, intros, policies } = props;
  const { themeData } = useAppSelector(selectApp);
  return (
    <StyledWebFooter
      className="footer"
      $appTheme={MAIN_THEME_DATA.mainColor}
      $themeData={themeData}
    >
      <div className="content-block">
        <FooterCol textSize={1.7} isContact title="Liên hệ chúng tôi" colData={contacts} />
        <FooterCol title="Giới thiệu" colData={intros} />
        <FooterCol textSize={1.7} title="Chính sách và quy định" colData={policies} />
        <SendEmailCol onSendMail={() => {}} />
      </div>
      <div className="copy-right-block">
        <span className="title">
          © Bản quyền thuộc về DONGDUONGJSC | Cung cấp bởi <a className="title">Đông Dương Sport</a>
        </span>
      </div>
    </StyledWebFooter>
  );
};
