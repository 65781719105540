import { settingAPI } from '@api';
import { createAsyncThunk } from '@reduxjs/toolkit';
const prefix = 'setting';

export const fetchSettingList = createAsyncThunk<any, any>(
  `${prefix}/detail-member`,
  async (params, { rejectWithValue }) => {
    try {
      const res = await settingAPI.getUserInfo();
      return res as unknown;
    } catch (e) {
      rejectWithValue(e);
    }
  },
);
