import { memo } from 'react';

import { CallingIcon, Footer, LocationIcon, MailIcon } from '@components';
import { selectApp, useAppDispatch, useAppSelector } from '@redux';
// import { CONTACT_WEB_FOOTER } from '@configs';

interface IProps {
  showMenu?: boolean;
  isAuth?: boolean;
}

export const FooterModule = memo((props: IProps) => {
  const CONTACT_WEB_FOOTER = [
    { id: 1, icon: LocationIcon, title: 'Số 2 - Ngách 91- Ngõ 54 - Ngọc Hồi - Hà Nội', link: '' },
    { id: 1, icon: CallingIcon, title: 'Liên hệ Nhượng Quyền: 0965.596.886', link: '' },
    { id: 1, icon: CallingIcon, title: 'Liên hệ Mua Hàng: 0837.596.886', link: '' },
    { id: 1, icon: CallingIcon, title: 'Liên hệ CSKH: 0854.59.6886', link: '' },
    { id: 1, icon: MailIcon, title: 'dongduongsport.group@gmail.com', link: '' },
  ];
  const dispatch = useAppDispatch();
  const { themeMode } = useAppSelector(selectApp);
  return <Footer contacts={CONTACT_WEB_FOOTER} />;
});
