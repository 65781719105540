import { MAIN_THEME_DATA } from '@configs';
import { Minus, Plus } from 'src/components/Icon';
import { styled } from 'styled-components';

interface QuantityControlProps {
  quantity: number;
  size?: 'small' | 'big';
  hasTitle?: boolean;
  onIncrease: () => void;
  onDecrease: () => void;
  onChangeQuantity?: (quantity: number) => void;
  className?: string;
}
export const QuantityControl = (props: QuantityControlProps) => {
  const {
    quantity,
    size = 'big',
    hasTitle = true,
    onIncrease,
    onDecrease,
    onChangeQuantity,
    className,
  } = props;
  const iconSize = size === 'big' ? 20 : 12;
  return (
    <>
      {hasTitle && <span className="title">Số lượng</span>}
      <StyledQuantityControl className={className} size={size}>
        <div onClick={onDecrease} className="plus-block">
          <Minus strokeWidth={2} width={iconSize} height={iconSize} />
        </div>
        {!onChangeQuantity ? (
          <span className="quantity">{quantity}</span>
        ) : (
          <input
            onChange={(e) => {
              onChangeQuantity?.(Number(e.target.value));
            }}
            type="text"
            pattern="\d*"
            maxLength={5}
            value={quantity}
            className="input-quantity"
          />
        )}
        <div onClick={onIncrease} className="minus-block">
          <Plus strokeWidth={2} width={iconSize} height={iconSize} />
        </div>
      </StyledQuantityControl>
    </>
  );
};
const StyledQuantityControl = styled.div<{ size?: 'small' | 'big' }>`
  background-color: white;
  width: ${(p) => (p?.size === 'small' ? '12.8rem' : '20rem')};
  border: ${(p) => (p?.size === 'small' ? '1px solid #888' : 'unset')};
  box-shadow: ${(p) => (p?.size === 'small' ? 'unset' : '0px 1px 16px rgba(56, 56, 56, 0.15)')};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: ${(p) => (p?.size === 'small' ? '0.8rem' : '1.2rem')};
  margin-top: 1.2rem;

  .quantity {
    color: ${(p) =>
      p?.size === 'small' ? `${MAIN_THEME_DATA.mainText}` : `${MAIN_THEME_DATA.mainText}`};
    font-size: ${(p) => (p?.size === 'small' ? '1.7rem' : '2.4rem')};
    line-height: ${(p) => (p?.size === 'small' ? '23.8px' : '33.6px')};
    font-family: Roboto-Medium;
    font-weight: 500;
  }
  .input-quantity {
    color: ${(p) =>
      p?.size === 'small' ? `${MAIN_THEME_DATA.mainText}` : `${MAIN_THEME_DATA.mainText}`};
    font-size: ${(p) => (p?.size === 'small' ? '1.7rem' : '2.4rem')};
    line-height: ${(p) => (p?.size === 'small' ? '23.8px' : '33.6px')};
    font-family: Roboto-Medium;
    font-weight: 500;
    width: 100%;
    text-align: center;
    outline: unset;
  }
  .plus-block {
    cursor: pointer;
    border-right-width: 1px;
    padding: ${(p) => (p?.size === 'small' ? '1rem 1.2rem' : '1.6rem')};
  }
  .minus-block {
    cursor: pointer;
    border-left-width: 1px;
    padding: ${(p) => (p?.size === 'small' ? '1rem 1.2rem' : '1.6rem')};
  }
`;
