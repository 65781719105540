import { SharedButton } from '@components';
import { MAIN_THEME_DATA, PATH_HOME, PATH_ORDER } from '@configs';
import { useMediaQuery } from '@utils';
import { useNavigate } from 'react-router-dom';
import { EmptyCart } from 'src/components/Icon';
import { styled } from 'styled-components';

export const EmptyCartView = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width: 768px)');
  return (
    <StyledEmptyCartView>
      <EmptyCart width={isMobile ? 80 : 152} height={isMobile ? 80 : 152} />
      <h2 className="empty-text">Giỏ hàng trống</h2>
      <div className="btn-block">
        <SharedButton
          onClick={() => {
            navigate(PATH_HOME);
          }}
          className="mb"
          text="Quay lại trang chủ"
        />
        <SharedButton
          btnStyle="line"
          textColor={MAIN_THEME_DATA.mainColor}
          text="Xem đơn hàng đã đặt"
          onClick={() => {
            navigate(PATH_ORDER);
          }}
        />
      </div>
    </StyledEmptyCartView>
  );
};
const StyledEmptyCartView = styled.div`
  margin-top: 4.8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 768px) {
    padding: 0 3.2rem;
  }
  .empty-text {
    @media (max-width: 768px) {
      margin-top: 2.4rem;
      margin-bottom: 4rem;
      text-align: center;
      font-family: Roboto-Regular;
      font-size: 1.7rem;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 33.6px */
    }
    margin-top: 4.8rem;
    margin-bottom: 3.2rem;
    text-align: center;
    /* Size 24/Regular */
    font-family: Roboto-Regular;
    font-size: 2.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 33.6px */
  }
  .btn-block {
    @media (max-width: 768px) {
      width: 100%;
    }
    width: 30%;
    .text-btn {
      font-size: 1.7rem;
    }
    .btn {
      /* padding: 1.2rem 13.15rem; */
    }
    .mb {
      margin-bottom: 2.4rem;
    }
  }
`;
