import { PATH_HOME } from '@configs';
import { OrderSuccessSection } from '@modules';
import { selectApp, useAppSelector } from '@redux';
import { LogApp } from '@utils';
import { useEffect, useLayoutEffect } from 'react';
import { Navigate } from 'react-router';
import { useLocation, useNavigate, useParams } from 'react-router';
import { selectCart } from 'src/redux/Slices/cart';

export const OrderSuccessPage = () => {
  const { orderHash } = useAppSelector(selectCart);
  const { currentPath } = useAppSelector(selectApp);
  // if (!orderHash) return <Navigate to={PATH_HOME} />;
  return <OrderSuccessSection />;
};
