import { useMediaQuery } from '@utils';
import Skeleton from 'react-loading-skeleton';
import { styled } from 'styled-components';

export const LoadingView = () => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const PRICE_WIDTH = isMobile ? '26.7rem' : '42.8rem';
  const BUTTON_WIDTH = isMobile ? '17.1rem' : '30rem';
  const BUTTON_HEIGHT = isMobile ? '3.6rem' : '17.6rem';
  return (
    <StyledLoadingView>
      {!isMobile && <Skeleton width="56.1rem" height="3.4rem" />}
      <div className="content-loading-block">
        <Skeleton height="100%" containerClassName="gallery-skeleton" />
        <div className="product-info-block">
          <div>
            <div className={isMobile ? '' : 'm-left-4'}>
              <Skeleton height="4.5rem" width={PRICE_WIDTH} />
              <div className="status-block">
                <Skeleton height="2rem" width={PRICE_WIDTH} />
              </div>
            </div>
            <div className="attribute-block">
              <div className="size-block">
                <Skeleton width="7.2rem" height="2rem" />
                <div className="size-content">
                  <Skeleton className="size-loading" width="12rem" height="4.4rem" />
                  <Skeleton className="size-loading" width="12rem" height="4.4rem" />
                  <Skeleton className="size-loading" width="12rem" height="4.4rem" />
                  <Skeleton className="size-loading" width="12rem" height="4.4rem" />
                </div>
                <Skeleton width="7.2rem" height="2rem" />
                <div className="size-content">
                  <Skeleton className="size-loading" width="12rem" height="4.4rem" />
                  <Skeleton className="size-loading" width="12rem" height="4.4rem" />
                  <Skeleton className="size-loading" width="12rem" height="4.4rem" />
                  <Skeleton className="size-loading" width="12rem" height="4.4rem" />
                </div>
              </div>
              <div className="quantity-block">
                <Skeleton className="size-loading" width="20rem" height="5.6rem" />
              </div>
            </div>
          </div>
          <div className={`btn-block ${isMobile ? '' : 'm-left-4'}`}>
            <Skeleton
              width={BUTTON_WIDTH}
              height={BUTTON_HEIGHT}
              containerClassName="left-btn-loading"
            />
            <Skeleton width={BUTTON_WIDTH} height={BUTTON_HEIGHT} />
          </div>
        </div>
      </div>
      <div className="description-block">
        <Skeleton width="100%" height="17.6rem" />
        <Skeleton width="100%" height="17.6rem" />
      </div>
    </StyledLoadingView>
  );
};
const StyledLoadingView = styled.div`
  padding: 0 12rem;
  @media (max-width: 768px) {
    padding: 0 1.6rem;
  }
  .content-loading-block {
    display: flex;
    flex-direction: row;
    @media (max-width: 768px) {
      flex-direction: column;
      margin-top: 0;
      margin-bottom: 2.4rem;
    }
    margin-bottom: 4rem;
    margin-top: 2.4rem;
  }
  .gallery-skeleton {
    @media (max-width: 768px) {
      width: 100%;
      margin-bottom: 2.4rem;
    }
    width: calc((536 / 1200) * 100%);
    aspect-ratio: 536 / 664;
  }
  .size-loading {
    margin-right: 1.6rem;
    margin-bottom: 1.6rem;
  }
  .left-btn-loading {
    margin-right: 2.4rem;
  }
  .product-info-loading-block {
    /* margin-left: 4rem; */
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
`;
