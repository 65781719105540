import { Category, NavBarItem, ProductFilter, SubCategory } from '@interfaces';
import { selectApp, selectAuth, useAppSelector } from '@redux';
import { useMediaQuery } from '@utils';
import { useLocation } from 'react-router-dom';
import { WebAuthHeader } from './WebAuthHeader';
import { MobileAuthHeader } from './MobileAuthHeader';

interface IProps {
  isAuth?: boolean;
}

export const AuthHeader = (props: IProps) => {
  const { isAuth } = props;
  const { themeData } = useAppSelector(selectApp);
  const { accessToken } = useAppSelector(selectAuth);
  const location = useLocation();
  const isMobile = useMediaQuery('(max-width: 768px)');
  return isMobile ? <MobileAuthHeader /> : <WebAuthHeader />;
};
