import { MAIN_THEME_DATA, MOBILE_FOOTER_HEIGHT } from '@configs';
import { CartItem as CartItemInterface, Classification, SubClassification } from '@interfaces';
import { Col, Row, Steps } from 'antd';
import { styled } from 'styled-components';
import { BagIcon, IConArrowLeft, IdCardIcon, ShoppingCartIcon, TickIcon } from '../../Icon';
import { SharedButton } from '../../shared';
import { BillLine } from '../components/BillLine';
import { CartItem } from '../components/CartItem';
import { EmptyCartView } from '../components/EmptyCartView';
import { LoadingBill, LoadingCart } from '../components/LoadingCart';
import { RoundedIcon } from '../components/RoundedIcon';
import { DebouncedFunc } from 'lodash';
interface CartSectionProps {
  cart: CartItemInterface[];
  getAttribute: (item: CartItemInterface) => string;
  getPrice: () => { number: string; text: string; totalPrice: string };
  increaseProductQuantity: (item: CartItemInterface, quantity: number) => void;
  decreaseProductQuantity: (item: CartItemInterface, quantity: number) => void;
  onUpdateParentClassification: (
    cartItem: CartItemInterface,
    classification: Classification,
  ) => void;
  onUpdateChildClassification: (
    cartItem: CartItemInterface,
    classification: SubClassification,
  ) => void;
  onDeleteCartItem: (item: CartItemInterface) => void;
  onBack: () => void;
  navigateToOrder: () => void;
  isLoading: boolean;
  onChangeQuantity: (quantity: number, item: CartItemInterface) => void;
  getDeductedMoney: () => { number: string; text: string };
  noteError: string;
  onNoteChange: (value: string) => void;
  getDiscountMoney: () => { number: string; text: string };
  getDiscountPercent: (cartItem: CartItemInterface) => number;
  getUserAddress: () => string;
  handleProductQuantity: DebouncedFunc<(item: CartItemInterface, quantity: number) => void>;
}
export const AppCartSection = (props: CartSectionProps) => {
  const {
    cart,
    getAttribute,
    getPrice,
    increaseProductQuantity,
    decreaseProductQuantity,
    onUpdateParentClassification,
    onUpdateChildClassification,
    onDeleteCartItem,
    onBack,
    navigateToOrder,
    isLoading,
    onChangeQuantity,
    getDeductedMoney,
    noteError,
    onNoteChange,
    getDiscountMoney,
    getDiscountPercent,
    getUserAddress,
    handleProductQuantity,
  } = props;
  return (
    <StyledCartSection>
      {cart.length === 0 && !isLoading ? (
        <EmptyCartView />
      ) : (
        <div className="cart-content-block">
          <div>
            {isLoading ? (
              <LoadingCart isMobile type="cart" count={4} />
            ) : (
              <div className="shadow-block">
                {cart.map((item, index) => {
                  return (
                    <CartItem
                      isMobile
                      onDeleteCartItem={onDeleteCartItem}
                      key={item.product_item_id}
                      cartItem={item}
                      onIncrease={() => {
                        increaseProductQuantity(item, 1);
                      }}
                      onDecrease={() => {
                        decreaseProductQuantity(item, 1);
                      }}
                      attribute={getAttribute(item)}
                      hasBottomLine={index !== cart.length - 1}
                      onUpdateParentClassification={onUpdateParentClassification}
                      onUpdateChildClassification={onUpdateChildClassification}
                      getDiscountPercent={getDiscountPercent}
                      onChangeQuantity={(q) => {
                        handleProductQuantity(item, q);
                      }}
                    />
                  );
                })}
              </div>
            )}
            {/* <SharedButton className="add-btn" btnStyle="line" text={'Chọn thêm sản phẩm'} /> */}
          </div>
          <div className="bill-block">
            <span className="summary-text">Tóm tắt thanh toán</span>
            <BillLine title="Tạm tính" content={getPrice().totalPrice} />
            <BillLine title="Tổng chiết khấu" content={getDeductedMoney().number} />
            <BillLine title="Khuyến mãi" content={getDiscountMoney().number} />
            <BillLine title="Địa chỉ:" content={getUserAddress()} />
            <BillLine isTotalPrice title="Tổng tiền" content={getPrice().number} />
            <BillLine isTextPrice title="Bằng chữ" content={getPrice().text} />
          </div>
          <div className="note-detail">
            <textarea
              placeholder="Địa chỉ chi tiết"
              onChange={(e) => {
                onNoteChange(e.target.value);
              }}
            />
            {noteError && <p className="input-text-error">{noteError}</p>}
          </div>
          {!isLoading && (
            <div className="total-price-block">
              <div className="total-price-wrapper">
                <span className="total-price-text">Tổng tiền</span>
                <span className="total-price-value">{getPrice().number}</span>
              </div>
              <SharedButton onClick={navigateToOrder} text="Đặt hàng" />
            </div>
          )}
        </div>
      )}
    </StyledCartSection>
  );
};
const StyledCartSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  textarea {
    border-radius: 1.6rem;
    width: 100%;
    border: 1px solid #888;
    height: 9.6rem;
    padding-top: 1rem;
    padding-left: 1.6rem;
    margin-top: 1.2rem;
  }
  .total-price-block {
    position: fixed;
    height: 9.8rem;
    background-color: white;
    width: 100%;
    bottom: ${MOBILE_FOOTER_HEIGHT};
    left: 0;
    box-shadow: 0px -6px 6px 0px rgba(0, 0, 0, 0.04);
    display: flex;
    padding: 1.6rem;
    justify-content: space-between;
    .btn {
      width: 40%;
    }
    .total-price-wrapper {
      display: flex;
      flex-direction: column;

      .total-price-text {
        font-size: 1.7rem;
      }
      .total-price-value {
        font-family: Roboto-Medium;
        font-size: 2.4rem;
        color: ${MAIN_THEME_DATA.mainColor};
      }
    }
  }
  .product-footer-item {
    text-align: left;
    font-size: 1.2rem;
    color: #888888;
    @media (max-width: 768px) {
      font-size: 1rem;
    }
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 16.8px */
  }
  .shadow-block {
    box-shadow: 0px 1px 16px 0px rgba(56, 56, 56, 0.15);
    border-radius: 10px;
    background-color: white;
  }
  .bill-block {
    margin-top: 1.6rem;
    padding: 1.6rem;
    border-radius: 8px;
    border: 1px solid #d20c00;
    background-color: #fff;
    .summary-text {
      font-family: Roboto-Medium;
      font-size: 1.4rem;
      margin-bottom: 1.6rem;
      display: inline-block;
    }
  }
  .cart-content-block {
    padding: 0 1.6rem 5rem 1.6rem;
    .add-btn {
      width: fit-content;
      padding: 1.5rem 4rem;
      margin-top: 2.4rem;
    }
  }
  .header-block {
    display: flex;
    padding: 0 12rem;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .title {
      /* Size 32/Medium */
      color: ${MAIN_THEME_DATA.mainText};
      font-family: Roboto-Medium;
      font-size: 3.2rem;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 44.8px */
    }
  }
`;
