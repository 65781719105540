import { styled } from 'styled-components';

export const StyledList = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  h2 {
    font-family: Roboto-Medium;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 33.6px */
  }
  .react-horizontal-scrolling-menu--wrapper {
    width: calc(100% - 9.6rem);
  }
  .react-horizontal-scrolling-menu--inner-wrapper {
    width: 100%;
  }

  .react-horizontal-scrolling-menu--scroll-container {
    padding: 1rem;
    overflow: hidden;
    width: calc(100% - 4.8rem);
  }
  .react-horizontal-scrolling-menu--item {
    width: 22.8rem;
    margin-right: 1.5rem;
    flex-shrink: 0;
  }
  .arrow {
    display: flex;
    width: 48px;
    height: 48px;
    padding: 12px;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    background-color: white;
  }
  .arrow-right {
    margin-left: 1.4rem;
  }
  .arrow-left {
    margin-right: 1.4rem;
  }
  .react-horizontal-scrolling-menu--arrow-left {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .react-horizontal-scrolling-menu--arrow-right {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
