import { BaseSyntheticEvent, memo } from 'react';
import {
  FieldErrorsImpl,
  FieldValues,
  UseFormHandleSubmit,
  UseFormRegister,
} from 'react-hook-form';
import GoogleLogin, { GoogleLogout } from 'react-google-login';

import { GoogleIcon, SharedButton, SharedImage, ShareInput, TelIcon } from '@components';
import { AUTH_THEME_COLOR, HEADER_HEIGHT, MAIN_THEME_DATA, SINGLE_HOST_ORIGIN } from '@configs';
import { opacityHex, themes, useTheme } from '@theme';
import styled from 'styled-components';
import { IMAGES } from '@assets';
import { IntroSectionModule } from '@modules';
import { logout, selectApp, useAppSelector } from '@redux';
import { LogApp } from '@utils';
import { Divider } from 'antd';
import { StyledMobileAuth } from '../Login';
import { LoginFailModal } from '../components/LoginFailModal';
interface IProps {
  redirectToForgot?: () => void;
  redirectToSignUp?: () => void;
  register: UseFormRegister<{ username: string; password: string }>;
  errors: Partial<FieldErrorsImpl<any>>;
  handleLogin: (e?: BaseSyntheticEvent<any, any, any> | undefined) => Promise<void>;
  onClose: () => void;
  onConfirm: () => void;
  opened: boolean;
}

export const AppLoginForm = memo((props: IProps) => {
  const {
    errors,
    redirectToForgot,
    register,
    handleLogin,
    redirectToSignUp,
    onClose,
    onConfirm,
    opened,
  } = props;
  const { themeData } = useAppSelector(selectApp);
  const { theme } = useTheme();
  return (
    <StyledMobileAuth>
      <StyledLoginSection>
        <form onSubmit={handleLogin} className="login-form">
          <ShareInput
            placeholder="Số điện thoại"
            name="username"
            className="input"
            // type="number"
            errors={errors['username']?.message}
            register={register}
          />
          <ShareInput
            placeholder="Mật khẩu"
            name="password"
            className="input"
            type="password"
            errors={errors['password']?.message}
            register={register}
            haveShowPassIcon
          />
          <div className="actions">
            <SharedButton
              typeHtml="submit"
              text="Đăng nhập"
              backgroundColor={MAIN_THEME_DATA.mainColor}
              btnStyle="pad"
            />
            <span onClick={redirectToForgot} className="action-text forgot-text">
              Quên mật khẩu
            </span>
            <span className=" action-text">
              Bạn chưa có tài khoản?
              <span onClick={redirectToSignUp} className="register-now">
                Đăng ký ngay
              </span>
            </span>
          </div>
        </form>
      </StyledLoginSection>
      <LoginFailModal opened={opened} onConfirm={onConfirm} onClose={onClose} />
    </StyledMobileAuth>
  );
});

const StyledLoginSection = styled.div`
  .app-modal {
    .content {
      margin: 0 1.6rem;
    }
  }
  .input {
    margin-bottom: 1.6rem;
  }
  .actions {
    margin-top: 0.8rem;
    .action-text {
      margin-top: 0.8rem;
    }
  }
`;
