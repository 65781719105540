import { ProductDetailPayload } from '@interfaces';
import { ApiClient } from './axiosClient';

export const productAPI = {
  getProduct: (payload: ProductDetailPayload) => {
    const url = `/products/detail`;
    return ApiClient.post(url, payload);
  },
  getProductByCode: (code: string, warehouseId: string) => {
    const url = `/products/code/${code}?warehouse_id=${warehouseId}`;
    return ApiClient.get(url);
  },
  getClassifications: (id: string) => {
    const url = `/products/${id}/classifications`;
    return ApiClient.get(url);
  },
};
