import { IMAGES } from '@assets';
import { MAIN_THEME_DATA } from '@configs';
import { SharedImage } from 'src/components/shared';
import { styled } from 'styled-components';
interface TabItemProps {
  isSelected?: boolean;
  title: string;
  onClick: () => void;
  image: any;
}
export const TabItem = (props: TabItemProps) => {
  const { isSelected, title, onClick, image } = props;
  return (
    <StyledTabItem onClick={onClick} isSelected={isSelected}>
      <SharedImage
        containerClassName={image ? 'img-ctn' : 'img-ctn img-bg'}
        src={image ? image.url : IMAGES.logo}
      />
      <span className="tab-item-title">{title}</span>
    </StyledTabItem>
  );
};
const StyledTabItem = styled.div<{ isSelected?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  .img-bg {
    background-color: ${MAIN_THEME_DATA.mainColor};
  }
  .img-ctn {
    width: 3.6rem;
    aspect-ratio: 1;
    border-radius: 100px;
    margin-bottom: 0.5rem;
    img {
      border-radius: 100px;
    }
  }
  .tab-item-title {
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    color: ${(p) => (p?.isSelected ? MAIN_THEME_DATA.mainColor : MAIN_THEME_DATA.mainText)};
    text-align: center;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 3em;
    line-height: 1.5em;
    max-height: 3em;
    word-wrap: break-word;
    max-width: 10rem;
  }
`;
