import { LikeIcon, ReverseIcon, ShieldIcon } from 'src/components/Icon';
import { styled } from 'styled-components';

export const Policy = () => {
  return (
    <StyledPolicy>
      <div className="policy-item">
        <ShieldIcon />
        <span>Hoàn tiền 111% nếu hàng giả</span>
      </div>
      <div className="policy-item">
        <LikeIcon />
        <span>Đồng kiểm trước khi nhận hàng</span>
      </div>
      <div className="policy-item">
        <ReverseIcon />
        <span>Hoàn tiền 111% nếu hàng giả</span>
      </div>
    </StyledPolicy>
  );
};
const StyledPolicy = styled.div`
  .policy-item {
    display: flex;
    span {
      margin-left: 0.8rem;
      margin-bottom: 1.6rem;
    }
  }
`;
