import { MAIN_THEME_DATA } from '@configs';
import { CartItem as CartItemInterface, Classification, SubClassification } from '@interfaces';
import { Col, Row, Steps } from 'antd';
import { styled } from 'styled-components';
import { BagIcon, IConArrowLeft, IdCardIcon, ShoppingCartIcon, TickIcon } from '../../Icon';
import { ShareSelectInput, SharedButton } from '../../shared';
import { BillLine } from '../components/BillLine';
import { CartItem } from '../components/CartItem';
import { EmptyCartView } from '../components/EmptyCartView';
import { LoadingBill, LoadingCart } from '../components/LoadingCart';
import { RoundedIcon } from '../components/RoundedIcon';
import { DebouncedFunc } from 'lodash';
interface CartSectionProps {
  cart: CartItemInterface[];
  getAttribute: (item: CartItemInterface) => string;
  getPrice: () => { number: string; text: string; totalPrice: string };
  increaseProductQuantity: (item: CartItemInterface, quantity: number) => void;
  decreaseProductQuantity: (item: CartItemInterface, quantity: number) => void;
  onUpdateParentClassification: (
    cartItem: CartItemInterface,
    classification: Classification,
  ) => void;
  onUpdateChildClassification: (
    cartItem: CartItemInterface,
    classification: SubClassification,
  ) => void;
  onDeleteCartItem: (item: CartItemInterface) => void;
  onBack: () => void;
  navigateToOrder: () => void;
  isLoading: boolean;
  onChangeQuantity: (quantity: number, item: CartItemInterface) => void;
  getDeductedMoney: () => { number: string; text: string };
  noteError: string;
  onNoteChange: (value: string) => void;
  getDiscountMoney: () => { number: string; text: string };
  getDiscountPercent: (cartItem: CartItemInterface) => number;
  getUserAddress: () => string;
  handleProductQuantity: DebouncedFunc<(item: CartItemInterface, quantity: number) => void>;
}
export const WebCartSection = (props: CartSectionProps) => {
  const {
    cart,
    getAttribute,
    getPrice,
    increaseProductQuantity,
    decreaseProductQuantity,
    onUpdateParentClassification,
    onUpdateChildClassification,
    onDeleteCartItem,
    onBack,
    navigateToOrder,
    isLoading,
    onChangeQuantity,
    getDeductedMoney,
    onNoteChange,
    noteError,
    getDiscountMoney,
    getDiscountPercent,
    getUserAddress,
    handleProductQuantity,
  } = props;
  return (
    <StyledCartSection>
      <div className="header-block">
        <IConArrowLeft onClick={onBack} width={36} height={36} />
        <h2 className="title">Giỏ hàng</h2>
        <span />
      </div>
      {cart.length === 0 && !isLoading ? (
        <EmptyCartView />
      ) : (
        <Row gutter={24} className="cart-content-block">
          <Col xxl={13} xl={12} lg={13}>
            {isLoading ? (
              <LoadingCart type="cart" count={4} />
            ) : (
              <div className="shadow-block">
                {cart.map((item, index) => {
                  return (
                    <CartItem
                      onDeleteCartItem={onDeleteCartItem}
                      key={item.product_item_id}
                      cartItem={item}
                      onIncrease={() => {
                        increaseProductQuantity(item, 1);
                      }}
                      onDecrease={() => {
                        decreaseProductQuantity(item, 1);
                      }}
                      attribute={getAttribute(item)}
                      hasBottomLine={index !== cart.length - 1}
                      onUpdateParentClassification={onUpdateParentClassification}
                      onUpdateChildClassification={onUpdateChildClassification}
                      getDiscountPercent={getDiscountPercent}
                      onChangeQuantity={(q) => {
                        handleProductQuantity(item, q);
                      }}
                    />
                  );
                })}
              </div>
            )}
          </Col>
          <Col xxl={11} xl={12} lg={11}>
            <div className="bill-block shadow-block">
              <div className="header-block">
                <Steps
                  items={[
                    {
                      status: 'finish',
                      icon: <RoundedIcon icon={<BagIcon />} title="Chọn sản phẩm" />,
                    },
                    {
                      status: 'finish',
                      icon: <RoundedIcon icon={<ShoppingCartIcon />} title="Giỏ hàng" />,
                    },
                    {
                      status: 'finish',
                      icon: <RoundedIcon icon={<IdCardIcon />} title="Thông tin đặt hàng" />,
                    },
                    {
                      status: 'finish',
                      icon: <RoundedIcon icon={<TickIcon />} title="Hoàn tất đặt hàng" />,
                    },
                  ]}
                />
              </div>
              <div className="content-block">
                {isLoading ? (
                  <LoadingBill />
                ) : (
                  <>
                    <BillLine title="Tạm tính" content={getPrice().totalPrice} />
                    <BillLine title="Tổng chiết khấu" content={getDeductedMoney().number} />
                    <BillLine title="Khuyến mãi" content={getDiscountMoney().number} />
                    <BillLine title="Địa chỉ:" content={getUserAddress()} />
                    <BillLine isTotalPrice title="Tổng tiền" content={getPrice().number} />
                    <BillLine isTextPrice title="Bằng chữ: " content={getPrice().text} />
                    <div className="note-detail">
                      <textarea
                        // {...register('detail')}
                        placeholder="Ghi chú"
                        onChange={(e) => {
                          onNoteChange(e.target.value);
                        }}
                      />
                      {noteError && <p className="input-text-error">{noteError}</p>}
                    </div>
                    {/* <ShareSelectInput placeholder="Sử dụng điểm tích lũy" data={[]} /> */}
                    <SharedButton onClick={navigateToOrder} text="Đặt hàng" />
                  </>
                )}
              </div>
            </div>
          </Col>
        </Row>
      )}
    </StyledCartSection>
  );
};
const StyledCartSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  textarea {
    border-radius: 1.6rem;
    width: 100%;
    border: 1px solid #888;
    height: 9.6rem;
    padding-top: 1rem;
    padding-left: 1.6rem;
  }
  .product-footer-item {
    text-align: left;
    font-size: 1.2rem;
    color: #888888;
    @media (max-width: 768px) {
      font-size: 1rem;
    }
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 16.8px */
  }
  .shadow-block {
    box-shadow: 0px 1px 16px 0px rgba(56, 56, 56, 0.15);
    border-radius: 10px;
    background-color: white;
  }
  .bill-block {
    .header-block {
      background-color: #d20c00;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      padding: 1.6rem;
      display: flex;
      flex-direction: column;
    }
    .content-block {
      background-color: white;
      padding: 0.9rem 1.6rem 1.6rem 1.6rem;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      .btn {
        margin-top: 2.4rem;
      }
    }
    .icon-title-block {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .icon-title {
        text-align: center;
        font-size: 1.2rem;
        color: white;
        font-weight: 400;
        line-height: 140%;
        margin-top: 0.4rem;
      }
    }
    .ant-steps-item-finish
      > .ant-steps-item-container
      > .ant-steps-item-content
      > .ant-steps-item-title:after {
      background-color: white;
      border: 1px dashed #d20c00;
    }
    .ant-steps-item-title:after {
      left: unset;
      top: 30px;
    }
    .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:nth-child(3) {
      padding-left: 0;
    }

    /* Target the 4th .ant-steps-item element */
    .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:nth-child(4) {
      padding-left: 0;
    }
    .ant-steps {
    }
  }
  .cart-content-block {
    margin-top: 3.6rem;
    padding: 0 12rem;
    .add-btn {
      width: fit-content;
      padding: 1.5rem 4rem;
      margin-top: 2.4rem;
    }
  }
  .header-block {
    display: flex;
    padding: 0 12rem;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .title {
      /* Size 32/Medium */
      color: ${MAIN_THEME_DATA.mainText};
      font-family: Roboto-Medium;
      font-size: 3.2rem;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 44.8px */
    }
  }
`;
