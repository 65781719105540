import { yupResolver } from '@hookform/resolvers/yup';
import { useLayoutEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { authAPI } from '@api';
import { ResetPasswordForm } from '@components';
import { PATH_LOGIN } from '@configs';
import { RegisterPayload, RootResponse, SignUpResponse } from '@interfaces';
import { selectAuth, setLoading, setRegisterInfo, useAppDispatch, useAppSelector } from '@redux';
//@ts-ignore
import { NotificationManager } from 'react-notifications';
const schema = yup.object().shape({
  password: yup
    .string()
    .required('Vui lòng nhập mật khẩu')
    .matches(
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$%^&+=!]).{8,}$/,
      'Mật khẩu phải chứa ít nhất 8 ký tự, bao gồm ít nhất một chữ hoa, một chữ thường, một số và một ký tự đặc biệt.',
    ),
  confirm_password: yup
    .string()
    .required('Vui lòng xác nhận mật khẩu')
    .oneOf([yup.ref('password'), null], 'Mật khẩu không khớp'),
});

export const SetupPasswordModule = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { resetPassHash, registerInfo } = useAppSelector(selectAuth);
  const [opened, setOpened] = useState(false);
  useLayoutEffect(() => {
    // if (!resetPassHash) {
    //   navigate(PATH_LOGIN);
    // }
  }, []);

  const {
    register,
    handleSubmit,
    reset,
    setError,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      password: '',
      confirm_password: '',
    },
  });
  const handleRedirectToLogin = () => {
    navigate(PATH_LOGIN);
  };
  const goBack = () => {
    navigate(-1);
  };

  const handleSignUp = handleSubmit(async (value) => {
    try {
      dispatch(setLoading(true));
      const { password } = value;
      const payload: RegisterPayload = {
        seller_id: registerInfo?.seller_id || '',
        password,
        full_name: registerInfo?.full_name || '',
        username: registerInfo?.phone_number || '',
        phone_number: registerInfo?.phone_number || '',
        image_id: registerInfo?.image_id || '',
        address: {
          province_code: registerInfo?.province?.code || '',
          district_code: registerInfo?.district?.code || '',
          ward_code: registerInfo?.ward?.code || '',
          detail: registerInfo?.address?.detail || '',
        },
        email: 'h@gmail.com',
      };
      const res: RootResponse<SignUpResponse> = await authAPI.signUp(payload);
      setOpened(true);
      dispatch(setRegisterInfo(undefined));
    } catch (error: any) {
      NotificationManager.error('Thông báo', error.data.detail);
    } finally {
      dispatch(setLoading(false));
    }
  });

  return (
    <ResetPasswordForm
      register={register}
      redirectToLogin={handleRedirectToLogin}
      errors={errors}
      handleResetPassword={handleSignUp}
      goBack={goBack}
      opened={opened}
    />
  );
};
