import { Order, OrderProgress } from '@interfaces';
import Stepper from 'src/components/cart/components/steps';
import { LABEL_POSITION } from 'src/components/cart/components/steps/constants';
import styled from 'styled-components';
import { DEFAULT_TIMELINE } from '../OrderDetail';
import { MAIN_THEME_DATA, ORDER_STATUS_TEXT } from '@configs';
import { IStep } from 'src/components/cart/components/steps/stepper-component/types';
import moment from 'moment';
interface OrderProgressSectionProps {
  orderProgress: IStep[];
  isLoading: boolean;
  order: Order;
}
export const OrderProgressSection = (props: OrderProgressSectionProps) => {
  const { orderProgress, isLoading, order } = props;
  return (
    <StyledOrderProgressSection>
      <div className="card">
        <div className="order-heading">
          <span className="text-med">
            {order.code + ' - '}
            <span className="text-med date">{moment(order.created_at).format('DD/MM/yyyy')}</span>
          </span>
          <span className="order-status">{ORDER_STATUS_TEXT[order.status].toUpperCase()}</span>
        </div>
        <Stepper
          isMobile
          isLoading={isLoading}
          steps={isLoading ? DEFAULT_TIMELINE : orderProgress}
          currentStepIndex={0}
          labelPosition={LABEL_POSITION.RIGHT}
        />
      </div>
    </StyledOrderProgressSection>
  );
};
const StyledOrderProgressSection = styled.div`
  padding: 0 1.6rem 1.6rem 1.6rem;
  .card {
    .order-heading {
      display: flex;
      justify-content: space-between;
      padding-bottom: 0.8rem;
      border-bottom: 1px solid #d9d9d9;
      margin-bottom: 0.8rem;
      .order-status {
        color: ${MAIN_THEME_DATA.mainColor};
      }
    }
    box-shadow: 0px 1px 16px 0px rgba(56, 56, 56, 0.15);
    margin-bottom: 2.4rem;
    background-color: white;
    border-radius: 16px;
    padding: 1.2rem 1.6rem 2rem 1.6rem;
    .item-title {
      font-family: Roboto-Medium;
      font-size: 1.7rem;
      font-weight: 500;
      line-height: 140%;
    }
  }
`;
