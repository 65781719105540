import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { authAPI, categoryAPI, homeAPI } from '@api';
import {
  DEFAULT_CATEGORY_PARAMS,
  DEFAULT_PRODUCT_PARAMS,
  DEFAULT_WAREHOUSE_ITEM,
  MAX_ITEM_PER_PAGE,
} from '@configs';
import {
  Category,
  CategoryRoot,
  ProductDetailResponse,
  ProductFilterData,
  ProductFilterPayload,
  ProductFilterResponse,
  RootResponse,
  SubCategory,
  WarehouseItem,
} from '@interfaces';
import { LogApp } from '@utils';
import { RootState } from '.';

interface IInitialState {
  loading: boolean;
  category: Category[];
  warehouses: WarehouseItem[];
  searchedProduct: {
    data: ProductFilterData;
    loading: boolean;
    page: number;
  };
  currentChildCategory: SubCategory;
  currentParentCategory: Category;
  currentProduct: ProductDetailResponse;
  warehouseModal: boolean;
  currentWarehouse: WarehouseItem;
  currentKeyword: string;
}

const initialState = {
  category: [],
  warehouses: [],
  loading: false,
  currentChildCategory: DEFAULT_CATEGORY_PARAMS,
  currentParentCategory: DEFAULT_CATEGORY_PARAMS,
  currentProduct: DEFAULT_PRODUCT_PARAMS,
  warehouseModal: false,
  currentWarehouse: DEFAULT_WAREHOUSE_ITEM,
  searchedProduct: {
    data: {
      limit: 25,
      count: 0,
      data: [],
      brands: [],
      suppliers: [],
    },
    page: 0,
    loading: false,
  },
  currentKeyword: '',
} as IInitialState;
export const getCategories = createAsyncThunk('home/category', async () => {
  const response: CategoryRoot = await categoryAPI.getCategories();
  return response;
});
export const getWarehouses = createAsyncThunk('home/warehouse', async (keyword?: string) => {
  const response: RootResponse<WarehouseItem[]> = await authAPI.getWarehouses({ keyword });
  return response;
});
export const getProductsByKeyword = createAsyncThunk(
  'home/search',
  async (payload: ProductFilterPayload) => {
    const response: RootResponse<ProductFilterResponse> = await homeAPI.getProductByFilter(payload);
    return response;
  },
);
const home = createSlice({
  name: 'home',
  initialState,
  reducers: {
    setCurrentChildCategory: (state, action: PayloadAction<SubCategory>) => {
      state.currentChildCategory = action.payload;
    },
    setCurrentParentCategory: (state, action: PayloadAction<Category>) => {
      state.currentParentCategory = action.payload;
    },
    setCurrentParentProduct: (state, action: PayloadAction<ProductDetailResponse>) => {
      state.currentProduct = action.payload;
    },
    setWarehouseModalOpened: (state, action: PayloadAction<boolean>) => {
      state.warehouseModal = action.payload;
    },
    setCurrentWarehouse: (state, action: PayloadAction<WarehouseItem>) => {
      state.currentWarehouse = action.payload;
      state.warehouseModal = false;
    },
    setCurrentProductSearchPage: (state, action: PayloadAction<number>) => {
      state.searchedProduct.page = action.payload;
    },
    setCurrentProductSearchKey: (state, action: PayloadAction<string>) => {
      state.currentKeyword = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCategories.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCategories.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.category = payload.data.categories;
      })
      .addCase(getCategories.rejected, (state) => {
        state.loading = false;
      });
    builder
      .addCase(getWarehouses.pending, (state) => {
        state.loading = true;
      })
      .addCase(getWarehouses.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.warehouses = payload.data;
      })
      .addCase(getWarehouses.rejected, (state) => {
        state.loading = false;
      });
    builder
      .addCase(getProductsByKeyword.pending, (state) => {
        state.searchedProduct.loading = true;
      })
      .addCase(getProductsByKeyword.fulfilled, (state, { payload }) => {
        state.searchedProduct.data = {
          data: payload.data.products,
          limit: payload.data.amount,
          count: 25,
          brands: [],
          suppliers: [],
          // brands: payload.data.brands.map((item) => {
          //   return {
          //     value: item,
          //     label: item,
          //   };
          // }),
          // suppliers: payload.data.suppliers.map((item) => {
          //   return {
          //     value: item,
          //     label: item,
          //   };
          // }),
        };
        state.searchedProduct.loading = false;
      })
      .addCase(getProductsByKeyword.rejected, (state) => {
        state.searchedProduct.loading = false;
      });
  },
});

export const selectHome = (state: RootState) => {
  return state.home;
};

export const {
  setCurrentChildCategory,
  setCurrentParentCategory,
  setCurrentParentProduct,
  setWarehouseModalOpened,
  setCurrentWarehouse,
  setCurrentProductSearchPage,
  setCurrentProductSearchKey,
} = home.actions;

export default home.reducer;
