import { MAIN_THEME_DATA, ORDER_STATUS } from '@configs';
import { GetOrderParams, Order, OrderData } from '@interfaces';
import { List, Tabs } from 'antd';
import { BaseSyntheticEvent } from 'react';
import { FieldErrorsImpl, UseFormRegister } from 'react-hook-form';
import { styled } from 'styled-components';
import { Pagination, ShareRangeDateSelect } from '../../shared';
import { LoadingOrder } from '../components/LoadingOrder';
import { OrderItem } from '../components/OrderItem';
import { RatingModal } from '../components/RatingModal';
interface OrderSectionProps {
  orders: OrderData;
  onChangeStatus: (status: string) => void;
  navigateToOrderDetail: (id: string) => void;
  onPageChange: (page: number) => void;
  onDateChange: (date: string[]) => void;
  onReview: (order: Order) => void;
  onRateChange: (star: number) => void;
  isLoading: boolean;
  payload: GetOrderParams;
  modalData: { opened: boolean; orderItem: Order };
  rating: number;
  errors: Partial<FieldErrorsImpl<any>>;
  register: UseFormRegister<any>;
  onClose: () => void;
  submitReview: (e?: BaseSyntheticEvent<any, any, any> | undefined) => Promise<void>;
  onUpdateOrder: (id: string) => Promise<void>;
  orderCount: any;
}
export const AppOrderSection = (props: OrderSectionProps) => {
  const {
    orders,
    onChangeStatus,
    navigateToOrderDetail,
    isLoading,
    payload,
    onPageChange,
    onDateChange,
    onReview,
    modalData,
    rating,
    onRateChange,
    register,
    errors,
    onClose,
    submitReview,
    onUpdateOrder,
    orderCount,
  } = props;
  return (
    <StyledOrderSection>
      <div className="heading-wrapper">
        <Tabs
          tabBarStyle={{
            borderBottomWidth: 1,
            borderBottomColor: '#D9D9D9',
          }}
          onChange={(key) => {
            onChangeStatus(key);
          }}
        >
          {ORDER_STATUS.map((item) => {
            return (
              <Tabs.TabPane
                tab={
                  <div className="flex items-center">
                    {item.label}
                    {orderCount[item.key] > 0 && (
                      <h4
                        style={{ color: MAIN_THEME_DATA.mainColor, fontSize: 12 }}
                        className="ml-2 font-semibold"
                      >{`${orderCount[item.key]}`}</h4>
                    )}
                  </div>
                }
                key={item.key}
              />
            );
          })}
        </Tabs>
        <ShareRangeDateSelect
          containerClassName="filter-wrapper"
          placeholder={['Từ ngày', 'Đến ngày']}
          format={'yyyy-MM-DD'}
          onChange={(date, dateString) => {
            onDateChange(dateString);
          }}
        />
      </div>
      <div className="content">
        {isLoading ? (
          <LoadingOrder isMobile count={2} />
        ) : (
          <List
            grid={{
              gutter: 16,
              xs: 1,
              sm: 1,
              md: 1,
              lg: 1,
              xl: 1,
              xxl: 1,
            }}
            dataSource={orders.data}
            renderItem={(item: Order) => (
              <List.Item>
                <OrderItem
                  onReview={onReview}
                  isMobile
                  navigateToOrderDetail={navigateToOrderDetail}
                  orderItem={item}
                  onUpdateOrder={onUpdateOrder}
                />
              </List.Item>
            )}
          />
        )}
        <Pagination
          className="pagination-bar"
          currentPage={payload.page}
          totalCount={orders.limit}
          pageSize={orders.count}
          onPageChange={(page: number) => {
            onPageChange(page);
          }}
        />
      </div>
      <RatingModal
        modalData={modalData}
        rating={rating}
        errors={errors}
        register={register}
        onClose={onClose}
        submitReview={submitReview}
        onRateChange={onRateChange}
      />
    </StyledOrderSection>
  );
};
const StyledOrderSection = styled.div`
  textarea {
    border-radius: 1.6rem;
    width: 100%;
    border: 1px solid #888;
    height: 8rem;
    padding-top: 1rem;
    padding-left: 1.6rem;
  }
  .ant-rate-star.ant-rate-star-full div div span svg {
    width: 2.4rem;
    height: 2.4rem;
  }
  .ant-rate-star.ant-rate-star-zero div div span svg {
    width: 2.4rem;
    height: 2.4rem;
  }
  .app-modal {
    .content {
      margin: 0 1.6rem;
      padding: 0 0;
      border-radius: 1.6rem;
    }
  }
  .ant-tabs-nav-operations {
    background-color: white;
  }
  .ant-picker {
    padding: 0.8rem 1.2rem;
  }
  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0;
  }
  .ant-tabs-tab-btn {
    color: ${MAIN_THEME_DATA.mainText};
    font-size: 1.4rem;
    font-family: Roboto-Regular;
    font-weight: 400;
    line-height: 23.8px;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${MAIN_THEME_DATA.mainColor};
    font-size: 1.4rem;
    font-family: Roboto-Medium;
    font-weight: 500;
    line-height: 23.8px;
  }
  .ant-tabs-ink-bar {
    background: ${MAIN_THEME_DATA.mainColor};
    height: 3px;
  }
  .ant-tabs-tab {
    padding: 0.9rem 1.6rem;
  }
  .ant-tabs-nav-list {
    background-color: white;
  }
  .ant-picker-focused,
  .ant-picker:hover {
    border-color: ${MAIN_THEME_DATA.mainColor};
  }
  .ant-picker-range .ant-picker-active-bar {
    background: ${MAIN_THEME_DATA.mainColor};
  }
  .heading-wrapper {
    margin-top: -1.6rem;
    .filter-wrapper {
      margin: 0 1.6rem 1.6rem 1.6rem;
    }
  }
  .pagination-bar {
    margin-top: 1rem;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .content {
    padding: 0 1.6rem;
  }
`;
