import { IProfileParams } from '@interfaces';
import { LogApp } from './../utils/utilities';
import { ApiClient } from './axiosClient';

export const settingAPI = {
  getUserInfo: () => {
    const url = 'members/web/info';
    return ApiClient.get(url);
  },
  getMerchantInfo: (params: any) => {
    const url = '/merchant/info/from-qrcode';
    LogApp('check bd', params);
    return ApiClient.get(url, { params });
  },
  updateUserInfo: (payload: IProfileParams) => {
    const url = '/members/update-member';
    return ApiClient.patch(url, payload);
  },
  uploadImage: (form: FormData) => {
    const url = `/upload/single`;
    return ApiClient.post(url, form);
  },
};
