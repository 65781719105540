import { MAIN_THEME_DATA } from '@configs';
import { Classification } from '@interfaces';
import { LogApp } from '@utils';
import { styled } from 'styled-components';
interface SizeProps {
  title: string;
  quantity: number;
  titleClassName?: string;
  quantityClassName?: string;
  borderColor?: string;
  borderRadius?: string;
  hasQuantity?: boolean;
  percent?: number;
  isSelected: boolean;
  onClick: () => void;
}
export const Size = (props: SizeProps) => {
  const {
    title,
    quantity,
    titleClassName,
    quantityClassName,
    hasQuantity = false,
    onClick,
    isSelected,
    borderColor = 'white',
    borderRadius = '1.2rem',
    percent,
  } = props;
  return (
    <StyledSize
      borderColor={borderColor}
      borderRadius={borderRadius}
      onClick={onClick}
      isSelected={isSelected}
      hasQuantity={hasQuantity}
    >
      <span className="def-title">{title}</span>
      {hasQuantity && (
        <>
          <div className="detail">
            <span className={titleClassName || 'def-text'}>Số lượng</span>
            <span className={quantityClassName || 'def-content'}>&nbsp;{quantity}</span>
          </div>
          {(percent || 0) > 0 && <span className="def-content">Chiết khấu: {percent}%</span>}
        </>
      )}
    </StyledSize>
  );
};
const StyledSize = styled.div<{
  isSelected: boolean;
  borderColor?: string;
  hasQuantity?: boolean;
  borderRadius: string;
}>`
  @media (max-width: 768px) {
    width: ${(p) => (p?.hasQuantity ? `25%` : 'unset')};
    margin-right: 0.8rem;
    margin-bottom: 0.8rem;
    padding: 0.4rem 0.8rem;
  }
  display: flex;
  flex-direction: column;
  background-color: white;
  width: ${(p) => (p?.hasQuantity ? `calc(25% - 1.6rem)` : 'unset')};
  margin-right: 1.6rem;
  margin-bottom: 1.6rem;
  justify-content: center;
  align-items: center;
  border-radius: ${(p) => p?.borderRadius || '0.8rem'};
  padding: 0.8rem 1.2rem;
  cursor: pointer;
  border-color: ${(p) => (p?.isSelected ? MAIN_THEME_DATA.mainColor : p?.borderColor)};
  border-width: 0.5px;
  .def-title {
    font-size: 1.4rem;
    line-height: 19.6px;
    @media (max-width: 768px) {
      font-size: 1.2rem;
      line-height: 140%;
    }
  }
  .detail {
    display: flex;
  }
  .def-text {
    color: #888888;
    font-size: 1.4rem;
    font-family: Roboto-Medium;
    font-weight: 400;
    line-height: 19.6px;
    margin-right: 0.5rem;
    @media (max-width: 768px) {
      font-size: 1rem;
      line-height: 140%;
    }
  }
  .def-content {
    color: ${MAIN_THEME_DATA.mainColor};
    @media (max-width: 768px) {
      font-size: 1rem;
      line-height: 140%;
    }
  }
`;
