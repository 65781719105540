import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RegisterData } from '@interfaces';
import { RootState } from '.';
import { LogApp } from '@utils';

interface IAuth {
  accessToken?: string;
  refreshToken?: string;
  accountInfo?: any;
  registerInfo?: RegisterData;
  forgotUsername?: string;
  verifyOTPHash?: string;
  resetPassHash?: string;
  // merchantId?: string;
  memberPhone?: string;
  tokenExpires?: string | number;
  registerHash?: string;
  googleRegisterHash?: string;
  privateId: {
    userId: string;
    warehouseId: string;
  };
  version: string;
}

const initialState: IAuth = {
  accessToken: '',
  refreshToken: undefined,
  accountInfo: undefined,
  verifyOTPHash: undefined,
  resetPassHash: undefined,
  forgotUsername: '',
  registerInfo: undefined,
  memberPhone: undefined,
  tokenExpires: undefined,
  registerHash: undefined,
  googleRegisterHash: undefined,
  privateId: {
    userId: '',
    warehouseId: '',
  },
  version: '',
};

const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    setAccessToken: (state, action: PayloadAction<string>) => {
      state.accessToken = action.payload;
    },
    setPrivateId: (state, action: PayloadAction<{ userId: string; warehouseId: string }>) => {
      const { userId, warehouseId } = action.payload;
      state.privateId = { userId, warehouseId };
    },

    setForgotUsername: (state, action: PayloadAction<string>) => {
      state.forgotUsername = action.payload;
    },
    setAccountInfo: (state, action: PayloadAction<any>) => {
      state.accountInfo = action.payload;
    },
    setRegisterInfo: (state, action: PayloadAction<RegisterData | undefined>) => {
      LogApp(action.payload, 'action.payload');
      state.registerInfo = action.payload;
    },
    setVerifyOTPHash: (state, action: PayloadAction<string>) => {
      state.verifyOTPHash = action.payload;
    },
    setResetPassHash: (state, action: PayloadAction<string>) => {
      state.resetPassHash = action.payload;
    },
    // setMerchantId: (state, action: PayloadAction<string>) => {
    //   state.merchantId = action.payload;
    // },
    setMemberPhone: (state, action: PayloadAction<string>) => {
      state.memberPhone = action.payload;
    },
    setTokenExpires: (state, action: PayloadAction<string | number>) => {
      state.tokenExpires = action.payload;
    },
    setRegisterHash: (state, action: PayloadAction<string>) => {
      state.registerHash = action.payload;
    },
    setGoogleRegisterHash: (state, action: PayloadAction<string>) => {
      state.googleRegisterHash = action.payload;
    },
    setVersion: (state, action: PayloadAction<string>) => {
      state.version = action.payload;
    },
    logout: (state) => {
      return {
        version: state.version,
        accessToken: '',
        refreshToken: undefined,
        accountInfo: undefined,
        verifyOTPHash: undefined,
        resetPassHash: undefined,
        forgotEmail: undefined,
        registerInfo: undefined,
        memberPhone: undefined,
        tokenExpires: undefined,
        registerHash: undefined,
        googleRegisterHash: undefined,
        privateId: {
          userId: '',
          warehouseId: '',
        },
      };
    },
  },
});

export const {
  setAccessToken,
  setAccountInfo,
  setForgotUsername,
  // setLoginHash,
  setVerifyOTPHash,
  setResetPassHash,
  // setMerchantId,
  setMemberPhone,
  setTokenExpires,
  setRegisterHash,
  setGoogleRegisterHash,
  logout,
  setPrivateId,
  setRegisterInfo,
  setVersion,
} = authSlice.actions;

export const selectAuth = (state: RootState) => state.auth;

export default authSlice.reducer;
