import Skeleton from 'react-loading-skeleton';

export const AddressLoading = () => {
  return (
    <>
      <Skeleton width="13.5rem" />
      <Skeleton width="7.9rem" />
      <Skeleton width="29.2rem" />
    </>
  );
};
export const BillLoading = () => {
  return (
    <>
      <Skeleton />
      <Skeleton />
      <Skeleton />
      <Skeleton />
    </>
  );
};
export const HeadLoading = () => {
  return (
    <div className="bill-heading">
      <Skeleton width="15.4rem" height="2rem" />
      <Skeleton width="11.2rem" height="2rem" />
    </div>
  );
};
